import { Button, Flex, Form, Space, Steps, Typography } from "antd";
import { useForm } from "antd/es/form/Form";

import { Dialog } from "antd-mobile";
import { log, useLogPage } from "api/analytics";
import { preloadImage } from "api/utils";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Profile, updateSelfProfile, UserContext } from "./api/user";
import { Container, DefaultHeader } from "./component/Container";
import { FormStage, SuburbInput } from "./component/Form/Input";
import {
  AboutMe,
  DobInput,
  EmailInput,
  GenderInput,
  interestBundleChoice,
  InterestBundleInput,
  localProfilePics,
  PreferredName,
  ProfilePicInput,
} from "./component/Form/ProfileInput";
const { Title, Text } = Typography;

const stages: FormStage<Profile>[] = [
  {
    id: "basic",
    title: "Let's Get Acquainted",
    subtitle:
      "We'd love to know a bit about you. Could you please share your name with us?",
    names: ["preferredName"],
    fields: (
      <>
        <PreferredName />
        <EmailInput />
        <DobInput />
        <GenderInput />
      </>
    ),
  },
  {
    id: "interests",
    title: "Tell us more your interests",
    subtitle:
      "Telling us more about your interests will help us put you into groups that you like!",
    names: [],
    fields: (
      <>
        <InterestBundleInput />
      </>
    ),
  },
  {
    id: "bio",
    title: "Tell us more about yourself!",
    subtitle:
      "Everyone has a unique background that can spark great conversations. ",
    names: ["aboutMe", "location"],
    fields: (
      <>
        <AboutMe />
        <SuburbInput label="Preferred Meetup location" />
      </>
    ),
  },
  {
    id: "pic",
    title: "Choose an Avatar!",
    subtitle: "Make yourself look unique by choosing an avatar",
    names: ["profilePic"],
    fields: (
      <>
        <ProfilePicInput />
      </>
    ),
  },
];

const CreateProfile = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { profile } = useContext(UserContext);

  const { state } = useLocation();
  const defaultStage = state?.stage || 0;

  const [index, setIndex] = useState<number>(defaultStage);
  const { title, fields, subtitle = "" } = stages[index];

  useLogPage("profileCreate");
  useEffect(() => {
    Promise.all(localProfilePics.map((src) => preloadImage(src)));
    Promise.all(interestBundleChoice.map(({ icon }) => preloadImage(icon)));
  }, []);

  return Container({
    lessPadding: true,
    header: <DefaultHeader />,
    content: (
      <Flex vertical gap={2}>
        <Steps
          size="small"
          current={index}
          items={stages.map((_, i) => ({ disabled: i > index }))}
          onChange={setIndex}
          direction="horizontal"
          responsive={false}
        />

        <Space.Compact direction="vertical" style={{ paddingBottom: 20 }}>
          <Title level={2}>{title}</Title>
          <Text type="secondary">{subtitle}</Text>
        </Space.Compact>
        <Form
          initialValues={profile}
          onFinish={(data) => {
            log("profileCreate_stageFinish", {
              index,
              id: stages[index].id,
            });
            if (index + 1 < stages.length) {
              updateSelfProfile(data as Profile);
              setIndex((p) => p + 1);
            } else {
              updateSelfProfile({
                ...data,
                completedSetup: true,
              } as Profile).then(() => navigate("/home"));
            }
          }}
          form={form}
        >
          {fields}
        </Form>
      </Flex>
    ),
    footer: (
      <Flex vertical gap="small" align="center">
        <Button
          type="primary"
          block
          shape="round"
          size="large"
          onClick={() => {
            form.submit();
          }}
        >
          Next
        </Button>
        {index > 0 ? (
          <Button
            size="small"
            type="link"
            shape="round"
            onClick={() => {
              Dialog.show({
                content:
                  "Are you sure to skip to group matching? Completing your profile helps us find a better group for you!",
                closeOnAction: true,
                actions: [
                  [
                    { key: "cancel", text: "Cancel", danger: true },
                    {
                      key: "exit",
                      text: "Yes, skip!",
                      onClick: () => {
                        log("profileCreate_skip");
                        updateSelfProfile({ quickstart: true }).then(() =>
                          navigate("/home"),
                        );
                      },
                    },
                  ],
                ],
              });
            }}
          >
            Skip for now
          </Button>
        ) : null}
      </Flex>
    ),
  });
};
export default CreateProfile;
