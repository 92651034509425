import {
  Button,
  Card,
  Divider,
  Flex,
  Form,
  Image,
  Space,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { log, useLogPage } from "api/analytics";
import { AuthContext } from "api/auth";
import { quickstartSave } from "api/quickstart";
import { useProfileSnapshot } from "api/user";
import CircularImageIcon from "component/CircularImageIcon";
import { GenderInput, PreferredName } from "component/Form/ProfileInput";
import {
  activities,
  AdditionalQuestionsInput,
  DateTimeInput,
  QueueTypeInput,
  SuburbInput,
} from "component/Form/QueueInput";
import PageCard from "component/PageCard";
import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const QuickStartPage = ({ children }: React.PropsWithChildren) => {
  const [form] = useForm();
  const navigate = useNavigate();
  const authUser = useContext(AuthContext);
  const params = useParams();
  const activity = activities.find(({ id }) => id === params.activityId);

  useLogPage("quickstart");
  if (!activity) {
    return (
      <PageCard
        footer={
          <Button
            type="primary"
            block
            shape="round"
            size="large"
            onClick={() => form.submit()}
          >
            Next
          </Button>
        }
      >
        <Space direction="vertical">
          <Typography.Title level={3}>
            Join Fun Activities & Meet New Friends
          </Typography.Title>
          <Typography.Text type="secondary">
            Meet friends who share your passions. Join groups for games, museum
            visits & more.
          </Typography.Text>
          <Form
            form={form}
            onFinish={(data) => {
              console.log(data);
              navigate(data.activityType);
            }}
          >
            <QueueTypeInput />
          </Form>
        </Space>
      </PageCard>
    );
  }

  return (
    <PageCard>
      <Card>
        <Flex vertical align="center">
          <Space direction="vertical">
            {activity.banner ? (
              <Image
                width={"100%"}
                height={100}
                src={activity.banner}
                preview={false}
                style={{ borderRadius: 10, objectFit: "cover" }}
              />
            ) : null}
            <Typography.Title level={2} style={{ margin: 0 }}>
              Find people to {activity.to}
            </Typography.Title>
            <Typography.Paragraph>{activity.description}</Typography.Paragraph>
            {/* TODO: show activity stats with proper security <ActivityStats activity={activity} /> */}
            {authUser.user ? (
              <ProfileName />
            ) : (
              <Link to="/login" state={{ redirect: window.location.pathname }}>
                Have an account? Login here
              </Link>
            )}
          </Space>
          <Divider />
          <CircularImageIcon src={activity.icon} />
          <Form
            form={form}
            initialValues={{
              activityType: "game",
            }}
            onFinish={(data) => {
              const {
                preferredName,
                gender,
                location,
                preferredDate,
                additionalQuestions,
              } = data;
              const activityType = activity.id;
              quickstartSave({
                profile: {
                  preferredName,
                  gender,
                  location,
                  quickstart: true,
                },
                queue: {
                  activityType,
                  preferredDate,
                  additionalQuestions,
                },
              });
              log("quickstart_form_success");
              navigate("/login", { state: { quickstart: true } });
            }}
          >
            {authUser.user ? null : <ProfileInput />}
            <Divider />
            {authUser.user ? null : (
              <SuburbInput label="Preferred Meetup location" />
            )}
            <DateTimeInput />
            <Divider />
            <AdditionalQuestionsInput />
            <Button htmlType="submit" block type="primary">
              Submit
            </Button>
          </Form>
        </Flex>
      </Card>
    </PageCard>
  );
};

const ProfileInput = () => {
  return (
    <>
      <PreferredName />
      <GenderInput />
    </>
  );
};

const ProfileName = () => {
  const userProfile = useProfileSnapshot();
  if (userProfile.profile) {
    return (
      <Typography.Paragraph type="secondary">
        Signed in as {userProfile.profile.preferredName}
      </Typography.Paragraph>
    );
  }
  return null;
};

export default QuickStartPage;
