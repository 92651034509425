import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Flex,
  Form,
  Input,
  Radio,
  Select,
  Space,
} from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import dayjs from "dayjs";
import { useState } from "react";
import { Profile } from "../../api/user";
import suburbsOptions from "../../data/suburbs";
import {
  CardSelect,
  CardSelectChoice,
  DateInput,
  removeKeyboardWhenScrollProps,
  SaveableTextInput,
  SelectableTag,
  textFilterOption,
} from "./Input";
import { email, required } from "./rules";
import { useSelectMultiple } from "./util";

export const ProfileTextInput = ({
  name,
  label,
  placeholder,
  rules = [required],
  size = "large",
}: {
  name: keyof Profile;
  rules?: any[];
  placeholder?: string;
  label: string;
  size?: SizeType;
}) => (
  <Form.Item<Profile> name={name} rules={rules} label={label} initialValue="">
    <Input
      size={size}
      placeholder={placeholder}
      {...removeKeyboardWhenScrollProps}
    />
  </Form.Item>
);

export const FirstNameInput = () =>
  ProfileTextInput({ name: "firstName", label: "First Name" });

export const LastNameInput = () =>
  ProfileTextInput({ name: "lastName", label: "Last Name" });

export const PreferredName = () =>
  ProfileTextInput({ name: "preferredName", label: "Name" });

export const EmailInput = () => (
  <Form.Item<Profile>
    name="email"
    rules={[required, email]}
    label="Email"
    initialValue=""
  >
    <Input size="large" />
  </Form.Item>
);

export const DobInput = () => {
  return (
    <Form.Item
      name="birthday"
      rules={[required]}
      label="Date of Birth"
      initialValue={dayjs("2000-01-01 00:00:00")}
    >
      <DateInput label="Birthday" />
    </Form.Item>
  );
};

export const GenderInput = () => {
  const [otherSelected, selectOther] = useState<boolean>(false);
  return (
    <Space.Compact direction="vertical">
      <Form.Item name="gender" rules={[required]} label="Gender">
        <Radio.Group
          size="large"
          defaultValue="none"
          onChange={(event) => selectOther(event.target.value === "")}
        >
          <Radio.Button value="male">Male</Radio.Button>
          <Radio.Button value="female">Female</Radio.Button>
          <Radio.Button value="">Other</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="gender" rules={[required]} hidden={!otherSelected}>
        <Input size="large" placeholder="Other..." />
      </Form.Item>
    </Space.Compact>
  );
};

export const PasswordInput = () => (
  <Form.Item<Profile> name="password" rules={[required]} label="Password">
    <Input.Password />
  </Form.Item>
);

export const PriorHobbies = () =>
  ProfileTextInput({
    name: "priorHobbies",
    label: "What are some things that you've spent a lot of time on?",
    rules: [],
  });

export const SuburbInput = ({
  label,
  placeholder,
}: {
  label?: string;
  placeholder?: string;
}) => {
  return (
    <Form.Item<Profile>
      label={label ?? "Your current location"}
      style={{ width: "100%" }}
      name="location"
      rules={[required]}
    >
      <Select
        size="large"
        showSearch
        style={{ width: "100%" }}
        {...removeKeyboardWhenScrollProps}
        placeholder={placeholder ?? "Your location"}
        optionFilterProp="children"
        filterOption={textFilterOption}
        options={suburbsOptions}
      />
    </Form.Item>
  );
};

export const OriginalLocation = ({ label }: { label?: string }) => (
  <Form.Item<Profile>
    label={label ?? "Where do you come from originally?"}
    style={{ width: "100%" }}
    name="origin"
    initialValue=""
  >
    <Input size="large" />
  </Form.Item>
);

export const goals = {
  friends: "🤝 Making new friends",
  network: "💼 Networking for professional opportunities",
  socialise: "🎉 Socializing and having fun",
  explore: "🎨 Exploring new hobbies or interests",
};

const goalChoices = (
  ["friends", "network", "socialise", "explore"] as const
).map((id) => ({ id, text: goals[id] }));

export const GoalInput = () => {
  return (
    <Form.Item<Profile> name="goal" noStyle>
      <CardSelect
        choices={goalChoices}
        onChange={(_) => {}}
        value={""}
        multiple
      />
    </Form.Item>
  );
};

export function AboutMe() {
  return (
    <Form.Item<Profile>
      rules={[required]}
      style={{ width: "100%" }}
      name="aboutMe"
      label="Write a short bio to let people know who you are"
    >
      <Input.TextArea
        placeholder="I am a person of interest"
        size="large"
        rows={4}
      />
    </Form.Item>
  );
}

export function InterestSpecificInput() {
  const interestType = [
    {
      category: "Entertainment",
      subcategories: [
        "Movies",
        "Music",
        "Television Shows",
        "Books and Literature",
        "Theater and Performing Arts",
      ],
    },
    {
      category: "Hobbies and Crafts",
      subcategories: [
        "Reading",
        "Cooking and Baking",
        "Photography",
        "Painting and Drawing",
      ],
    },
    {
      category: "Sports and Fitness",
      subcategories: [
        "Participating in Sports",
        "Watching Sports",
        "Gym and Exercise",
        "Outdoor Activities  (e.g., hiking, camping)",
        "Martial Arts",
        "Running and Marathons",
      ],
    },
    {
      category: "Technology",
      subcategories: [
        "Consumer Electronics",
        "Software and Apps",
        "Video and Computer Games",
        "Robotics",
        "Virtual Reality and Augmented Reality",
      ],
    },
    {
      category: "Travel and Adventure",
      subcategories: [
        "Domestic Travel",
        "International Travel",
        "Adventure Activities",
        "Cultural Exploration",
        "Road Trips",
      ],
    },
    {
      category: "Food and Dining",
      subcategories: [
        "Trying New Cuisines",
        "Restaurant Reviews and Recommendations",
        "Gourmet Cooking",
        "Wine Tasting",
        "Food Blogging",
      ],
    },
    {
      category: "Fashion and Beauty",
      subcategories: [
        "Fashion Trends",
        "Beauty and Skincare",
        "Personal Grooming",
        "Makeup Artistry",
        "Jewelry and Accessories",
      ],
    },
    {
      category: "Social Issues and News",
      subcategories: [
        "Politics",
        "Environmental Issues",
        "Social Justice and Activism",
        "Current Events",
        "Human Rights",
      ],
    },
    {
      category: "Personal Development",
      subcategories: [
        "Self-Help and Motivation",
        "Meditation and Mindfulness",
        "Educational Pursuits",
        "Financial Planning and Investment",
      ],
    },
  ];
  const [active, setActive] = useState<string>("");
  const InputComponent = ({
    value,
    onChange,
  }: {
    onChange: (arg0: string | { [key: string]: boolean }) => void;
    value: { [key: string]: boolean };
  }) => (
    <Space direction="vertical" style={{ width: "100%" }}>
      {interestType.map(({ category, subcategories }) => (
        <CardSelectChoice
          id={category}
          text={
            category +
            (subcategories.filter((s) => value[s]).length > 0
              ? ` (${subcategories.filter((s) => value[s]).length})`
              : "")
          }
          selected={subcategories.filter((s) => value[s]).length > 0}
          onSelect={() => {
            active === category ? setActive("") : setActive(category);
          }}
        >
          {active === category && (
            <Space size={[8, 16]} wrap>
              {subcategories.map((s) => (
                <SelectableTag
                  selected={value[s]}
                  onSelect={(e) => {
                    e.stopPropagation();
                    onChange({ ...value, [s]: !value[s] });
                  }}
                  text={s}
                />
              ))}
            </Space>
          )}
        </CardSelectChoice>
      ))}
    </Space>
  );

  return (
    <Form.Item<Profile> name="interests" noStyle initialValue={{}}>
      <InputComponent onChange={() => {}} value={{}} />
    </Form.Item>
  );
}

export const InterestBundleModifiable_Deprecated = () => {
  const choices = [
    {
      id: "arts_entertainment",
      name: "Creative Minds",
      description:
        "For those who live to create and express through visual arts.",
      interests: [
        "Drawing & Painting",
        "Crafting",
        "Photography",
        "Sculpting",
        "Art Collecting",
      ],
    },
    {
      id: "fitness_sports",
      name: "Fit & Fierce",
      description:
        "Perfect for those who love to stay active and push their physical limits.",
      interests: [
        "Team Sports",
        "Individual Sports",
        "Fitness Activities",
        "Martial Arts",
        "Extreme Sports",
      ],
      icon: require("sports.png"),
    },
    {
      id: "technology_gaming",
      name: "Tech Gurus",
      description:
        "Ideal for tech enthusiasts who are always ahead of the curve with gadgets and code.",
      interests: [
        "Computing",
        "Programming",
        "Gaming",
        "Electronics",
        "Gadgets",
      ],
    },
    {
      id: "culinary_exploration",
      name: "Epicurean Explorers",
      description:
        "Great for those who savor new flavors and culinary adventures.",
      interests: [
        "Cooking",
        "Baking",
        "Culinary Exploration",
        "Wine Tasting",
        "Craft Beer Brewing",
      ],
    },
    {
      id: "outdoor_adventures",
      name: "Adventure Seekers",
      description:
        "For thrill-seekers who love the great outdoors and exploring nature.",
      interests: [
        "Hiking & Trekking",
        "Camping",
        "Fishing",
        "Water Sports",
        "Eco-Tourism",
      ],
    },
    {
      id: "cultural_exploration",
      name: "Culture Vultures",
      description:
        "For those who love to dive into different cultures and histories.",
      interests: [
        "Cultural Travel",
        "Museums",
        "Historical Sites",
        "Eco-Tourism",
        "Adventure Travel",
      ],
    },
    {
      id: "literature_learning",
      name: "Bookworms United",
      description:
        "Perfect for those who find joy in the written word and intellectual discussions.",
      interests: [
        "Reading",
        "Writing",
        "Book Clubs",
        "Literary Analysis",
        "History & Culture",
      ],
    },
    {
      id: "community_social",
      name: "Social Butterflies",
      description:
        "Ideal for individuals who thrive on social interactions and making a difference.",
      interests: [
        "Volunteering",
        "Social Events",
        "Advocacy",
        "Networking",
        "Community Service",
      ],
    },
    {
      id: "health_wellness",
      name: "Zen Masters",
      description:
        "For those focused on maintaining a balanced and healthy lifestyle.",
      interests: [
        "Meditation",
        "Yoga",
        "Nutrition",
        "Holistic Health",
        "Mental Health Awareness",
      ],
    },
    {
      id: "finance_investment",
      name: "Money Movers",
      description:
        "Ideal for individuals interested in growing their wealth and financial knowledge.",
      interests: [
        "Stock Market",
        "Real Estate",
        "Cryptocurrency",
        "Personal Finance Management",
      ],
    },
  ];
  const [selected, select] = useSelectMultiple();
  return (
    <Form.Item name="bundleSelection" noStyle>
      <Space direction="vertical" style={{ width: "100%" }}>
        {choices.map(({ id, name, description, icon, interests }) => (
          <CardSelectChoice
            id={id}
            text={name}
            icon={icon}
            description={description}
            selected={selected[id]}
            onSelect={() => {
              select(id);
              /*
            onSelect={() => {
              select(id);
              /*
              if (interests.every((i) => selected[i])) {
                const unSelectAll = interests.reduce(
                  (a, i) => ({
                    ...a,
                    [i]: false,
                  }),
                  {},
                );
                select(unSelectAll);
              } else {
                const selectAll = interests.reduce(
                  (a, i) => ({
                    ...a,
                    [i]: true,
                  }),
                  {},
                );
                select(selectAll);
              }
               */
            }}
          >
            {/*
            interests.some((i) => selected[i]) && (
              <Space size={[8, 16]} wrap>
                {interests.map((text) => (
                  <SelectableTag
                    text={text}
                    selected={selected[text]}
                    onSelect={(e) => {
                      e.stopPropagation();
                      select(text);
                    }}
                  />
                ))}
              </Space>
            )
              */}
          </CardSelectChoice>
        ))}
      </Space>
    </Form.Item>
  );
};

export const interestBundleChoice = [
  {
    id: "arts_entertainment",
    text: "Creative Minds",
    description:
      "For those who live to create and express through visual arts.",
    interests: [
      "Drawing & Painting",
      "Crafting",
      "Photography",
      "Sculpting",
      "Art Collecting",
    ],
    questions: [
      "What's the most recent art project you've worked on, and what inspired it?",
    ],
    icon: require("images/art.png"),
  },
  {
    id: "fitness_sports",
    text: "Fit & Fierce",
    description:
      "Perfect for those who love to stay active and push their physical limits.",
    interests: [
      "Team Sports",
      "Individual Sports",
      "Fitness Activities",
      "Martial Arts",
      "Extreme Sports",
    ],
    questions: [
      "What's your favorite workout routine or sport, and why do you enjoy it?",
    ],

    icon: require("sports.png"),
  },
  {
    id: "technology_gaming",
    text: "Tech Gurus",
    description:
      "Ideal for tech enthusiasts who are always ahead of the curve with gadgets and code.",
    interests: ["Computing", "Programming", "Gaming", "Electronics", "Gadgets"],
    questions: [
      "What's the coolest tech gadget or app you've discovered recently, and how has it impacted your daily life?",
    ],
    icon: require("images/tech.png"),
  },
  {
    id: "culinary_exploration",
    text: "Foodie Explorer",
    description:
      "Great for those who savor new flavors and culinary adventures.",
    interests: [
      "Cooking",
      "Baking",
      "Culinary Exploration",
      "Wine Tasting",
      "Craft Beer Brewing",
    ],
    questions: [
      "What's the most memorable meal you've ever had, and what made it so special?",
    ],
    icon: require("images/food.png"),
  },
  {
    id: "outdoor_adventures",
    text: "Adventure Seekers",
    description:
      "For thrill-seekers who love the great outdoors and exploring nature.",
    interests: [
      "Hiking & Trekking",
      "Camping",
      "Fishing",
      "Water Sports",
      "Eco-Tourism",
    ],

    questions: [
      "What's the most exciting outdoor adventure you've had, and what made it unforgettable?",
    ],
    icon: require("images/outdoor.png"),
  },
  /*
  {
    id: "cultural_exploration",
    text: "Culture Vultures",
    description:
      "For those who love to dive into different cultures and histories.",
    interests: [
      "Cultural Travel",
      "Museums",
      "Historical Sites",
      "Eco-Tourism",
      "Adventure Travel",
    ],
    questions: [
      "What's a cultural experience or destination that's had a significant impact on you, and why?",
    ],
  },
  {
    id: "health_wellness",
    text: "Zen Masters",
    description:
      "For those focused on maintaining a balanced and healthy lifestyle.",
    interests: [
      "Meditation",
      "Yoga",
      "Nutrition",
      "Holistic Health",
      "Mental Health Awareness",
    ],
    questions: [
      "What's your go-to strategy for maintaining mental and physical well-being, and how has it helped you?",
    ],
  },
   */
  {
    id: "literature_learning",
    text: "Bookworms United",
    description:
      "Perfect for those who find joy in the written word and intellectual discussions.",
    interests: [
      "Reading",
      "Writing",
      "Book Clubs",
      "Literary Analysis",
      "History & Culture",
    ],
    icon: require("images/books.png"),
    questions: [
      "What's a book that changed your perspective on life, and what did you learn from it?",
    ],
  },
  {
    id: "community_social",
    text: "Social Butterflies",
    description:
      "Ideal for individuals who thrive on social interactions and making a difference.",
    interests: [
      "Volunteering",
      "Social Events",
      "Advocacy",
      "Networking",
      "Community Service",
    ],
    icon: require("images/social.png"),
    questions: [
      "What kind of social events or causes are you most passionate about?",
    ],
  },
  {
    id: "finance_investment",
    text: "Money Movers",
    description:
      "Ideal for individuals interested in growing their wealth and financial knowledge.",
    interests: [
      "Stock Market",
      "Real Estate",
      "Cryptocurrency",
      "Personal Finance Management",
    ],
    questions: [
      "What's the best financial advice you've received, and how has it shaped your financial journey?",
    ],
    icon: require("images/money.png"),
  },
];
export const InterestBundleInput = () => {
  // const [selected, select] = useSelectMultiple();
  const InputComponent = ({
    value,
    onChange,
  }: {
    onChange: (arg0: string | { [key: string]: boolean }) => void;
    value: { [key: string]: boolean };
  }) => (
    <Space direction="vertical" style={{ width: "100%" }}>
      {interestBundleChoice.map(
        ({ id, text, description, icon, interests, questions }) => (
          <CardSelectChoice
            key={id}
            id={id}
            text={text}
            icon={icon}
            description={description}
            selected={value[id]}
            onSelect={() => onChange({ ...value, [id]: !value[id] })}
          >
            {value[id] ? (
              <Space direction="vertical">
                <Divider />
                <Form.Item
                  name={["interestsQuestions", id, questions[0]]}
                  label={questions[0]}
                  layout="vertical"
                >
                  <SaveableTextInput />
                </Form.Item>
              </Space>
            ) : null}
          </CardSelectChoice>
        ),
      )}
    </Space>
  );
  return (
    <Form.Item name="interests" noStyle initialValue={{}}>
      <InputComponent onChange={(_) => {}} value={{}} />
    </Form.Item>
  );
};

export const localProfilePics = Array(17)
  .fill(0)
  .map((_, index) => require(`images/profiles/${index}.png`));
/*
const profilePics = [
  "https://cdn.midjourney.com/f0bde425-8f85-4abb-aa85-16f77bd4d03a/0_0.png",
  "https://cdn.midjourney.com/f0bde425-8f85-4abb-aa85-16f77bd4d03a/0_2.png",
  "https://cdn.midjourney.com/15c0c67e-f900-4f0a-9adf-27a8f5d8cfae/0_2.png",
  "https://cdn.midjourney.com/abec0c5f-dd19-4705-8f08-f70d5d3832a6/0_0.png",
  "https://cdn.midjourney.com/fe47924d-e8ac-4dcb-8943-659f2d3f5c76/0_0.png",
  "https://cdn.midjourney.com/0c557439-3776-40e3-9784-ccc4f1903b4d/0_1.png",
  "https://cdn.midjourney.com/cfcc28a7-c4f6-47c0-88f9-820f2810906f/0_2.png",
];
   */
export const ProfilePicInput = () => {
  const form = useFormInstance();
  const [pic, setPic] = useState<string>("");
  const setProfilePic = (p: string) => {
    setPic(p);
    form.setFieldValue("profilePicId", p);
  };
  return (
    <Flex gap={30} vertical align="stretch">
      <Flex justify="center">
        <Avatar
          icon={<UserOutlined />}
          size={150}
          src={localProfilePics[pic as unknown as number]}
        />
      </Flex>
      <Form.Item name="profilePicId" noStyle></Form.Item>
      <Card title="Choose your profile photo">
        <Flex align="center" vertical gap={10}>
          <Space wrap>
            {localProfilePics.map((p, index) => (
              <Button
                key={index}
                onClick={() => setProfilePic(index.toString())}
                icon={
                  <Avatar
                    shape="square"
                    icon={<UserOutlined />}
                    src={p}
                    size={64}
                  />
                }
                size="large"
                style={{ width: 64, height: 64 }}
              />
            ))}
          </Space>
        </Flex>
      </Card>
    </Flex>
  );
};

const nameToComponent = {
  aboutMe: <AboutMe />,
  goal: <GoalInput />,
  interests: <InterestSpecificInput />,
  bundleSelection: <InterestBundleInput />,
  profilePic: <ProfilePicInput />,
};
export default nameToComponent;
