import { getDoc } from "firebase/firestore";
import { createContext, useState } from "react";
import { getUserRef, Profile, UserId } from "./user";

export const FriendsContext = createContext<{ [key: string]: Profile | null }>(
  {},
);

function getFriend(uid: UserId) {
  return getDoc(getUserRef(uid)).then((querySnapshot) => {
    if (!querySnapshot.exists()) {
      return false;
    }
    return querySnapshot.data();
  });
}

export function FriendsProvider({ children }: React.PropsWithChildren) {
  const [profileData, setProfileData] = useState<{
    [key: string]: Profile | null;
  }>({});

  const profileProxy = new Proxy(profileData, {
    get(target, uid: UserId) {
      if (uid in target) {
        return target[uid];
      }
      getFriend(uid).then((data) =>
        setProfileData((prev: {}) => ({ ...prev, [uid]: data })),
      );
    },
  });

  return (
    <FriendsContext.Provider value={profileProxy}>
      {children}
    </FriendsContext.Provider>
  );
}
