import { LeftOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { OTPRef } from "antd/es/input/OTP";
import { log, useLogPage } from "api/analytics";
import { getErrorMessageFromFireauth } from "api/auth";
import { auth, remoteConfig } from "api/firebaseConfig";
import { Logo } from "component/Container";
import { PhoneNumberInput } from "component/Form/LoginInput";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  ApplicationVerifier,
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { getValue } from "firebase/remote-config";
import { LoginStatus } from "Login";
import { createContext, useContext, useEffect, useRef, useState } from "react";
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

export const PhoneLoginContext = createContext<{
  confirmation: ConfirmationResult | null;
  signIn: (phoneNumber: string) => Promise<any>;
  phoneNumber: string;
  requestTimestamp: Dayjs;
}>({
  confirmation: null,
  signIn: () => Promise.reject("Phone Login Provider not set up"),
  phoneNumber: "",
  requestTimestamp: dayjs(),
});

export const PhoneLoginProvider = ({ children }: React.PropsWithChildren) => {
  const [confirmation, setConfirmation] = useState<ConfirmationResult | null>(
    null,
  );
  const [requestTimestamp, setRequestTimestamp] = useState<Dayjs>(dayjs());
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const captcha = useCaptcha();

  const signIn = (phoneNumber: string) => {
    setRequestTimestamp(dayjs());
    setPhoneNumber(phoneNumber);
    return signInWithPhoneNumber(auth, phoneNumber, captcha.current!).then(
      (confirmationResult) => setConfirmation(confirmationResult),
    );
  };

  return (
    <PhoneLoginContext.Provider
      value={{
        confirmation,
        signIn,
        phoneNumber,
        requestTimestamp,
      }}
    >
      {children}
      <div id="sign-in-button"> </div>
    </PhoneLoginContext.Provider>
  );
};

export const PhoneLogin = ({ hideLabel }: any) => {
  const { signIn } = useContext(PhoneLoginContext);
  const { status, setStatus } = useContext(LoginStatus);
  const prefix = getValue(remoteConfig, "phoneCountryCode").asString();

  const [form] = useForm();
  const submit = (num: string) => {
    setStatus({
      loading: true,
      error: false,
    });
    signIn(`${prefix}${num}`)
      .then(() => {
        return setStatus({
          phoneLogin: true,
          loading: false,
          error: false,
        });
      })
      .catch(getErrorMessageFromFireauth)
      .then((error) => {
        if (error) {
          form.setFields([
            {
              name: "phoneNumber",
              errors: [error],
            },
          ]);
        }
      });
  };
  return (
    <Form form={form} onFinish={(values) => submit(values.phoneNumber)}>
      <PhoneNumberInput
        prefix={prefix}
        label={!hideLabel}
        loading={status.loading}
      />
    </Form>
  );
};

export const PhoneLoginConfirmation = () => {
  const { confirmation, phoneNumber } = useContext(PhoneLoginContext);
  const inputRef = useRef<OTPRef>(null);
  const { status, setStatus } = useContext(LoginStatus);
  useEffect(() => inputRef.current?.focus(), []);
  useLogPage("signIn_otp");
  if (!confirmation) {
    return <div>something went wrong</div>;
  }

  return (
    <>
      <Button
        icon={<LeftOutlined />}
        type="text"
        onClick={() => setStatus({ loading: false, error: false })}
      />
      <Flex justify="space-between">
        <Typography.Title level={2}>Verification</Typography.Title>
        <Logo size="small" />
      </Flex>
      <Typography.Paragraph>
        Please enter the verification code sent to {phoneNumber}
      </Typography.Paragraph>
      <Typography.Text type="danger">{status.error}</Typography.Text>
      <Input.OTP
        ref={inputRef}
        size="large"
        variant="filled"
        onChange={(text) => {
          if (text.length === 6) {
            setStatus((prev) => ({
              ...prev,
              loading: true,
              error: false,
            }));
            confirmation
              .confirm(text)
              .then(() => log("signIn_otpComplete"))
              .catch(getErrorMessageFromFireauth)
              .then((errorMessage) =>
                setStatus((prev) => ({
                  ...prev,
                  loading: false,
                  error: errorMessage,
                })),
              );
          }
        }}
      />
      <ResendOTPCode />
      <Button type="primary" loading={status.loading}>
        Confirm
      </Button>
    </>
  );
};

function ResendOTPCode() {
  const { requestTimestamp } = useContext(PhoneLoginContext);
  const timeToResend = useTimer(requestTimestamp, /* nSeconds= */ 60);
  if (timeToResend < 0) {
    return <Button type="text">Resend code</Button>;
  }
  return (
    <Button type="text" disabled>
      Resend in {timeToResend} seconds
    </Button>
  );
}

function useCaptcha() {
  const captcha = useRef<ApplicationVerifier | null>(null);
  useEffect(() => {
    captcha.current = new RecaptchaVerifier(auth, "sign-in-button", {
      size: "invisible",
      callback: (response: any) => {},
    });
  }, []);
  return captcha;
}

function useTimer(startTime: Dayjs, nSeconds: number = 60) {
  const [timeToResend, setTimeToResend] = useState(
    Math.floor(startTime.diff(dayjs()) / 1000 + 60),
  );
  useEffect(() => {
    const interval = setInterval(
      () => setTimeToResend(Math.floor(startTime.diff(dayjs()) / 1000 + 60)),
      1000,
    );
    return () => clearInterval(interval);
  }, [startTime]);
  return timeToResend;
}
