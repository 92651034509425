import { Tag } from "antd";

const tagColors: {
  [key: string]: string;
} = {};

const availableColors = [
  "magenta",
  "red",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
];

export const EasyTag = ({ tag }: { tag: string }) => {
  if (!tagColors[tag]) {
    tagColors[tag] =
      availableColors[Object.keys(tagColors).length % availableColors.length];
  }
  return <Tag color={tagColors[tag]}>{tag}</Tag>;
};
