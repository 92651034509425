import { Dialog } from "antd-mobile";
import Loader from "component/Loader";
import { Dayjs } from "dayjs";
import {
  arrayUnion,
  collection,
  collectionGroup,
  doc,
  DocumentReference,
  query,
  runTransaction,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { createContext, useContext, useMemo } from "react";
import { activities, AvailableActivity } from "../component/Form/QueueInput";
import { log } from "./analytics";
import { AuthContext } from "./auth";
import { auth, db } from "./firebaseConfig";
import {
  createFirestoreDateConverter,
  dayjsToFirebaseDate,
  firebaseDateToDayjs,
  Status,
  useDocumentSnapshot,
  useQuerySnapshot,
} from "./firestore";
import { quickstartDelete, quickstartLoad } from "./quickstart";
import { Profile, UserContext, UserId } from "./user";
import { Branded } from "./utils";

export function maybeCreateQueueFromQuickstart() {
  const qs = quickstartLoad();
  if (!qs.queue) {
    return;
  }
  quickstartDelete("queue");
  createQueue(qs.queue).then(() => log("quickstart_signup_success"));
}

export function createQueue(
  queue: Partial<QueueType>,
  ref?: DocumentReference,
) {
  const user = auth.currentUser;
  if (!user) {
    throw Error();
  }
  const q = queue as any;
  q.timeCreated = serverTimestamp();
  q.creator = user.uid;
  q.type = "creator";
  q.participants = [];

  const queueRef =
    ref ||
    queue.ref ||
    (queue.id &&
      doc(getQueueCollectionForUser(user.uid as UserId), queue.id)) ||
    getNewQueueDocRef();

  return setDoc(
    queueRef.withConverter(
      createFirestoreDateConverter([], queueFirestoreConverter),
    ),
    { ...q, id: queueRef.id },
  );
}

export function getNewQueueDocRef() {
  const user = auth.currentUser;
  if (!user) {
    throw Error();
  }
  return doc(getQueueCollectionForUser(user.uid as UserId));
}

const queueFirestoreConverter = {
  toFirestore: (item: any) => {
    if (!item.preferredDate) {
      return item;
    }
    return {
      ...item,
      preferredDate: item["preferredDate"].map((d: Dayjs) =>
        dayjsToFirebaseDate(d),
      ),
    };
  },
  fromFirestore: (item: any) => {
    if (!item.preferredDate) {
      return item;
    }
    if (!Array.isArray(item.preferredDate)) {
      return {
        ...item,
        preferredDate: [firebaseDateToDayjs(item["preferredDate"])],
      };
    }
    return {
      ...item,
      preferredDate: item.preferredDate.map((d: Timestamp) =>
        firebaseDateToDayjs(d),
      ),
    };
  },
};

export function leaveOrDeleteQueue(q: QueueType) {
  return runTransaction(db, async (transaction) => {
    if (q.ref) {
      transaction.delete(q.ref);
    }
  });
}

export function leaveOrDeleteQueueWithUIConfirmation(q: any) {
  return Dialog.show({
    content: "Oh no! Are you sure you want to stop queueing?",
    closeOnAction: true,
    actions: [
      { key: "cancel", text: "Stay in queue" },
      {
        key: "exit",
        text: "Yes, stop queueing",
        danger: true,
        onClick: () => leaveOrDeleteQueue(q),
      },
    ],
  });
}

export type QueueId = Branded<string, "QueueId">;

export type QueueType = {
  id: QueueId;
  creator: UserId;
  preferredTime: "afternoon";
  preferredDate: Dayjs[];
  activityType: AvailableActivity;
  type: "creator" | "participant";
  participants: UserId[];
  min: number;
  timeCreated: Dayjs;
  additionalQuestions: { [key: string]: string };

  post: string;
  options?: FilterOptions;
  ref?: DocumentReference;
};
export type ActivityType = (typeof activities)[0];
export type FilterOptions = {
  verifiedOnly: boolean;
  sameGenderOnly: boolean;
  level: "intermediate";
};

export function joinQueue(queue: QueueType, profile: Profile) {
  if (!queue.ref || !profile.ref) {
    return;
  }
  updateDoc(queue.ref, {
    participants: arrayUnion(profile.id),
  });
  updateDoc(profile.ref, {
    quickJoinQueue: arrayUnion(queue.ref.id),
  });
}

export function getAllQueueCollection() {
  return collectionGroup(db, "queue").withConverter(
    createFirestoreDateConverter(["timeCreated"], queueFirestoreConverter),
  );
}

function getQueueDocQueryWithId(qid: QueueId) {
  return query(getAllQueueCollection(), where("id", "==", qid));
}

export function useQueueWithQueueId(qid: QueueId) {
  const q = useMemo(() => getQueueDocQueryWithId(qid), [qid]);
  const { loading, error, data } = useQuerySnapshot(q);
  if (data && data.length === 1) {
    return { loading, error, queue: data[0] as QueueType };
  }
  return { loading, error, data };
}

function useQueue() {
  const authUser = useContext(AuthContext);
  const uid = authUser.user!.uid;
  return useQueueWithUserId(uid as UserId);
}

export function useQueueWithUserId(uid: UserId) {
  const q = useMemo(() => query(getQueueCollectionForUser(uid)), [uid]);
  const { loading, error, data } = useQuerySnapshot(q);
  if (error && error === "missing") {
    return { loading: false, error: true, queue: null };
  }
  return { loading, error, queue: data as QueueType[] };
}

export function useQuickJoinQueue() {
  const { profile } = useContext(UserContext);
  const qj = useMemo(
    () => query(getQuickJoinQueueQuery(profile.quickJoinQueue)),
    [profile.quickJoinQueue],
  );
  const { loading, error, data } = useQuerySnapshot(qj);

  return { loading, error, data: data as QueueType[] };
}
export function getQuickJoinQueueQuery(quickJoinQueue?: QueueId[]) {
  const list =
    quickJoinQueue && quickJoinQueue.length > 0 ? quickJoinQueue : ["no"];
  return query(getAllQueueCollection(), where("id", "in", list));
}

export function getQueueCollectionForUser(uid: UserId) {
  return collection(db, "users", uid, "queue").withConverter(
    createFirestoreDateConverter(["timeCreated"], queueFirestoreConverter),
  );
}

export const useQueueCount = (activityType: string) => {
  const q = useMemo(() => doc(db, "counter", activityType), [activityType]);
  const x = useDocumentSnapshot(q);
  const { data } = x;
  console.log(x);
  if (!data || !data.count) {
    return 0;
  }
  return data.count;
};

export const QueueContext = createContext<
  Status<{ queue?: QueueType[] | null }>
>({
  loading: true,
  error: false,
});

export function QueueProvider({ children }: { children: React.ReactNode }) {
  const queue = useQueue();

  if (queue.loading) {
    return <Loader />;
  }

  return (
    <QueueContext.Provider value={queue}>{children}</QueueContext.Provider>
  );
}
