import dayjs from "dayjs";
import { collection, doc } from "firebase/firestore";
import { db } from "./firebaseConfig";
import { QueueId, QueueType } from "./queue";
import { Profile } from "./user";

export const quickstartSave = (fields: {
  profile: Partial<Profile>;
  queue: Partial<QueueType>;
}) => {
  fields.queue.id = doc(collection(db, "x")).id as QueueId;
  window.localStorage.setItem("quickstart", JSON.stringify(fields));
};

export const quickstartLoad: () => {
  profile?: Partial<Profile>;
  queue?: Partial<QueueType>;
} = () => {
  const quickstart = window.localStorage.getItem("quickstart");
  try {
    const options = JSON.parse(quickstart || "") as {
      profile: Partial<Profile>;
      queue: Partial<QueueType>;
    };
    if (options.queue.preferredDate) {
      options.queue.preferredDate = options.queue.preferredDate?.map((date) =>
        dayjs(date),
      );
    }
    return options;
  } catch {
    return {};
  }
};

export const quickstartDelete = (key: "profile" | "queue") => {
  const quickstart = window.localStorage.getItem("quickstart");
  try {
    const options = JSON.parse(quickstart || "{}") as {
      profile?: Partial<Profile>;
      queue?: Partial<QueueType>;
    };
    delete options[key];
    window.localStorage.setItem("quickstart", JSON.stringify(options));
  } catch {
    return {};
  }
};
