export const data = [
  {
    name: "Sydney",
    area: "4.30",
    population: 31391,
    density: 7300.232558139535,
  },
  {
    name: "Chippendale",
    area: "0.50",
    population: 9282,
    density: 18564,
  },
  {
    name: "Zetland",
    area: "0.80",
    population: 13968,
    density: 17460,
  },
  {
    name: "Wolli Creek",
    area: "0.70",
    population: 11872,
    density: 16960,
  },
  {
    name: "Waterloo",
    area: "1.10",
    population: 17851,
    density: 16228.181818181816,
  },
  {
    name: "Ultimo",
    area: "0.60",
    population: 9316,
    density: 15526.666666666668,
  },
  {
    name: "Pyrmont",
    area: "0.90",
    population: 13735,
    density: 15261.111111111111,
  },
  {
    name: "Surry Hills",
    area: "1.30",
    population: 16781,
    density: 12908.461538461537,
  },
  {
    name: "Potts Point - Woolloomooloo",
    area: "1.50",
    population: 19076,
    density: 12717.333333333334,
  },
  {
    name: "Darlinghurst",
    area: "0.90",
    population: 11124,
    density: 12360,
  },
  {
    name: "Rhodes",
    area: "1.00",
    population: 12263,
    density: 12263,
  },
  {
    name: "Newtown (NSW)",
    area: "1.60",
    population: 15397,
    density: 9623.125,
  },
  {
    name: "Glebe - Forest Lodge",
    area: "2.30",
    population: 21505,
    density: 9350,
  },
  {
    name: "Burwood (NSW)",
    area: "1.90",
    population: 17485,
    density: 9202.631578947368,
  },
  {
    name: "Redfern",
    area: "1.70",
    population: 15005,
    density: 8826.470588235294,
  },
  {
    name: "Rosebery - Beaconsfield",
    area: "1.60",
    population: 13337,
    density: 8335.625,
  },
  {
    name: "Neutral Bay - Kirribilli",
    area: "2.20",
    population: 17973,
    density: 8169.545454545454,
  },
  {
    name: "Campsie",
    area: "2.70",
    population: 21727,
    density: 8047.0370370370365,
  },
  {
    name: "Bondi",
    area: "7.50",
    population: 60118,
    density: 8015.733333333334,
  },
  {
    name: "Lakemba",
    area: "2.20",
    population: 17338,
    density: 7880.90909090909,
  },
  {
    name: "Kingsford",
    area: "2.00",
    population: 15613,
    density: 7806.5,
  },
  {
    name: "Wiley Park",
    area: "1.40",
    population: 10468,
    density: 7477.142857142858,
  },
  {
    name: "Coogee - Clovelly",
    area: "2.70",
    population: 20166,
    density: 7468.888888888889,
  },
  {
    name: "Mascot",
    area: "3.10",
    population: 23036,
    density: 7430.967741935484,
  },
  {
    name: "Hurstville",
    area: "3.60",
    population: 25819,
    density: 7171.944444444444,
  },
  {
    name: "North Sydney - Lavender Bay",
    area: "1.90",
    population: 13292,
    density: 6995.789473684211,
  },
  {
    name: "Petersham - Stanmore",
    area: "3.00",
    population: 20690,
    density: 6896.666666666667,
  },
  {
    name: "Dulwich Hill - Lewisham",
    area: "2.70",
    population: 18486,
    density: 6846.666666666666,
  },
  {
    name: "Dee",
    area: "4.10",
    population: 27920,
    density: 6809.756097560976,
  },
  {
    name: "Parramatta",
    area: "3.60",
    population: 24185,
    density: 6718.055555555556,
  },
  {
    name: "Ashfield",
    area: "3.70",
    population: 24655,
    density: 6663.513513513513,
  },
  {
    name: "Annandale (NSW)",
    area: "1.50",
    population: 9805,
    density: 6536.666666666667,
  },
  {
    name: "Double Bay - Darling Point",
    area: "1.60",
    population: 10250,
    density: 6406.25,
  },
  {
    name: "Crows Nest - Waverton",
    area: "3.00",
    population: 18608,
    density: 6202.666666666667,
  },
  {
    name: "Canterbury - South",
    area: "2.10",
    population: 12940,
    density: 6161.9047619047615,
  },
  {
    name: "Chatswood - East",
    area: "3.40",
    population: 20784,
    density: 6112.941176470588,
  },
  {
    name: "Cremorne - Cammeray",
    area: "3.20",
    population: 19544,
    density: 6107.5,
  },
  {
    name: "Balmain",
    area: "2.60",
    population: 15760,
    density: 6061.538461538461,
  },
  {
    name: "Rockdale - Banksia",
    area: "3.60",
    population: 21613,
    density: 6003.611111111111,
  },
  {
    name: "Leichhardt",
    area: "3.00",
    population: 17934,
    density: 5978,
  },
  {
    name: "Dover Heights",
    area: "1.90",
    population: 11296,
    density: 5945.263157894738,
  },
  {
    name: "Wentworthville - Westmead",
    area: "3.90",
    population: 23054,
    density: 5911.282051282052,
  },
  {
    name: "Woollahra",
    area: "1.30",
    population: 7657,
    density: 5890,
  },
  {
    name: "Wahroonga (West) - Waitara",
    area: "2.10",
    population: 12324,
    density: 5868.571428571428,
  },
  {
    name: "Homebush",
    area: "3.80",
    population: 22262,
    density: 5858.421052631579,
  },
  {
    name: "Penshurst",
    area: "1.70",
    population: 9813,
    density: 5772.352941176471,
  },
  {
    name: "Artarmon",
    area: "1.70",
    population: 9683,
    density: 5695.882352941177,
  },
  {
    name: "Randwick",
    area: "5.30",
    population: 29837,
    density: 5629.622641509434,
  },
  {
    name: "Camperdown - Darlington",
    area: "1.70",
    population: 9426,
    density: 5544.705882352941,
  },
  {
    name: "Kogarah",
    area: "5.70",
    population: 31174,
    density: 5469.122807017544,
  },
  {
    name: "Maroubra",
    area: "5.90",
    population: 32126,
    density: 5445.0847457627115,
  },
  {
    name: "Kensington (NSW)",
    area: "2.60",
    population: 13810,
    density: 5311.538461538461,
  },
  {
    name: "Liverpool",
    area: "6.40",
    population: 33870,
    density: 5292.1875,
  },
  {
    name: "South Coogee",
    area: "1.10",
    population: 5728,
    density: 5207.272727272727,
  },
  {
    name: "Bankstown",
    area: "7.10",
    population: 36578,
    density: 5151.830985915493,
  },
  {
    name: "Pagewood - Hillsdale - Daceyville",
    area: "3.10",
    population: 15777,
    density: 5089.354838709677,
  },
  {
    name: "Auburn",
    area: "8.20",
    population: 41344,
    density: 5041.951219512195,
  },
  {
    name: "Drummoyne - Rodd Point",
    area: "3.70",
    population: 18629,
    density: 5034.864864864865,
  },
  {
    name: "Merrylands - Holroyd",
    area: "5.00",
    population: 24865,
    density: 4973,
  },
  {
    name: "Bexley",
    area: "5.30",
    population: 26210,
    density: 4945.2830188679245,
  },
  {
    name: "Erskineville - Alexandria",
    area: "4.30",
    population: 20661,
    density: 4804.883720930233,
  },
  {
    name: "Marrickville",
    area: "5.80",
    population: 26969,
    density: 4649.827586206897,
  },
  {
    name: "Bellevue Hill",
    area: "3.20",
    population: 14866,
    density: 4645.625,
  },
  {
    name: "Belmore - Belfield",
    area: "4.50",
    population: 20875,
    density: 4638.888888888889,
  },
  {
    name: "Ryde",
    area: "7.30",
    population: 33847,
    density: 4636.575342465753,
  },
  {
    name: "St Leonards - Naremburn",
    area: "2.80",
    population: 12913,
    density: 4611.785714285715,
  },
  {
    name: "Five Dock - Abbotsford",
    area: "4.60",
    population: 21181,
    density: 4604.565217391305,
  },
  {
    name: "Fairfield",
    area: "8.90",
    population: 40362,
    density: 4535.056179775281,
  },
  {
    name: "Croydon Park - Enfield",
    area: "4.00",
    population: 17835,
    density: 4458.75,
  },
  {
    name: "West Ryde - Meadowbank",
    area: "4.80",
    population: 21225,
    density: 4421.875,
  },
  {
    name: "Riverwood",
    area: "2.70",
    population: 11924,
    density: 4416.296296296296,
  },
  {
    name: "Granville - Clyde",
    area: "5.60",
    population: 24410,
    density: 4358.928571428572,
  },
  {
    name: "Croydon",
    area: "2.60",
    population: 11186,
    density: 4302.307692307692,
  },
  {
    name: "South Hurstville - Blakehurst",
    area: "6.20",
    population: 26481,
    density: 4271.129032258064,
  },
  {
    name: "Punchbowl",
    area: "5.20",
    population: 22080,
    density: 4246.153846153846,
  },
  {
    name: "Willoughby",
    area: "3.10",
    population: 13032,
    density: 4203.870967741936,
  },
  {
    name: "South Wentworthville",
    area: "1.80",
    population: 7559,
    density: 4199.444444444444,
  },
  {
    name: "Strathfield",
    area: "6.40",
    population: 26851,
    density: 4195.46875,
  },
  {
    name: "Roselands",
    area: "3.60",
    population: 14994,
    density: 4165,
  },
  {
    name: "Berala",
    area: "2.10",
    population: 8738,
    density: 4160.952380952381,
  },
  {
    name: "Paddington - Moore Park",
    area: "3.70",
    population: 15278,
    density: 4129.189189189189,
  },
  {
    name: "Canley Vale - Canley Heights",
    area: "5.40",
    population: 22269,
    density: 4123.888888888889,
  },
  {
    name: "Yagoona - Birrong",
    area: "5.60",
    population: 23042,
    density: 4114.642857142858,
  },
  {
    name: "Canterbury (North) - Ashbury",
    area: "3.00",
    population: 12321,
    density: 4107,
  },
  {
    name: "Acacia Gardens",
    area: "0.90",
    population: 3694,
    density: 4104.444444444444,
  },
  {
    name: "Chatswood (West) - Lane Cove North",
    area: "5.50",
    population: 22564,
    density: 4102.545454545455,
  },
  {
    name: "Greenacre",
    area: "7.00",
    population: 28518,
    density: 4074,
  },
  {
    name: "Narwee - Beverly Hills",
    area: "3.40",
    population: 13822,
    density: 4065.294117647059,
  },
  {
    name: "Arncliffe - Bardwell Valley",
    area: "4.30",
    population: 17437,
    density: 4055.1162790697676,
  },
  {
    name: "Monterey - Brighton-le-Sands - Kyeemagh",
    area: "3.40",
    population: 13758,
    density: 4046.470588235294,
  },
  {
    name: "Matraville - Chifley",
    area: "3.40",
    population: 13749,
    density: 4043.823529411765,
  },
  {
    name: "Green Valley",
    area: "3.20",
    population: 12800,
    density: 4000,
  },
  {
    name: "Botany",
    area: "3.40",
    population: 13523,
    density: 3977.3529411764707,
  },
  {
    name: "Haberfield - Summer Hill",
    area: "3.50",
    population: 13887,
    density: 3967.714285714286,
  },
  {
    name: "Epping",
    area: "8.90",
    population: 34575,
    density: 3884.8314606741574,
  },
  {
    name: "Lilyfield - Rozelle",
    area: "3.60",
    population: 13918,
    density: 3866.111111111111,
  },
  {
    name: "Guildford",
    area: "12.30",
    population: 47119,
    density: 3830.813008130081,
  },
  {
    name: "Lurnea - Cartwright",
    area: "3.50",
    population: 13250,
    density: 3785.714285714286,
  },
  {
    name: "Toongabbie - West",
    area: "3.20",
    population: 11990,
    density: 3746.875,
  },
  {
    name: "Blacktown",
    area: "8.10",
    population: 30317,
    density: 3742.8395061728397,
  },
  {
    name: "Concord - Mortlake - Cabarita",
    area: "6.30",
    population: 23547,
    density: 3737.6190476190477,
  },
  {
    name: "Eastwood",
    area: "5.30",
    population: 19690,
    density: 3715.0943396226417,
  },
  {
    name: "Narrabeen - Wheeler Heights",
    area: "3.00",
    population: 11137,
    density: 3712.3333333333335,
  },
  {
    name: "Kellyville Ridge - The Ponds",
    area: "5.90",
    population: 21571,
    density: 3656.101694915254,
  },
  {
    name: "Lane Cove",
    area: "4.50",
    population: 16385,
    density: 3641.1111111111113,
  },
  {
    name: "Sans Souci - Ramsgate",
    area: "4.60",
    population: 16720,
    density: 3634.7826086956525,
  },
  {
    name: "Miranda - Yowie Bay",
    area: "6.00",
    population: 21781,
    density: 3630.1666666666665,
  },
  {
    name: "Northmead",
    area: "5.90",
    population: 21339,
    density: 3616.779661016949,
  },
  {
    name: "Revesby",
    area: "5.10",
    population: 18212,
    density: 3570.980392156863,
  },
  {
    name: "Manly - Fairlight",
    area: "6.70",
    population: 23648,
    density: 3529.55223880597,
  },
  {
    name: "Lidcombe",
    area: "6.40",
    population: 22568,
    density: 3526.25,
  },
  {
    name: "Kingsgrove (South) - Bardwell Park",
    area: "3.80",
    population: 13204,
    density: 3474.7368421052633,
  },
  {
    name: "Hassall Grove - Plumpton",
    area: "6.20",
    population: 21424,
    density: 3455.483870967742,
  },
  {
    name: "Collaroy - Collaroy Plateau",
    area: "4.10",
    population: 13999,
    density: 3414.390243902439,
  },
  {
    name: "Mortdale - Oatley",
    area: "4.40",
    population: 15014,
    density: 3412.272727272727,
  },
  {
    name: "Beacon Hill - Narraweena",
    area: "4.30",
    population: 14597,
    density: 3394.651162790698,
  },
  {
    name: "Stanhope Gardens - Parklea",
    area: "3.90",
    population: 13152,
    density: 3372.3076923076924,
  },
  {
    name: "Gladesville - Huntleys Point",
    area: "4.70",
    population: 15814,
    density: 3364.68085106383,
  },
  {
    name: "Hoxton Park - Carnes Hill - Horningsea Park",
    area: "4.10",
    population: 13792,
    density: 3363.9024390243903,
  },
  {
    name: "Mosman",
    area: "8.70",
    population: 29071,
    density: 3341.4942528735637,
  },
  {
    name: "Bonnyrigg Heights - Bonnyrigg",
    area: "5.20",
    population: 17305,
    density: 3327.8846153846152,
  },
  {
    name: "Caringbah",
    area: "8.10",
    population: 26802,
    density: 3308.888888888889,
  },
  {
    name: "Ashcroft - Busby - Miller",
    area: "5.50",
    population: 18128,
    density: 3296,
  },
  {
    name: "Carlingford",
    area: "9.70",
    population: 31923,
    density: 3291.030927835052,
  },
  {
    name: "Cabramatta",
    area: "12.90",
    population: 42426,
    density: 3288.8372093023254,
  },
  {
    name: "Edensor Park",
    area: "3.10",
    population: 10183,
    density: 3284.838709677419,
  },
  {
    name: "Quakers Hill",
    area: "7.80",
    population: 25610,
    density: 3283.3333333333335,
  },
  {
    name: "Earlwood",
    area: "5.60",
    population: 18071,
    density: 3226.964285714286,
  },
  {
    name: "Concord West - North Strathfield",
    area: "4.10",
    population: 13111,
    density: 3197.8048780487807,
  },
  {
    name: "Gymea - Grays Point",
    area: "5.80",
    population: 18472,
    density: 3184.8275862068967,
  },
  {
    name: "Freshwater - Brookvale",
    area: "7.20",
    population: 22682,
    density: 3150.277777777778,
  },
  {
    name: "Glenwood",
    area: "5.10",
    population: 16057,
    density: 3148.4313725490197,
  },
  {
    name: "Pendle Hill - Girraween",
    area: "4.50",
    population: 14123,
    density: 3138.4444444444443,
  },
  {
    name: "Toongabbie - Constitution Hill",
    area: "7.50",
    population: 23451,
    density: 3126.8,
  },
  {
    name: "Wentworth Point - Sydney Olympic Park",
    area: "7.30",
    population: 22818,
    density: 3125.753424657534,
  },
  {
    name: "Oatlands - Dundas Valley",
    area: "6.10",
    population: 19032,
    density: 3120,
  },
  {
    name: "St Johns Park - Wakeley",
    area: "3.60",
    population: 11123,
    density: 3089.722222222222,
  },
  {
    name: "Sutherland - Kirrawee",
    area: "7.80",
    population: 23976,
    density: 3073.846153846154,
  },
  {
    name: "Bidwill - Hebersham - Emerton",
    area: "6.10",
    population: 18678,
    density: 3061.9672131147545,
  },
  {
    name: "Wollongong",
    area: "11.20",
    population: 34117,
    density: 3046.1607142857147,
  },
  {
    name: "Smithfield - Wetherill Park",
    area: "6.60",
    population: 19832,
    density: 3004.848484848485,
  },
  {
    name: "Greystanes - South",
    area: "5.70",
    population: 16762,
    density: 2940.7017543859647,
  },
  {
    name: "Chester Hill - Sefton",
    area: "7.30",
    population: 21396,
    density: 2930.958904109589,
  },
  {
    name: "Kellyville",
    area: "10.70",
    population: 31116,
    density: 2908.03738317757,
  },
  {
    name: "Eastlakes",
    area: "3.10",
    population: 9005,
    density: 2904.838709677419,
  },
  {
    name: "Oatley - Hurstville Grove",
    area: "4.40",
    population: 12688,
    density: 2883.6363636363635,
  },
  {
    name: "Colyton - Oxley Park",
    area: "4.70",
    population: 13418,
    density: 2854.8936170212764,
  },
  {
    name: "Greenwich - Riverview",
    area: "4.60",
    population: 13040,
    density: 2834.7826086956525,
  },
  {
    name: "Denistone",
    area: "2.50",
    population: 7079,
    density: 2831.6,
  },
  {
    name: "Woolooware - Burraneer",
    area: "3.30",
    population: 9335,
    density: 2828.787878787879,
  },
  {
    name: "St Clair",
    area: "7.20",
    population: 20231,
    density: 2809.861111111111,
  },
  {
    name: "Putney",
    area: "1.50",
    population: 4210,
    density: 2806.6666666666665,
  },
  {
    name: "Fairfield - East",
    area: "5.70",
    population: 15954,
    density: 2798.9473684210525,
  },
  {
    name: "Peakhurst - Lugarno",
    area: "7.50",
    population: 20711,
    density: 2761.4666666666667,
  },
  {
    name: "Wickham - Carrington - Tighes Hill",
    area: "3.60",
    population: 9906,
    density: 2751.6666666666665,
  },
  {
    name: "Balgowlah - Clontarf - Seaforth",
    area: "7.60",
    population: 20833,
    density: 2741.184210526316,
  },
  {
    name: "Mount Druitt - Whalan",
    area: "8.70",
    population: 23691,
    density: 2723.1034482758623,
  },
  {
    name: "Bass Hill - Georges Hall",
    area: "8.90",
    population: 24193,
    density: 2718.314606741573,
  },
  {
    name: "Sylvania - Taren Point",
    area: "6.10",
    population: 16456,
    density: 2697.704918032787,
  },
  {
    name: "Padstow",
    area: "6.50",
    population: 17363,
    density: 2671.230769230769,
  },
  {
    name: "Lilli Pilli - Port Hacking - Dolans Bay",
    area: "1.30",
    population: 3427,
    density: 2636.153846153846,
  },
  {
    name: "Ermington - Rydalmere",
    area: "9.90",
    population: 25926,
    density: 2618.7878787878785,
  },
  {
    name: "Regents Park",
    area: "2.00",
    population: 5229,
    density: 2614.5,
  },
  {
    name: "Winston Hills",
    area: "4.30",
    population: 11181,
    density: 2600.232558139535,
  },
  {
    name: "Hornsby",
    area: "8.90",
    population: 23130,
    density: 2598.876404494382,
  },
  {
    name: "North Ryde - East Ryde",
    area: "6.20",
    population: 15970,
    density: 2575.806451612903,
  },
  {
    name: "North",
    area: "12.50",
    population: 32181,
    density: 2574.48,
  },
  {
    name: "Edmondson Park",
    area: "6.10",
    population: 15676,
    density: 2569.8360655737706,
  },
  {
    name: "Waratah - North Lambton",
    area: "5.10",
    population: 13034,
    density: 2555.686274509804,
  },
  {
    name: "North Kellyville",
    area: "8.00",
    population: 20256,
    density: 2532,
  },
  {
    name: "Oyster Bay - Como - Jannali",
    area: "8.90",
    population: 22531,
    density: 2531.573033707865,
  },
  {
    name: "Blacktown",
    area: "15.40",
    population: 38894,
    density: 2525.5844155844156,
  },
  {
    name: "Hinchinbrook",
    area: "5.20",
    population: 13022,
    density: 2504.230769230769,
  },
  {
    name: "Baulkham",
    area: "19.20",
    population: 47784,
    density: 2488.75,
  },
  {
    name: "Cambridge Park",
    area: "6.60",
    population: 16308,
    density: 2470.909090909091,
  },
  {
    name: "Macquarie Park - Marsfield",
    area: "10.70",
    population: 26428,
    density: 2469.906542056075,
  },
  {
    name: "Kingsgrove - North",
    area: "2.60",
    population: 6365,
    density: 2448.076923076923,
  },
  {
    name: "Greenfield Park - Prairiewood",
    area: "3.60",
    population: 8789,
    density: 2441.3888888888887,
  },
  {
    name: "Hunters Hill - Woolwich",
    area: "4.20",
    population: 10174,
    density: 2422.3809523809523,
  },
  {
    name: "Doonside - Woodcroft",
    area: "9.90",
    population: 23743,
    density: 2398.282828282828,
  },
  {
    name: "Newport - Bilgola",
    area: "5.80",
    population: 13703,
    density: 2362.5862068965516,
  },
  {
    name: "Casula",
    area: "7.10",
    population: 16722,
    density: 2355.211267605634,
  },
  {
    name: "Rose Bay - Vaucluse - Watsons Bay",
    area: "5.10",
    population: 11843,
    density: 2322.1568627450984,
  },
  {
    name: "Silverwater - Newington",
    area: "3.90",
    population: 9010,
    density: 2310.25641025641,
  },
  {
    name: "Glenmore Park - Regentville",
    area: "9.20",
    population: 21082,
    density: 2291.521739130435,
  },
  {
    name: "Cherrybrook",
    area: "8.30",
    population: 18952,
    density: 2283.3734939759033,
  },
  {
    name: "Mona Vale - Warriewood (North)",
    area: "6.50",
    population: 14818,
    density: 2279.6923076923076,
  },
  {
    name: "Sydenham - Tempe - St Peters",
    area: "3.90",
    population: 8675,
    density: 2224.3589743589746,
  },
  {
    name: "Castle",
    area: "18.80",
    population: 41397,
    density: 2201.968085106383,
  },
  {
    name: "Lalor Park - Kings Langley",
    area: "11.80",
    population: 25777,
    density: 2184.4915254237285,
  },
  {
    name: "Warilla",
    area: "9.50",
    population: 20425,
    density: 2150,
  },
  {
    name: "Adamstown - Kotara",
    area: "8.00",
    population: 17083,
    density: 2135.375,
  },
  {
    name: "North Narrabeen - Warriewood (South)",
    area: "5.20",
    population: 11098,
    density: 2134.230769230769,
  },
  {
    name: "Lindfield - Roseville",
    area: "11.90",
    population: 25283,
    density: 2124.621848739496,
  },
  {
    name: "Rouse Hill - Beaumont Hills",
    area: "8.60",
    population: 18144,
    density: 2109.767441860465,
  },
  {
    name: "Mayfield - Warabrook",
    area: "7.50",
    population: 15810,
    density: 2108,
  },
  {
    name: "Bateau Bay - Killarney Vale",
    area: "10.90",
    population: 22931,
    density: 2103.761467889908,
  },
  {
    name: "Gledswood Hills - Gregory Hills",
    area: "8.70",
    population: 18014,
    density: 2070.574712643678,
  },
  {
    name: "Schofields",
    area: "22.20",
    population: 45884,
    density: 2066.846846846847,
  },
  {
    name: "Bossley Park - Abbotsbury",
    area: "9.50",
    population: 19614,
    density: 2064.6315789473683,
  },
  {
    name: "Merewether - The Junction",
    area: "7.20",
    population: 14740,
    density: 2047.2222222222222,
  },
  {
    name: "Gorokan - Kanwal - Charmhaven",
    area: "11.20",
    population: 22633,
    density: 2020.8035714285716,
  },
  {
    name: "Seven Hills - Prospect",
    area: "7.60",
    population: 15172,
    density: 1996.3157894736844,
  },
  {
    name: "Gordon - Killara",
    area: "11.70",
    population: 23277,
    density: 1989.4871794871797,
  },
  {
    name: "Rosehill - Harris Park",
    area: "4.90",
    population: 9721,
    density: 1983.877551020408,
  },
  {
    name: "Lethbridge Park - Tregear",
    area: "11.30",
    population: 22239,
    density: 1968.0530973451325,
  },
  {
    name: "Normanhurst - Thornleigh - Westleigh",
    area: "10.00",
    population: 19585,
    density: 1958.5,
  },
  {
    name: "Engadine",
    area: "9.00",
    population: 17516,
    density: 1946.2222222222222,
  },
  {
    name: "Jamisontown - South Penrith",
    area: "9.00",
    population: 17500,
    density: 1944.4444444444443,
  },
  {
    name: "Loftus - Yarrawarrah",
    area: "3.80",
    population: 7379,
    density: 1941.842105263158,
  },
  {
    name: "Hamilton - Broadmeadow",
    area: "6.70",
    population: 12981,
    density: 1937.4626865671642,
  },
  {
    name: "Panania",
    area: "14.40",
    population: 27770,
    density: 1928.4722222222222,
  },
  {
    name: "West Hoxton - Middleton Grange",
    area: "9.30",
    population: 17636,
    density: 1896.3440860215053,
  },
  {
    name: "Manly Vale - Allambie Heights",
    area: "9.40",
    population: 17668,
    density: 1879.5744680851062,
  },
  {
    name: "Kingswood - Werrington",
    area: "14.00",
    population: 26075,
    density: 1862.5,
  },
  {
    name: "Penrith",
    area: "10.40",
    population: 19343,
    density: 1859.9038461538462,
  },
  {
    name: "Cecil Hills",
    area: "3.70",
    population: 6852,
    density: 1851.8918918918919,
  },
  {
    name: "Harrington Park",
    area: "6.20",
    population: 11375,
    density: 1834.6774193548385,
  },
  {
    name: "Spring Farm",
    area: "5.90",
    population: 10737,
    density: 1819.8305084745762,
  },
  {
    name: "Balgownie - Fairy Meadow",
    area: "12.30",
    population: 21930,
    density: 1782.9268292682925,
  },
  {
    name: "Banora Point",
    area: "9.40",
    population: 16517,
    density: 1757.127659574468,
  },
  {
    name: "Castle Cove - Northbridge",
    area: "7.70",
    population: 13516,
    density: 1755.3246753246754,
  },
  {
    name: "Minto - St Andrews",
    area: "12.40",
    population: 21754,
    density: 1754.3548387096773,
  },
  {
    name: "Campbelltown - Woodbine",
    area: "13.70",
    population: 23751,
    density: 1733.6496350364964,
  },
  {
    name: "Pymble",
    area: "10.30",
    population: 17668,
    density: 1715.3398058252426,
  },
  {
    name: "Oran Park",
    area: "13.20",
    population: 22542,
    density: 1707.7272727272727,
  },
  {
    name: "Prestons",
    area: "9.20",
    population: 15684,
    density: 1704.7826086956522,
  },
  {
    name: "Lambton - New Lambton",
    area: "10.50",
    population: 17541,
    density: 1670.5714285714287,
  },
  {
    name: "Glendenning - Dean Park",
    area: "5.10",
    population: 8506,
    density: 1667.843137254902,
  },
  {
    name: "Pennant Hills - Cheltenham",
    area: "13.30",
    population: 22047,
    density: 1657.6691729323306,
  },
  {
    name: "Macquarie Fields",
    area: "9.00",
    population: 14440,
    density: 1604.4444444444443,
  },
  {
    name: "West Pennant Hills",
    area: "7.80",
    population: 12500,
    density: 1602.5641025641025,
  },
  {
    name: "Rooty Hill - Minchinbury",
    area: "14.60",
    population: 23133,
    density: 1584.4520547945206,
  },
  {
    name: "Glenfield",
    area: "7.00",
    population: 10739,
    density: 1534.142857142857,
  },
  {
    name: "Mount Hutton - Windale",
    area: "6.30",
    population: 9618,
    density: 1526.6666666666667,
  },
  {
    name: "Avalon - Palm Beach",
    area: "8.60",
    population: 13092,
    density: 1522.325581395349,
  },
  {
    name: "Saratoga - Davistown",
    area: "4.80",
    population: 7282,
    density: 1517.0833333333335,
  },
  {
    name: "Forestville - Killarney Heights",
    area: "8.90",
    population: 13377,
    density: 1503.0337078651685,
  },
  {
    name: "Pemulwuy - Greystanes (North)",
    area: "7.40",
    population: 11006,
    density: 1487.2972972972973,
  },
  {
    name: "Chipping Norton - Moorebank",
    area: "14.50",
    population: 21371,
    density: 1473.8620689655172,
  },
  {
    name: "Corrimal - Tarrawanna - Bellambi",
    area: "11.10",
    population: 16340,
    density: 1472.0720720720722,
  },
  {
    name: "Mount Annan",
    area: "13.20",
    population: 19387,
    density: 1468.7121212121212,
  },
  {
    name: "Strathfield South",
    area: "2.50",
    population: 3660,
    density: 1464,
  },
  {
    name: "Terrigal - North Avoca",
    area: "10.50",
    population: 15231,
    density: 1450.5714285714287,
  },
  {
    name: "Illawong - Alfords Point",
    area: "7.30",
    population: 10554,
    density: 1445.7534246575342,
  },
  {
    name: "St Marys - North St Marys",
    area: "12.90",
    population: 17945,
    density: 1391.0852713178294,
  },
  {
    name: "Port Kembla - Warrawong",
    area: "7.00",
    population: 9734,
    density: 1390.5714285714287,
  },
  {
    name: "Wahroonga (East) - Warrawee",
    area: "13.30",
    population: 18204,
    density: 1368.7218045112782,
  },
  {
    name: "Wyoming",
    area: "8.40",
    population: 11302,
    density: 1345.4761904761904,
  },
  {
    name: "Warwick Farm",
    area: "4.70",
    population: 6237,
    density: 1327.0212765957447,
  },
  {
    name: "Condell Park",
    area: "10.00",
    population: 13190,
    density: 1319,
  },
  {
    name: "Woonona - Bulli - Russell Vale",
    area: "15.80",
    population: 20698,
    density: 1310,
  },
  {
    name: "Gosford - Springfield",
    area: "16.90",
    population: 22088,
    density: 1306.98224852071,
  },
  {
    name: "Malabar - La Perouse",
    area: "8.40",
    population: 10802,
    density: 1285.952380952381,
  },
  {
    name: "St Ives",
    area: "17.80",
    population: 22408,
    density: 1258.8764044943819,
  },
  {
    name: "Valentine - Eleebana",
    area: "10.00",
    population: 12523,
    density: 1252.3,
  },
  {
    name: "Figtree - Keiraville",
    area: "18.80",
    population: 23396,
    density: 1244.468085106383,
  },
  {
    name: "Denham Court - Bardia",
    area: "15.60",
    population: 19118,
    density: 1225.5128205128206,
  },
  {
    name: "Ingleburn",
    area: "14.10",
    population: 17088,
    density: 1211.9148936170213,
  },
  {
    name: "Karabar",
    area: "7.00",
    population: 8483,
    density: 1211.857142857143,
  },
  {
    name: "Warners Bay - Boolaroo",
    area: "12.50",
    population: 15138,
    density: 1211.04,
  },
  {
    name: "Avoca Beach - Copacabana",
    area: "6.40",
    population: 7494,
    density: 1170.9375,
  },
  {
    name: "Charlestown - Dudley",
    area: "22.30",
    population: 25756,
    density: 1154.9775784753363,
  },
  {
    name: "Glendale - Cardiff - Hillsborough",
    area: "21.90",
    population: 25197,
    density: 1150.5479452054794,
  },
  {
    name: "The Entrance",
    area: "13.90",
    population: 15970,
    density: 1148.9208633093524,
  },
  {
    name: "Woronora Heights",
    area: "3.10",
    population: 3556,
    density: 1147.0967741935483,
  },
  {
    name: "Berkeley - Lake Heights - Cringila",
    area: "12.50",
    population: 14173,
    density: 1133.84,
  },
  {
    name: "Edgeworth - Cameron Park",
    area: "15.60",
    population: 17433,
    density: 1117.5,
  },
  {
    name: "Cromer",
    area: "7.50",
    population: 8231,
    density: 1097.4666666666667,
  },
  {
    name: "Budgewoi - Buff Point - Halekulani",
    area: "9.30",
    population: 9912,
    density: 1065.8064516129032,
  },
  {
    name: "Googong",
    area: "6.90",
    population: 7352,
    density: 1065.5072463768115,
  },
  {
    name: "Shortland - Jesmond",
    area: "12.70",
    population: 13524,
    density: 1064.8818897637796,
  },
  {
    name: "Frenchs Forest - Oxford Falls",
    area: "16.70",
    population: 17401,
    density: 1041.9760479041918,
  },
  {
    name: "Redhead",
    area: "3.70",
    population: 3769,
    density: 1018.6486486486486,
  },
  {
    name: "Emu Plains - Leonay",
    area: "13.80",
    population: 13835,
    density: 1002.5362318840579,
  },
  {
    name: "Tweed Heads South",
    area: "8.50",
    population: 8490,
    density: 998.8235294117648,
  },
  {
    name: "Maryland - Fletcher - Minmi",
    area: "17.30",
    population: 17263,
    density: 997.8612716763006,
  },
  {
    name: "Cronulla - Kurnell - Bundeena",
    area: "25.10",
    population: 25025,
    density: 997.011952191235,
  },
  {
    name: "Wallsend - Elermore Vale",
    area: "21.50",
    population: 21395,
    density: 995.1162790697674,
  },
  {
    name: "Turramurra",
    area: "20.60",
    population: 20470,
    density: 993.6893203883494,
  },
  {
    name: "Umina - Booker Bay - Patonga",
    area: "25.20",
    population: 24919,
    density: 988.8492063492064,
  },
  {
    name: "Bonnells Bay - Silverwater",
    area: "10.20",
    population: 10028,
    density: 983.1372549019609,
  },
  {
    name: "Currans Hill",
    area: "7.10",
    population: 6964,
    density: 980.8450704225353,
  },
  {
    name: "Point Clare - Koolewong",
    area: "6.80",
    population: 6632,
    density: 975.2941176470589,
  },
  {
    name: "Marsden Park - Shanes Park",
    area: "25.00",
    population: 23778,
    density: 951.12,
  },
  {
    name: "Elderslie - Narellan",
    area: "15.30",
    population: 14540,
    density: 950.3267973856209,
  },
  {
    name: "Narara",
    area: "7.70",
    population: 7304,
    density: 948.5714285714286,
  },
  {
    name: "Toukley - Norah Head",
    area: "10.50",
    population: 9865,
    density: 939.5238095238095,
  },
  {
    name: "Queanbeyan",
    area: "31.50",
    population: 29392,
    density: 933.0793650793651,
  },
  {
    name: "Claymore - Eagle Vale - Raby",
    area: "22.90",
    population: 20308,
    density: 886.8122270742358,
  },
  {
    name: "Glenhaven",
    area: "7.20",
    population: 6350,
    density: 881.9444444444445,
  },
  {
    name: "Box Hill - Nelson",
    area: "21.10",
    population: 18386,
    density: 871.3744075829384,
  },
  {
    name: "East Maitland - Metford",
    area: "21.70",
    population: 18254,
    density: 841.1981566820277,
  },
  {
    name: "Woy Woy - Blackwall",
    area: "17.40",
    population: 14438,
    density: 829.7701149425288,
  },
  {
    name: "Belmont",
    area: "29.00",
    population: 23564,
    density: 812.551724137931,
  },
  {
    name: "Wamberal - Forresters Beach",
    area: "13.70",
    population: 10790,
    density: 787.5912408759125,
  },
  {
    name: "Kariong",
    area: "8.30",
    population: 6466,
    density: 779.0361445783132,
  },
  {
    name: "Erskine Park",
    area: "8.40",
    population: 6452,
    density: 768.0952380952381,
  },
  {
    name: "Windang - Primbee",
    area: "5.60",
    population: 4201,
    density: 750.1785714285714,
  },
  {
    name: "Thirroul - Austinmer - Coalcliff",
    area: "16.20",
    population: 12023,
    density: 742.1604938271605,
  },
  {
    name: "Summerland Point - Gwandalan",
    area: "9.20",
    population: 6754,
    density: 734.1304347826087,
  },
  {
    name: "Lavington",
    area: "21.50",
    population: 15632,
    density: 727.0697674418604,
  },
  {
    name: "Wangi Wangi - Rathmines",
    area: "13.20",
    population: 9596,
    density: 726.969696969697,
  },
  {
    name: "Leumeah - Minto Heights",
    area: "23.60",
    population: 17054,
    density: 722.6271186440678,
  },
  {
    name: "Port",
    area: "42.10",
    population: 29256,
    density: 694.916864608076,
  },
  {
    name: "Windsor - Bligh Park",
    area: "22.70",
    population: 15398,
    density: 678.3259911894273,
  },
  {
    name: "Chittaway Bay - Tumbi Umbi",
    area: "24.50",
    population: 16225,
    density: 662.2448979591836,
  },
  {
    name: "Asquith - Mount Colah",
    area: "34.70",
    population: 22837,
    density: 658.1268011527377,
  },
  {
    name: "Jordan Springs - Llandilo",
    area: "22.60",
    population: 14796,
    density: 654.6902654867256,
  },
  {
    name: "Belrose",
    area: "13.40",
    population: 8736,
    density: 651.9402985074627,
  },
  {
    name: "Kincumber - Picketts Valley",
    area: "11.70",
    population: 7598,
    density: 649.4017094017095,
  },
  {
    name: "Blaxland - Warrimoo - Lapstone",
    area: "29.70",
    population: 19196,
    density: 646.3299663299664,
  },
  {
    name: "Menai - Lucas Heights - Woronora",
    area: "33.40",
    population: 20896,
    density: 625.6287425149701,
  },
  {
    name: "Wyong",
    area: "15.00",
    population: 9301,
    density: 620.0666666666667,
  },
  {
    name: "Unanderra - Mount Kembla",
    area: "25.10",
    population: 15282,
    density: 608.8446215139442,
  },
  {
    name: "Tenambit - East Maitland",
    area: "17.30",
    population: 10400,
    density: 601.1560693641618,
  },
  {
    name: "Bradbury - Wedderburn",
    area: "36.70",
    population: 20556,
    density: 560.1089918256131,
  },
  {
    name: "Shellharbour",
    area: "55.70",
    population: 30854,
    density: 553.931777378815,
  },
  {
    name: "Blue Haven - San Remo",
    area: "21.00",
    population: 11560,
    density: 550.4761904761905,
  },
  {
    name: "Goonellabah",
    area: "25.20",
    population: 13862,
    density: 550.0793650793651,
  },
  {
    name: "Riverstone",
    area: "32.20",
    population: 17211,
    density: 534.5031055900621,
  },
  {
    name: "Camden - Ellis Lane",
    area: "26.20",
    population: 13339,
    density: 509.12213740458014,
  },
  {
    name: "Warnervale - Wadalba",
    area: "42.90",
    population: 21707,
    density: 505.990675990676,
  },
  {
    name: "Coffs",
    area: "59.40",
    population: 30026,
    density: 505.4882154882155,
  },
  {
    name: "Niagara Park - Lisarow",
    area: "16.70",
    population: 8254,
    density: 494.251497005988,
  },
  {
    name: "Bolton Point - Teralba",
    area: "22.00",
    population: 10488,
    density: 476.72727272727275,
  },
  {
    name: "Leppington - Catherine Field",
    area: "36.40",
    population: 17042,
    density: 468.1868131868132,
  },
  {
    name: "Nelson Bay Peninsula",
    area: "47.50",
    population: 21983,
    density: 462.8,
  },
  {
    name: "Rosemeadow - Glen Alpine",
    area: "48.10",
    population: 21579,
    density: 448.6278586278586,
  },
  {
    name: "Dapto - Avondale",
    area: "56.60",
    population: 24651,
    density: 435.53003533568904,
  },
  {
    name: "Forster",
    area: "33.90",
    population: 14737,
    density: 434.71976401179944,
  },
  {
    name: "Erina - Green Point",
    area: "33.80",
    population: 14673,
    density: 434.112426035503,
  },
  {
    name: "Tuncurry",
    area: "15.70",
    population: 6337,
    density: 403.63057324840764,
  },
  {
    name: "Swansea - Caves Beach",
    area: "32.40",
    population: 12847,
    density: 396.51234567901236,
  },
  {
    name: "Newcastle",
    area: "34.90",
    population: 13792,
    density: 395.1862464183381,
  },
  {
    name: "Kingscliff - Fingal Head",
    area: "38.40",
    population: 15028,
    density: 391.3541666666667,
  },
  {
    name: "Chullora",
    area: "4.10",
    population: 1491,
    density: 363.6585365853659,
  },
  {
    name: "Cranebrook - Castlereagh",
    area: "48.90",
    population: 17255,
    density: 352.8629856850716,
  },
  {
    name: "Box Head - MacMasters Beach",
    area: "32.10",
    population: 11147,
    density: 347.25856697819313,
  },
  {
    name: "Albion",
    area: "82.30",
    population: 28459,
    density: 345.7958687727825,
  },
  {
    name: "Katoomba - Leura",
    area: "40.90",
    population: 13360,
    density: 326.6503667481663,
  },
  {
    name: "Toronto - Awaba",
    area: "43.70",
    population: 14101,
    density: 322.6773455377574,
  },
  {
    name: "Springwood - Winmalee",
    area: "69.70",
    population: 22066,
    density: 316.5853658536585,
  },
  {
    name: "Lake Munmorah - Mannering Park",
    area: "35.50",
    population: 11082,
    density: 312.16901408450707,
  },
  {
    name: "Stockton - Fullerton Cove",
    area: "30.50",
    population: 9349,
    density: 306.5245901639344,
  },
  {
    name: "Horsley - Kembla Grange",
    area: "44.20",
    population: 13531,
    density: 306.131221719457,
  },
  {
    name: "Wentworth Falls",
    area: "21.00",
    population: 6376,
    density: 303.6190476190476,
  },
  {
    name: "Holsworthy - Wattle Grove",
    area: "73.70",
    population: 21181,
    density: 287.3948439620081,
  },
  {
    name: "Thornton - Millers Forest",
    area: "63.10",
    population: 18056,
    density: 286.148969889065,
  },
  {
    name: "Byron Bay",
    area: "39.80",
    population: 11053,
    density: 277.713567839196,
  },
  {
    name: "Lennox Head - Skennars Head",
    area: "37.80",
    population: 9650,
    density: 255.29100529100532,
  },
  {
    name: "Lawson - Hazelbrook - Linden",
    area: "46.90",
    population: 11512,
    density: 245.45842217484008,
  },
  {
    name: "Kurri Kurri - Abermain",
    area: "90.60",
    population: 22197,
    density: 245.00000000000003,
  },
  {
    name: "Bowral",
    area: "55.60",
    population: 13512,
    density: 243.02158273381295,
  },
  {
    name: "Heathcote - Waterfall",
    area: "27.60",
    population: 6707,
    density: 243.0072463768116,
  },
  {
    name: "Lemon Tree Passage - Tanilba Bay",
    area: "30.00",
    population: 6973,
    density: 232.43333333333334,
  },
  {
    name: "Nowra",
    area: "101.70",
    population: 23354,
    density: 229.63618485742379,
  },
  {
    name: "Richmond - Clarendon",
    area: "74.70",
    population: 15892,
    density: 212.74431057563586,
  },
  {
    name: "Bathurst",
    area: "119.50",
    population: 25269,
    density: 211.4560669456067,
  },
  {
    name: "Tweed Heads",
    area: "100.40",
    population: 21016,
    density: 209.3227091633466,
  },
  {
    name: "St Georges Basin - Erowal Bay",
    area: "76.90",
    population: 15238,
    density: 198.15344603381013,
  },
  {
    name: "Tuggerah - Kangy Angy",
    area: "27.80",
    population: 5283,
    density: 190.0359712230216,
  },
  {
    name: "Batemans",
    area: "94.30",
    population: 17692,
    density: 187.61399787910923,
  },
  {
    name: "Korora - Emerald Beach",
    area: "57.70",
    population: 10635,
    density: 184.315424610052,
  },
  {
    name: "Maitland",
    area: "102.60",
    population: 18436,
    density: 179.6881091617934,
  },
  {
    name: "Bayview - Elanora Heights",
    area: "64.60",
    population: 11295,
    density: 174.8452012383901,
  },
  {
    name: "Brunswick Heads - Ocean Shores",
    area: "54.40",
    population: 9428,
    density: 173.30882352941177,
  },
  {
    name: "Sawtell - Boambee",
    area: "118.20",
    population: 20277,
    density: 171.5482233502538,
  },
  {
    name: "North Nowra - Bomaderry",
    area: "99.70",
    population: 16348,
    density: 163.97191574724172,
  },
  {
    name: "Banksmeadow",
    area: "3.90",
    population: 626,
    density: 160.51282051282053,
  },
  {
    name: "Huskisson - Vincentia",
    area: "31.00",
    population: 4962,
    density: 160.06451612903226,
  },
  {
    name: "Broulee - Tomakin",
    area: "24.20",
    population: 3852,
    density: 159.17355371900828,
  },
  {
    name: "Rutherford",
    area: "175.40",
    population: 27589,
    density: 157.29190421892815,
  },
  {
    name: "Austral - Greendale",
    area: "110.70",
    population: 16734,
    density: 151.16531165311653,
  },
  {
    name: "Mittagong",
    area: "73.80",
    population: 11078,
    density: 150.10840108401084,
  },
  {
    name: "Pitt Town - McGraths Hill",
    area: "80.50",
    population: 11796,
    density: 146.53416149068323,
  },
  {
    name: "Beresfield - Hexham",
    area: "59.30",
    population: 8640,
    density: 145.69983136593592,
  },
  {
    name: "Berowra - Brooklyn - Cowan",
    area: "81.80",
    population: 11769,
    density: 143.8753056234719,
  },
  {
    name: "Raymond Terrace",
    area: "109.50",
    population: 15134,
    density: 138.21004566210047,
  },
  {
    name: "South West Rocks",
    area: "46.00",
    population: 5818,
    density: 126.47826086956522,
  },
  {
    name: "Merimbula - Tura Beach",
    area: "97.30",
    population: 11597,
    density: 119.18807810894143,
  },
  {
    name: "Sussex Inlet - Berrara",
    area: "40.40",
    population: 4758,
    density: 117.77227722772278,
  },
  {
    name: "Terranora - North Tumbulgum",
    area: "29.00",
    population: 3382,
    density: 116.62068965517241,
  },
  {
    name: "Warragamba - Silverdale",
    area: "63.60",
    population: 6936,
    density: 109.0566037735849,
  },
  {
    name: "Picton - Tahmoor - Buxton",
    area: "189.90",
    population: 20448,
    density: 107.67772511848341,
  },
  {
    name: "Broken Hill",
    area: "170.10",
    population: 17624,
    density: 103.60964138741917,
  },
  {
    name: "West Wallsend - Barnsley - Killingworth",
    area: "72.50",
    population: 7383,
    density: 101.8344827586207,
  },
  {
    name: "Anna Bay",
    area: "68.10",
    population: 6691,
    density: 98.25256975036712,
  },
  {
    name: "Kiama",
    area: "243.50",
    population: 22894,
    density: 94.02053388090349,
  },
  {
    name: "Yarramundi - Londonderry",
    area: "81.40",
    population: 7600,
    density: 93.36609336609337,
  },
  {
    name: "Moss Vale - Berrima",
    area: "118.10",
    population: 10852,
    density: 91.88823031329382,
  },
  {
    name: "Bargo",
    area: "67.80",
    population: 6100,
    density: 89.97050147492625,
  },
  {
    name: "Wingham",
    area: "61.50",
    population: 5420,
    density: 88.130081300813,
  },
  {
    name: "Ballina",
    area: "442.70",
    population: 37538,
    density: 84.79331375649424,
  },
  {
    name: "Mulgoa - Luddenham - Orchard Hills",
    area: "158.70",
    population: 12801,
    density: 80.6616257088847,
  },
  {
    name: "Pottsville",
    area: "189.50",
    population: 14461,
    density: 76.311345646438,
  },
  {
    name: "Horsley Park - Kemps Creek",
    area: "56.30",
    population: 4205,
    density: 74.68916518650089,
  },
  {
    name: "Cobbitty - Bringelly",
    area: "73.90",
    population: 5380,
    density: 72.80108254397834,
  },
  {
    name: "Wauchope",
    area: "194.90",
    population: 13299,
    density: 68.23499230374551,
  },
  {
    name: "Kurrajong Heights - Ebenezer",
    area: "331.90",
    population: 22364,
    density: 67.38174148840012,
  },
  {
    name: "Culburra Beach",
    area: "76.40",
    population: 5004,
    density: 65.49738219895288,
  },
  {
    name: "Macksville - Scotts Head",
    area: "83.80",
    population: 5345,
    density: 63.782816229116946,
  },
  {
    name: "Old Bar - Manning Point - Red Head",
    area: "209.60",
    population: 13007,
    density: 62.05629770992367,
  },
  {
    name: "Dural - Kenthurst - Wisemans Ferry",
    area: "377.50",
    population: 22778,
    density: 60.33907284768212,
  },
  {
    name: "Helensburgh",
    area: "154.80",
    population: 9087,
    density: 58.701550387596896,
  },
  {
    name: "Morisset - Cooranbong",
    area: "330.50",
    population: 19393,
    density: 58.677760968229954,
  },
  {
    name: "Woolgoolga - Arrawarra",
    area: "270.20",
    population: 14900,
    density: 55.144337527757216,
  },
  {
    name: "Terrey Hills - Duffys Forest",
    area: "70.50",
    population: 3686,
    density: 52.283687943262414,
  },
  {
    name: "Moruya - Tuross Head",
    area: "165.40",
    population: 8643,
    density: 52.25513905683192,
  },
  {
    name: "Bega - Tathra",
    area: "183.90",
    population: 8926,
    density: 48.53724850462208,
  },
  {
    name: "Urunga",
    area: "108.40",
    population: 4929,
    density: 45.47047970479704,
  },
  {
    name: "Moama",
    area: "167.00",
    population: 7394,
    density: 44.275449101796404,
  },
  {
    name: "Williamtown - Medowie - Karuah",
    area: "365.20",
    population: 16058,
    density: 43.97042716319825,
  },
  {
    name: "Ourimbah - Fountaindale",
    area: "114.10",
    population: 4917,
    density: 43.093777388255916,
  },
  {
    name: "Blackheath - Megalong Valley",
    area: "139.20",
    population: 5786,
    density: 41.566091954022994,
  },
  {
    name: "Douglas Park - Appin",
    area: "289.90",
    population: 11854,
    density: 40.88996205588134,
  },
  {
    name: "Narooma - Bermagui",
    area: "252.70",
    population: 9946,
    density: 39.358923624851606,
  },
  {
    name: "Bangalow",
    area: "186.50",
    population: 7251,
    density: 38.879356568364614,
  },
  {
    name: "Hill Top - Colo Vale",
    area: "174.40",
    population: 6540,
    density: 37.5,
  },
  {
    name: "Branxton - Greta - Pokolbin",
    area: "392.30",
    population: 14366,
    density: 36.619933724190666,
  },
  {
    name: "Laurieton - Bonny Hills",
    area: "524.90",
    population: 19000,
    density: 36.197370927795774,
  },
  {
    name: "Eden",
    area: "94.80",
    population: 3424,
    density: 36.118143459915615,
  },
  {
    name: "Mullumbimby",
    area: "268.10",
    population: 9199,
    density: 34.3118239462887,
  },
  {
    name: "Ulladulla",
    area: "724.40",
    population: 23201,
    density: 32.027885146327996,
  },
  {
    name: "The Oaks - Oakdale",
    area: "322.70",
    population: 10220,
    density: 31.670281995661608,
  },
  {
    name: "Galston - Laughtondale",
    area: "191.70",
    population: 5450,
    density: 28.42983828899322,
  },
  {
    name: "Tea Gardens - Hawks Nest",
    area: "204.30",
    population: 5790,
    density: 28.34067547723935,
  },
  {
    name: "Lord Howe Island",
    area: "16.30",
    population: 452,
    density: 27.73006134969325,
  },
  {
    name: "Leeton",
    area: "373.90",
    population: 10320,
    density: 27.60096282428457,
  },
  {
    name: "Maclean - Yamba - Iluka",
    area: "711.00",
    population: 17859,
    density: 25.11814345991561,
  },
  {
    name: "Seaham - Woodville",
    area: "247.50",
    population: 5800,
    density: 23.434343434343436,
  },
  {
    name: "Lismore",
    area: "1296.50",
    population: 30313,
    density: 23.38064018511377,
  },
  {
    name: "Callala Bay - Currarong",
    area: "166.90",
    population: 3594,
    density: 21.533852606351108,
  },
  {
    name: "Murwillumbah",
    area: "932.50",
    population: 20073,
    density: 21.526005361930295,
  },
  {
    name: "Wentworth - Buronga",
    area: "322.50",
    population: 6697,
    density: 20.765891472868216,
  },
  {
    name: "Cessnock",
    area: "1639.40",
    population: 33555,
    density: 20.467854092960838,
  },
  {
    name: "Berry - Kangaroo Valley",
    area: "537.10",
    population: 9380,
    density: 17.46415937441817,
  },
  {
    name: "Orange",
    area: "4624.80",
    population: 54608,
    density: 11.807645736031828,
  },
  {
    name: "Nambucca",
    area: "1387.20",
    population: 15429,
    density: 11.122404844290656,
  },
  {
    name: "Dubbo",
    area: "4311.10",
    population: 47874,
    density: 11.104822435109368,
  },
  {
    name: "Coramba - Nana Glen - Bucca",
    area: "363.90",
    population: 4014,
    density: 11.03050288540808,
  },
  {
    name: "Evans Head",
    area: "543.50",
    population: 5809,
    density: 10.688132474701012,
  },
  {
    name: "Jilliby - Yarramalong",
    area: "346.80",
    population: 3499,
    density: 10.089388696655133,
  },
  {
    name: "Taree",
    area: "3470.00",
    population: 34646,
    density: 9.984438040345822,
  },
  {
    name: "Tomerong - Wandandian - Woollamia",
    area: "376.00",
    population: 3744,
    density: 9.957446808510639,
  },
  {
    name: "Port",
    area: "2925.90",
    population: 28014,
    density: 9.574489900543423,
  },
  {
    name: "Albury",
    area: "5575.60",
    population: 53114,
    density: 9.526149652055384,
  },
  {
    name: "Forster-Tuncurry Surrounds",
    area: "749.50",
    population: 7127,
    density: 9.509006004002668,
  },
  {
    name: "Wagga",
    area: "9526.70",
    population: 76151,
    density: 7.9934289943002295,
  },
  {
    name: "Lithgow",
    area: "2634.10",
    population: 20735,
    density: 7.871758855016894,
  },
  {
    name: "Robertson - Fitzroy Falls",
    area: "498.50",
    population: 3867,
    density: 7.757271815446339,
  },
  {
    name: "Tamworth",
    area: "9221.20",
    population: 65060,
    density: 7.05548084847959,
  },
  {
    name: "Kempsey",
    area: "3822.50",
    population: 25694,
    density: 6.7217789404839765,
  },
  {
    name: "Yennora Industrial",
    area: "2.10",
    population: 13,
    density: 6.19047619047619,
  },
  {
    name: "Bellingen",
    area: "1001.00",
    population: 6160,
    density: 6.153846153846154,
  },
  {
    name: "Calga - Kulnura",
    area: "768.00",
    population: 4677,
    density: 6.08984375,
  },
  {
    name: "Queanbeyan Surrounds",
    area: "3047.70",
    population: 17274,
    density: 5.6678806969189885,
  },
  {
    name: "Singleton",
    area: "4194.40",
    population: 22952,
    density: 5.472057982071334,
  },
  {
    name: "Southern Highlands",
    area: "1409.70",
    population: 7274,
    density: 5.159963112719018,
  },
  {
    name: "Bathurst",
    area: "3892.30",
    population: 20076,
    density: 5.157875806078668,
  },
  {
    name: "Muswellbrook",
    area: "3404.90",
    population: 16778,
    density: 4.927604334929073,
  },
  {
    name: "Casino",
    area: "4089.10",
    population: 19725,
    density: 4.823799858159497,
  },
  {
    name: "Blayney",
    area: "1642.70",
    population: 7662,
    density: 4.664272234735496,
  },
  {
    name: "Cootamundra",
    area: "1656.10",
    population: 7717,
    density: 4.659742769156452,
  },
  {
    name: "Cowra",
    area: "3279.40",
    population: 14887,
    density: 4.539549917667866,
  },
  {
    name: "Wetherill Park Industrial",
    area: "8.60",
    population: 39,
    density: 4.534883720930233,
  },
  {
    name: "Dungog",
    area: "2250.00",
    population: 9767,
    density: 4.340888888888889,
  },
  {
    name: "Grafton",
    area: "8552.20",
    population: 37022,
    density: 4.328944599050536,
  },
  {
    name: "Goulburn",
    area: "9099.90",
    population: 39138,
    density: 4.300926383806416,
  },
  {
    name: "Kyogle",
    area: "1979.80",
    population: 7657,
    density: 3.867562380038388,
  },
  {
    name: "Armidale",
    area: "9653.10",
    population: 34912,
    density: 3.616662004951777,
  },
  {
    name: "Yass",
    area: "5780.30",
    population: 19774,
    density: 3.4209297095306472,
  },
  {
    name: "Junee",
    area: "1970.90",
    population: 6435,
    density: 3.2650058348977624,
  },
  {
    name: "Port Botany Industrial",
    area: "2.80",
    population: 9,
    density: 3.2142857142857144,
  },
  {
    name: "Smithfield Industrial",
    area: "4.30",
    population: 13,
    density: 3.0232558139534884,
  },
  {
    name: "Young",
    area: "6355.60",
    population: 18675,
    density: 2.9383535779470074,
  },
  {
    name: "Corowa",
    area: "4572.30",
    population: 12937,
    density: 2.829429390022527,
  },
  {
    name: "Sydney Airport",
    area: "8.90",
    population: 23,
    density: 2.5842696629213484,
  },
  {
    name: "Wellington",
    area: "3603.40",
    population: 9068,
    density: 2.516512182938336,
  },
  {
    name: "Parkes",
    area: "5653.00",
    population: 14098,
    density: 2.4938970458163805,
  },
  {
    name: "Mudgee",
    area: "10961.90",
    population: 27224,
    density: 2.4835110701611947,
  },
  {
    name: "Eurobodalla Hinterland",
    area: "1460.00",
    population: 3611,
    density: 2.473287671232877,
  },
  {
    name: "Gunnedah",
    area: "5856.10",
    population: 14451,
    density: 2.4676832704359555,
  },
  {
    name: "Tumut",
    area: "4654.00",
    population: 11421,
    density: 2.454018048990116,
  },
  {
    name: "Bulahdelah - Stroud",
    area: "2334.00",
    population: 5361,
    density: 2.296915167095116,
  },
  {
    name: "Forbes",
    area: "4598.00",
    population: 10023,
    density: 2.179860809047412,
  },
  {
    name: "Jindabyne - Berridale",
    area: "3939.80",
    population: 8381,
    density: 2.1272653434184474,
  },
  {
    name: "Bega-Eden Hinterland",
    area: "4709.80",
    population: 9711,
    density: 2.061870992398828,
  },
  {
    name: "Tocumwal - Finley - Jerilderie",
    area: "5011.20",
    population: 9838,
    density: 1.9632024265644956,
  },
  {
    name: "Temora",
    area: "3434.30",
    population: 6588,
    density: 1.9182948490230904,
  },
  {
    name: "Gloucester",
    area: "2933.90",
    population: 5346,
    density: 1.822147994137496,
  },
  {
    name: "Scone",
    area: "8067.60",
    population: 14336,
    density: 1.7769844811344142,
  },
  {
    name: "Rookwood Cemetery",
    area: "3.00",
    population: 5,
    density: 1.6666666666666667,
  },
  {
    name: "Glen Innes",
    area: "5480.00",
    population: 8989,
    density: 1.6403284671532847,
  },
  {
    name: "Oberon",
    area: "2972.40",
    population: 4848,
    density: 1.6310052482842148,
  },
  {
    name: "Cooma",
    area: "6354.60",
    population: 10319,
    density: 1.6238630283574103,
  },
  {
    name: "Dorrigo",
    area: "1987.70",
    population: 3221,
    density: 1.6204658650701815,
  },
  {
    name: "Gundagai",
    area: "2362.50",
    population: 3735,
    density: 1.5809523809523809,
  },
  {
    name: "Quirindi",
    area: "5077.80",
    population: 7667,
    density: 1.5099058647445744,
  },
  {
    name: "Griffith",
    area: "23076.50",
    population: 33447,
    density: 1.4493965722704916,
  },
  {
    name: "Inverell",
    area: "17762.20",
    population: 23262,
    density: 1.3096350677280968,
  },
  {
    name: "Badgerys Creek",
    area: "17.60",
    population: 23,
    density: 1.3068181818181817,
  },
  {
    name: "Bilpin - Colo - St Albans",
    area: "2160.20",
    population: 2815,
    density: 1.3031200814739377,
  },
  {
    name: "Braidwood",
    area: "3418.40",
    population: 4396,
    density: 1.2859817458460099,
  },
  {
    name: "Narromine",
    area: "5956.30",
    population: 6550,
    density: 1.0996759733391535,
  },
  {
    name: "Grenfell",
    area: "3294.10",
    population: 3597,
    density: 1.0919522783157767,
  },
  {
    name: "Narrandera",
    area: "5409.30",
    population: 5891,
    density: 1.0890503392305844,
  },
  {
    name: "Narrabri",
    area: "12146.00",
    population: 11583,
    density: 0.9536472912893134,
  },
  {
    name: "Tenterfield",
    area: "7324.30",
    population: 6973,
    density: 0.9520363720765124,
  },
  {
    name: "Gilgandra",
    area: "5393.40",
    population: 4423,
    density: 0.8200763896614381,
  },
  {
    name: "Tumbarumba",
    area: "4520.70",
    population: 3526,
    density: 0.7799677041166191,
  },
  {
    name: "Coonabarabran",
    area: "10474.10",
    population: 7948,
    density: 0.7588241471820968,
  },
  {
    name: "Deniliquin",
    area: "18481.10",
    population: 13905,
    density: 0.7523902797993627,
  },
  {
    name: "Moree",
    area: "19876.70",
    population: 13323,
    density: 0.6702822903198217,
  },
  {
    name: "West Wyalong",
    area: "8317.50",
    population: 5434,
    density: 0.6533213104899309,
  },
  {
    name: "Bombala",
    area: "3990.10",
    population: 2468,
    density: 0.6185308638881232,
  },
  {
    name: "Port Kembla Industrial",
    area: "12.90",
    population: 7,
    density: 0.5426356589147286,
  },
  {
    name: "Prospect Reservoir",
    area: "29.90",
    population: 16,
    density: 0.5351170568561873,
  },
  {
    name: "Walcha",
    area: "6742.50",
    population: 3111,
    density: 0.4614015572858732,
  },
  {
    name: "Condobolin",
    area: "15858.90",
    population: 6392,
    density: 0.4030544363102107,
  },
  {
    name: "Coonamble",
    area: "12142.40",
    population: 4058,
    density: 0.33420081697193305,
  },
  {
    name: "Royal National Park",
    area: "139.30",
    population: 45,
    density: 0.32304379038047376,
  },
  {
    name: "Walgett - Lightning Ridge",
    area: "27011.10",
    population: 5809,
    density: 0.2150597347016597,
  },
  {
    name: "Nyngan - Warren",
    area: "21216.00",
    population: 4561,
    density: 0.2149792609351433,
  },
  {
    name: "Hay",
    area: "13922.90",
    population: 2848,
    density: 0.20455508550661142,
  },
  {
    name: "Cobar",
    area: "45551.40",
    population: 4156,
    density: 0.09123759094122244,
  },
  {
    name: "Wentworth-Balranald Region",
    area: "49722.50",
    population: 3650,
    density: 0.07340741113178138,
  },
  {
    name: "Bourke - Brewarrina",
    area: "56841.50",
    population: 3514,
    density: 0.061821028649842104,
  },
  {
    name: "Ettrema - Sassafras - Budawang",
    area: "2144.50",
    population: 62,
    density: 0.028911168104453253,
  },
  {
    name: "Holsworthy Military Area",
    area: "129.80",
    population: 3,
    density: 0.023112480739599383,
  },
  {
    name: "Far West",
    area: "146684.70",
    population: 2314,
    density: 0.015775333078364682,
  },
  {
    name: "Blue Mountains - North",
    area: "595.00",
    population: 9,
    density: 0.015126050420168067,
  },
  {
    name: "Deua - Wadbilliga",
    area: "2782.00",
    population: 41,
    density: 0.014737598849748382,
  },
  {
    name: "Illawarra Catchment Reserve",
    area: "744.30",
    population: 5,
    density: 0.006717721348918447,
  },
  {
    name: "Blue Mountains - South",
    area: "2456.90",
    population: 7,
    density: 0.002849118808254304,
  },
  {
    name: "Wollangambe - Wollemi",
    area: "2448.70",
    population: 0,
    density: 0,
  },
  {
    name: "Centennial Park",
    area: "2.00",
    population: 0,
    density: 0,
  },
]
  .slice(0, 100)
  .map(({ name }, index) => ({ label: name, value: name, index }));

const availableSuburbes = [
  {
    value: "sydney",
    label: "Sydney CBD",
  },
  {
    value: "eastwood",
    label: "Eastwood",
  },
];
export default availableSuburbes;
