import { UserOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Image,
  Layout,
  Space,
  theme,
  Typography,
} from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { AuthContext } from "api/auth";
import { UserContext } from "api/user";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.png";
import ButtonGroup from "./ButtonGroup";

const { Header, Content, Footer } = Layout;

type ContainerLayout = {
  header: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
  filledHeader?: boolean;
  lessPadding?: boolean;
};

export function Container(props: ContainerLayout) {
  const {
    token: { colorBgLayout },
  } = theme.useToken();

  return (
    <Layout style={{ height: "100%" }} id="container">
      {props.header}
      <Content
        style={{
          padding: props.lessPadding ? "0 24px" : "0 48px",
          marginBottom: props.footer ? "1px" : "5px",
          flex: 1,
          overflow: "scroll",
          scrollbarWidth: "none",
        }}
      >
        {props.content}
      </Content>
      <Divider style={{ margin: 0 }} />
      {props.footer ? (
        <Footer
          style={{
            backgroundColor: colorBgLayout,
            zIndex: 1,
            padding: props.lessPadding ? "12px" : "6px 24px",
            textAlign: "center",
          }}
        >
          {props.footer}
        </Footer>
      ) : null}
    </Layout>
  );
}
type ActionComponent = React.JSX.Element;
export function DefaultHeader({
  action,
  headerAlign,
  lessPadding = false,
}: {
  headerAlign?: "center" | "flex-end";
  action?: ActionComponent;
  back?: boolean | (() => void);
  lessPadding?: boolean;
}) {
  const {
    token: { colorBgLayout },
  } = theme.useToken();
  return (
    <Header
      style={{
        display: "flex",
        height: "72px",
        alignItems: headerAlign || "center",
        justifyContent: "space-between",
        padding: lessPadding ? 5 : undefined,
        backgroundColor: colorBgLayout,
      }}
    >
      <Space>
        <Logo />
      </Space>
      {action || <div />}
    </Header>
  );
}

export function DarkHeader({ children }: React.PropsWithChildren) {
  return (
    <Header
      style={{
        display: "flex",
        height: "72px",
        justifyContent: "space-between",
        padding: 5,
      }}
    >
      {children}
    </Header>
  );
}

export function ProfileAction(): ActionComponent {
  const navigate = useNavigate();
  const { profile } = useContext(UserContext);
  return (
    <Badge dot={!profile.completedSetup}>
      <Button
        shape="circle"
        icon={<UserOutlined />}
        onClick={() => navigate("/profile")}
      />
    </Badge>
  );
}

export function PublicAction(): ActionComponent {
  const screens = Grid.useBreakpoint();
  if (screens.md) {
    return (
      <Space>
        <Button type="text">Blog</Button>
        <Button type="text">About us</Button>
        <Button type="primary">Login</Button>
      </Space>
    );
  }
  return (
    <ButtonGroup>
      <Flex vertical align="stretch">
        <Button type="text">Blog</Button>
        <Button type="text">About us</Button>
        <Button type="primary">Login</Button>
      </Flex>
    </ButtonGroup>
  );
}

export function LoginAction(): ActionComponent {
  const navigate = useNavigate();
  return <Button onClick={() => navigate("/login")}>Login</Button>;
}

export function Logo({ size = "middle" }: { size?: SizeType }) {
  const { user } = useContext(AuthContext);
  return (
    <Link
      to={user ? "/home" : "/"}
      style={{
        padding: 0,
        fontFamily: "TTNormsPro",
        fontSize: 20,
        lineHeight: "initial",
        boxShadow: "none",
      }}
    >
      <Space>
        <Image
          src={logo}
          height={40}
          width={40}
          style={{ margin: 0 }}
          preview={false}
        />
        {size === "small" ? null : (
          <Flex align="end">
            <Typography.Title
              style={{ margin: 0, whiteSpace: "nowrap" }}
              level={4}
            >
              Jigsaur{" "}
              <span
                style={{
                  fontWeight: "300",
                }}
              >
                connect
              </span>
            </Typography.Title>
          </Flex>
        )}
      </Space>
    </Link>
  );
}
