import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { analytics } from "./firebaseConfig";

const pages = [
  "signIn",
  "signIn_otp",
  "profileCreate",
  "queueCreate",
  "plan_joinPage",
  "messagePage",
  "home",
  "profileView",
  "verify",
  "quickstart",
] as const;

const events = [
  "signIn_success",
  "quickstart_signup_success",
  "quickstart_form_success",
  "shareMessage",
  "signIn_otpComplete",
  "profileCreate_stageFinish",
  "profileCreate_skip",
  "queueCreate_stageFinish",
  "queueCreate_success",
  "queueCreate_select",
  "plan_joinPage_success",
  "messagePage_sendMessage",
  "plan_leavePlan",
  "exception",
] as const;

type EventTypes =
  | (typeof events)[number]
  | `${(typeof pages)[number]}_enter`
  | `${(typeof pages)[number]}_exit`;

export const log = (
  event: EventTypes,
  params?: {
    [key: string]: any;
  },
) => {
  if (event === "exception") {
    logEvent(analytics, event, params);
  } else {
    logEvent(analytics, event, params);
  }
};

export const useLogPage = (
  event: (typeof pages)[number],
  params?: {
    [key: string]: any;
  },
) => {
  useEffect(() => {
    log(`${event}_enter`, params);
    return log(`${event}_exit`, params);
  }, [event, params]);
};
