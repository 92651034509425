import { Alert, Card, Flex, Grid, Typography } from "antd";
import { onAuthStateChanged } from "firebase/auth";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "./api/firebaseConfig";
import { Status } from "./api/firestore";

import { log, useLogPage } from "api/analytics";
import { quickstartLoad } from "api/quickstart";
import { Container, DefaultHeader, Logo } from "component/Container";
import {
  PhoneLogin,
  PhoneLoginConfirmation,
  PhoneLoginProvider,
} from "component/LoginMethods/PhoneNumberLogin";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const { Title, Text } = Typography;

type LoginPageState = {
  phoneLogin?: boolean;
};

export const LoginStatus = createContext<{
  status: Status<LoginPageState>;
  setStatus: Dispatch<SetStateAction<Status<LoginPageState>>>;
}>({
  status: {
    loading: false,
    error: false,
  },
  setStatus: () => {
    throw new Error("LoginContext not created");
  },
});

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = location.state?.redirect || "/home";
  const quickstart = location.state?.quickstart;

  useEffect(
    () =>
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const qs = quickstartLoad();
          if (!quickstart || !qs.queue) {
            log("signIn_success");
            navigate(redirect);
            return;
          }
          navigate("/home");
        }
      }),
    [navigate, redirect, quickstart],
  );
  const [status, setStatus] = useState<Status<LoginPageState>>({
    loading: false,
    error: false,
  });

  const screens = Grid.useBreakpoint();

  return Container({
    lessPadding: true,
    header: <DefaultHeader />,
    content: (
      <LoginStatus.Provider value={{ status, setStatus }}>
        <PhoneLoginProvider>
          <Flex
            vertical
            gap={10}
            justify="center"
            align="center"
            style={{ height: "100%" }}
          >
            <Alert
              showIcon
              message="This website is built and catered for mobile devices, using it on a desktop might have unintended effect"
              type="warning"
              style={{
                margin: 10,
                display: screens["md"] ? undefined : "none",
              }}
            />
            <Card style={{ maxWidth: "960px" }}>
              <Flex vertical gap={10}>
                {status.phoneLogin ? <PhoneLoginConfirmation /> : <LoginHome />}
              </Flex>
            </Card>
          </Flex>
        </PhoneLoginProvider>
      </LoginStatus.Provider>
    ),
    footer: null,
  });
};

const LoginHome = () => {
  const location = useLocation();
  const quickstart = location.state?.quickstart;
  const { status } = useContext(LoginStatus);
  useLogPage("signIn");
  return (
    <>
      <Flex justify="space-between">
        <Title level={2}>
          {quickstart
            ? "All done! Get notified when we find you a group"
            : "Sign up!"}
        </Title>
        <Logo size="small" />
      </Flex>
      <Flex vertical>
        <Text>
          {quickstart
            ? "We will send you a text message when we successfully match you with a group"
            : "Find out the best way to meet new people!"}
        </Text>
      </Flex>
      <PhoneLogin hideLabel={quickstart} />
      <Text type="danger">{status.error}</Text>
      <Text type="secondary">
        By signing up, you agree to our{" "}
        <Link to="/terms">Terms & Condition</Link> and{" "}
        <Link to="/privacy">Privacy Policy</Link>.
      </Text>
    </>
  );
};

export default Login;
