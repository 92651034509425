import { PropsWithChildren } from "react";
import { FriendsProvider } from "./friends";
import { PlanProvider } from "./plan";
import { QueueProvider } from "./queue";

export function AuthApiProvider({ children }: PropsWithChildren) {
  return (
    <FriendsProvider>
      <QueueProvider>
        <PlanProvider>{children}</PlanProvider>
      </QueueProvider>
    </FriendsProvider>
  );
}
