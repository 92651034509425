import { Flex, Typography } from "antd";
import { usePlan } from "api/plan";
import { Container, DefaultHeader } from "component/Container";
import Loader from "component/Loader";
import { PlanCard } from "component/Plan";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

const PlanPage = ({ children }: React.PropsWithChildren) => {
  const { planId } = useParams();
  const { loading, error, plan } = usePlan(planId!);
  const [hidden, setHidden] = useState(true);
  useEffect(() => {
    if (!loading) {
      setTimeout(() => setHidden(false), 200);
    }
  }, [loading]);
  if (loading) {
    return <Loader />;
  }
  if (error || !plan) {
    return <Navigate to="/404" />;
  }

  return (
    <Container
      header={<DefaultHeader />}
      content={
        <>
          <Typography.Title level={3} style={{ textAlign: "center" }}>
            Group found!
          </Typography.Title>
          <Flex
            className="animate__animated animate__bounceIn"
            style={{ display: hidden ? "none" : undefined }}
          >
            <PlanCard plan={plan} title="" />
          </Flex>
        </>
      }
      footer={null}
    />
  );
};
export default PlanPage;
