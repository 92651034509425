import { blue } from "@ant-design/colors";
import { FacebookFilled, InstagramOutlined } from "@ant-design/icons";
import { TinyColor } from "@ctrl/tinycolor";
import { Button, ConfigProvider, Typography } from "antd";
import { auth } from "api/firebaseConfig";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  FacebookAuthProvider,
  getRedirectResult,
  signInWithCustomToken,
  signInWithRedirect,
  UserCredential,
} from "firebase/auth";
import { useState } from "react";
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const provider = new FacebookAuthProvider();
provider.addScope("email");
provider.addScope("user_birthday");
provider.addScope("user_gender");
// provider.addScope("user_hometown");

// eslint-disable-next-line
const IntagramLogin = () => {
  const [error] = useState<Error | null>(null);
  const continueViaInstagram = () => {
    loginViaInstagram();
  };
  const colors = ["#f9ce34", "#ee2a7b", "#6228d7"];
  const getHoverColors = (colors: string[]) =>
    colors.map((color) => new TinyColor(color).lighten(5).toString());
  const getActiveColors = (colors: string[]) =>
    colors.map((color) => new TinyColor(color).darken(5).toString());
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: `linear-gradient(135deg, ${colors.join(", ")})`,
            colorPrimaryHover: `linear-gradient(135deg, ${getHoverColors(colors).join(", ")})`,
            colorPrimaryActive: `linear-gradient(135deg, ${getActiveColors(colors).join(", ")})`,
          },
        },
      }}
    >
      {error ? (
        <Typography.Text type="danger">
          Error occured, please try again. If this happens again, please contact
          us.
        </Typography.Text>
      ) : null}
      <Button
        style={{ border: "0" }}
        type="primary"
        size="large"
        icon={<InstagramOutlined />}
        onClick={continueViaInstagram}
      >
        Continue with Instagram
      </Button>
    </ConfigProvider>
  );
};

// eslint-disable-next-line
const FacebookLogin = () => {
  const [error, setError] = useState<Error | null>(null);
  const continueViaFacebook = () => {
    loginViaFacebook().catch(setError);
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: blue.primary,
        },
      }}
    >
      {error ? (
        <Typography.Text type="danger">
          Error occured, please try again. If this happens again, please contact
          us.
        </Typography.Text>
      ) : null}
      <Button
        type="primary"
        size="large"
        icon={<FacebookFilled />}
        onClick={continueViaFacebook}
      >
        Continue with Facebook
      </Button>
    </ConfigProvider>
  );
};

export function loginViaInstagram() {
  window.location.replace("https://instagramlogin-korpsa3qja-uc.a.run.app");
}

export function loginViaFacebook() {
  return signInWithRedirect(auth, provider).then((result) => {
    console.log(result);
    const credential = FacebookAuthProvider.credentialFromResult(result);
    console.log(credential);
    const token = credential?.accessToken;
    console.log(token);
  });
  /*
  return signInAnonymously(auth).then(() => {
    window.location.pathname = "";
  });
   */
}

// eslint-disable-next-line
function getInstagramLoginResult(): Promise<UserCredential | void> {
  const urlParams = new URLSearchParams(window.location.search);
  const customToken = urlParams.get("customToken");
  if (customToken) {
    return signInWithCustomToken(auth, customToken);
  }
  return Promise.resolve();
}

export function handleRedirect() {
  return (
    getRedirectResult(auth)
      /*
    .then((result) => {
      const user = result?.user as FirebaseUser;
      const credential =
        result && FacebookAuthProvider.credentialFromResult(result);
      console.log(credential);
      console.log(user);
      if (!user) {
        return;
      }
      const token = credential?.accessToken;
      if (token && user) {
        return axios
          .get("https://graph.facebook.com/v19.0/me", {
            params: {
              fields: fieldNames.join(","),
              access_token: token,
            },
          })
          .then((fbProfile) => {
            const {
              name = "",
              email = "",
              first_name = "",
              last_name = "",
              birthday = "",
              // hometown = "",
              gender = "",
            } = fbProfile.data as {
              [key: FieldNames]: string;
            };
            createProfileIfNew(user, {
              birthday: Timestamp.fromDate(
                dayjs(birthday, "MM/DD/YYYY").toDate(),
              ),
              // hometown,
              gender,
              email,
              preferredName: name,
              firstName: first_name,
              lastName: last_name,
              profilePic: user.photoURL ?? "",
            }).then(() => {
              // window.location.path = "/createProfile";
            });
          });
      }
    })
     */
      .catch((e) => console.error(e))
  );
}
