import { Button, Form, Input, Space } from "antd";
import { phoneNumberRule, required } from "./rules";

export const PhoneNumberInput = ({
  prefix = "+61",
  label = true,
  loading = false,
}: React.PropsWithChildren<{
  prefix?: string;
  label?: boolean;
  loading: boolean;
}>) => (
  <Form.Item label={label && "Phone number login"}>
    <Space.Compact>
      <Form.Item
        noStyle
        name="phoneNumber"
        rules={[required, phoneNumberRule]}
        validateTrigger="onBlur"
      >
        <Input addonBefore={prefix} defaultValue="4" type="tel" />
      </Form.Item>
      <Button htmlType="submit" loading={loading}>
        Submit
      </Button>
    </Space.Compact>
  </Form.Item>
);
