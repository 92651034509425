import { auth, db } from "api/firebaseConfig";
import dayjs, { Dayjs } from "dayjs";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  FieldValue,
  getCountFromServer,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { log } from "./analytics";
import { createFirestoreDateConverter } from "./firestore";
import { getPlanRef, PlanType, updateMessageCount } from "./plan";
import { UserId } from "./user";

export type Message = {
  id: string;
  message: string;
  user: string;
  time: Dayjs;
  roomId: string;
};

const getMessageRef = (roomId: string) =>
  collection(db, "plan", roomId, "message");

export const useMessage = (roomId: string) => {
  const [messages, setMessages] = useState<Message[]>([]);
  useEffect(() => {
    const q = query(getMessageRef(roomId), orderBy("time")).withConverter(
      createFirestoreDateConverter(["time"]),
    );
    return onSnapshot(q, (querySnapshot) => {
      const messages: Message[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.time = data.time || dayjs();
        messages.push({ ...data, id: doc.id } as Message);
      });
      setMessages(messages);
      updateMessageCount(roomId, messages.length);
    });
  }, [roomId]);

  return messages;
};

export const countMessages = (roomId: string) => {
  const messageRef = getMessageRef(roomId);
  return getCountFromServer(messageRef);
};

export const readMessage = (plan: PlanType | undefined, userId: UserId) => {
  if (!plan) {
    return;
  }
  const lastSeen = plan.lastSeen || ({} as { [key: UserId]: number });
  if (!plan.messageCount) {
    return;
  }
  if (lastSeen[userId] === plan.messageCount) {
    return;
  }
  const planRef = getPlanRef(plan.id!);
  return updateDoc(planRef, {
    [`lastSeen.${userId}`]: plan.messageCount || 0,
  });
};

export const sendMessage = (roomId: string, message: string) => {
  log("messagePage_sendMessage");
  const doc: Omit<Message, "time" | "id"> & { time: FieldValue } = {
    message,
    user: auth.currentUser!.uid,
    time: serverTimestamp(),
    roomId,
  };
  return addDoc(getMessageRef(roomId), doc);
};

export const sendSystemMessage = (roomId: string, message: string) => {
  const doc: Omit<Message, "time" | "id"> & { time: FieldValue } = {
    message,
    user: "system",
    time: serverTimestamp(),
    roomId,
  };
  return addDoc(getMessageRef(roomId), doc);
};

export const deleteMessage = async (message: Message) => {
  const messageRef = doc(db, "plan", message.roomId, "message", message.id);
  const d = await getDoc(messageRef);
  setDoc(
    doc(db, "plan", message.roomId, "deletedMessage", message.id),
    d.data(),
  );
  return deleteDoc(messageRef);
};
