import {
  CheckCircleTwoTone,
  DeleteOutlined,
  EditOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, Card, Flex, Space, Typography } from "antd";
import { useLogPage } from "api/analytics";
import { usePageTitle } from "api/utils";
import ButtonGroup from "component/ButtonGroup";
import { CardSelectChoice } from "component/Form/Input";
import Loader from "component/Loader";
import { VerifyButton } from "component/Verify";
import { signOut } from "firebase/auth";
import { useContext } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { auth } from "./api/firebaseConfig";
import { FriendsContext } from "./api/friends";
import { Profile, UserContext } from "./api/user";
import { Container, DefaultHeader } from "./component/Container";
import { goals, interestBundleChoice } from "./component/Form/ProfileInput";

export default function ProfilePage() {
  const { id } = useParams();
  const friends = useContext(FriendsContext);

  const { profile: selfProfile } = useContext(UserContext);
  let profile: Profile;
  let isSelfProfile = false;
  if (!id) {
    isSelfProfile = true;
    profile = selfProfile;
  } else {
    profile = friends[id] as Profile;
    if (id === selfProfile.id) {
      isSelfProfile = true;
    }
  }
  usePageTitle(`Jigsaur - ${profile?.preferredName}`);
  useLogPage("profileView");

  // TODO: Change this to 404
  if (profile === null) {
    return <Navigate to="/home" />;
  }
  if (profile === undefined) {
    return <Loader />;
  }

  return (
    <Container
      lessPadding
      header={
        <DefaultHeader
          action={isSelfProfile ? <ProfileHeaderAction /> : undefined}
        />
      }
      content={
        <Flex vertical align="stretch" style={{ padding: "100px 0" }} gap={20}>
          <Flex vertical align="center" gap={20}>
            <Avatar
              icon={<UserOutlined />}
              size={150}
              src={profile.profilePic}
            />
            <ProfileName level={1} profile={profile} />
            <ThreeButtons profile={profile} />
            {!isSelfProfile ? <VerifyButton profile={profile} /> : null}
          </Flex>
          <Flex vertical align="stretch" gap={20}>
            <AboutMe profile={profile} />
            <Goals profile={profile} />
            <Interests profile={profile} />
            {profile.id === selfProfile.id ? (
              <Button danger icon={<DeleteOutlined />}>
                Delete Account
              </Button>
            ) : null}
          </Flex>
        </Flex>
      }
      footer={null}
    />
  );
}

type ProfileProps = {
  profile: Profile;
};

/* =====================
 * COMPONENTS
 * =====================
 */
const ProfileHeaderAction = () => {
  const navigate = useNavigate();
  const { profile } = useContext(UserContext);
  return (
    <Badge dot={!profile.completedSetup}>
      <ButtonGroup>
        <Space.Compact direction="vertical" style={{ width: "100%" }}>
          <Button
            icon={
              <Badge dot={!profile.completedSetup}>
                <EditOutlined />
              </Badge>
            }
            onClick={() => navigate("/createProfile")}
          >
            {profile.completedSetup ? "Edit Profile" : "Complete Profile"}
          </Button>
          <Button
            type="primary"
            danger
            icon={<LogoutOutlined />}
            onClick={() => signOut(auth)}
          >
            Logout
          </Button>
        </Space.Compact>
      </ButtonGroup>
    </Badge>
  );
};

export const ProfileName = ({
  profile,
  level,
}: ProfileProps & {
  level: 1 | 5 | 2 | 3 | 4 | undefined;
}) => (
  <Space align="center">
    <Typography.Title style={{ margin: 0, textAlign: "center" }} level={level}>
      {profile?.preferredName}
    </Typography.Title>
    {profile?.verified ? <CheckCircleTwoTone /> : null}
  </Space>
);

const ThreeButtons = ({ profile }: ProfileProps) => (
  <></>
  /*
  <ConfigProvider
    theme={{
      components: {
        Button: {
          defaultBorderColor: "blue",
          defaultColor: "blue",
          colorPrimary: "blue",
        },
      },
    }}
  >
    <Button block icon={<CheckCircleTwoTone />}>
      Verify {profile.firstName}
    </Button>
  </ConfigProvider>
  <Space align="center">
    <Button type="primary" size="large">
    <Button type="primary" icon={<FireFilled />} size="large" danger />
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "blue",
          },
        },
      }}
    >
      <Button type="primary" icon={<FireFilled />} size="large" />
    </ConfigProvider>
  </Space>
   */
);

const AboutMe = ({ profile }: ProfileProps) => (
  <Card title="About me">
    <Typography.Paragraph>{profile?.aboutMe}</Typography.Paragraph>
  </Card>
);

const Goals = ({ profile }: ProfileProps) =>
  profile.goal ? (
    <Card title="Goal">
      <Space direction="vertical">
        {Object.entries(profile.goal)
          .filter(([_, selected]) => selected)
          .map(([g, _]) => (
            <Card key={g}>
              <Typography.Text>
                {goals[g as keyof typeof goals]}
              </Typography.Text>
            </Card>
          ))}
      </Space>
    </Card>
  ) : null;

const Interests = ({ profile }: ProfileProps) => {
  if (profile.interests) {
    return (
      <Card title="Interests">
        {profile.interests && (
          <Space direction="vertical">
            {Object.entries(profile.interests)
              .filter(([_, selected]) => selected)
              .map(([g, _]) => (
                <CardSelectChoice
                  key={g}
                  {...interestBundleChoice.find(({ id }) => id === g)!}
                >
                  {profile.interestsQuestions &&
                    Object.entries(profile.interestsQuestions[g])
                      .filter(([_, a]) => a)
                      .map(([q, a]) => (
                        <Space direction="vertical">
                          <Typography.Paragraph strong>
                            {q}
                          </Typography.Paragraph>
                          <Typography.Paragraph>{a}</Typography.Paragraph>
                        </Space>
                      ))}
                </CardSelectChoice>
              ))}
          </Space>
        )}
      </Card>
    );
  }
  return null;
};
