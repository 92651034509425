import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function ordinalSuffixOf(i: number | undefined) {
  if (!i) {
    return "";
  }
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

export function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

export function usePageTitle(newTitle: string) {
  const location = useLocation();
  const [prevTitle] = useState(document.title);
  useEffect(() => {
    document.title = newTitle;
    return () => {
      document.title = prevTitle;
    };
  }, [location, newTitle, prevTitle]);
}

export function dateArrayToString(dates: Dayjs[] | undefined, mini?: boolean) {
  if (!dates) {
    return "";
  }
  if (dates.length <= 0) {
    return "";
  }
  if (dates.length === 1) {
    return dates[0].format("ll");
  }
  if (mini) {
    return `${dates[0].format("MMM D")} +${dates.length - 1} day(s)`;
  }
  return `${dates[0].format("l")} and ${dates.length - 1} other day(s)`;
}

declare const __brand: unique symbol;
type Brand<B> = { [__brand]: B };
export type Branded<T, B> = T & Brand<B>;
