import Loader from "component/Loader";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  AuthError,
  onAuthStateChanged,
  User as FirebaseUser,
} from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { log } from "./analytics";
import { auth } from "./firebaseConfig";
import { Status } from "./firestore";
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export type AuthUser = Status<{ user?: FirebaseUser | null }>;

export const AuthContext = createContext<AuthUser>({
  loading: false,
  error: false,
});

const useAuth = () => {
  const [authUser, setAuthUser] = useState<AuthUser>({
    loading: true,
    user: auth.currentUser,
    error: false,
  });
  useEffect(
    () =>
      onAuthStateChanged(auth, (user) => {
        setAuthUser({ user, loading: false, error: false });
      }),
    [],
  );
  return authUser;
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const authUser = useAuth();
  if (authUser.loading) {
    return <Loader />;
  }
  return (
    <AuthContext.Provider value={authUser}>{children}</AuthContext.Provider>
  );
}

export type AccountDetails = {
  email: string;
  password: string;
};

export function getErrorMessageFromFireauth(e: AuthError) {
  const errorMessages = {
    "auth/claims-too-large":
      "The provided claims are too large. Please try again with smaller claims.",
    "auth/email-already-in-use":
      "The email address is already in use. Please use a different email address.",
    "auth/id-token-expired": "Your session has expired. Please sign in again.",
    "auth/id-token-revoked":
      "Your session has been revoked. Please sign in again.",
    "auth/insufficient-permission":
      "You do not have the necessary permissions to perform this action.",
    "auth/internal-error":
      "An internal error occurred. Please try again later.",
    "auth/invalid-argument":
      "An invalid argument was provided. Please check your input and try again.",
    "auth/invalid-claims":
      "The provided custom claims are invalid. Please try again.",
    "auth/invalid-continue-uri":
      "The provided continue URL is invalid. Please check the URL and try again.",
    "auth/invalid-creation-time":
      "The creation time is invalid. Please try again.",
    "auth/invalid-credential": "Invalid password. Please try again.",
    "auth/invalid-disabled-field":
      "The provided disabled field value is invalid. Please try again.",
    "auth/invalid-display-name":
      "The provided display name is invalid. Please try again.",
    "auth/invalid-dynamic-link-domain":
      "The provided dynamic link domain is not authorized. Please check your configuration.",
    "auth/invalid-email":
      "The email address is not valid. Please check the format and try again.",
    "auth/invalid-email-verified":
      "The email verification status is invalid. Please try again.",
    "auth/invalid-hash-algorithm":
      "The provided hash algorithm is not supported. Please check your configuration.",
    "auth/invalid-hash-block-size":
      "The hash block size is invalid. Please check your configuration.",
    "auth/invalid-hash-derived-key-length":
      "The derived key length for the hash algorithm is invalid. Please check your configuration.",
    "auth/invalid-hash-key":
      "The hash key is invalid. Please check your configuration.",
    "auth/invalid-hash-memory-cost":
      "The hash memory cost is invalid. Please check your configuration.",
    "auth/invalid-hash-parallelization":
      "The hash parallelization value is invalid. Please check your configuration.",
    "auth/invalid-hash-rounds":
      "The hash rounds value is invalid. Please check your configuration.",
    "auth/invalid-hash-salt-separator":
      "The hash salt separator is invalid. Please check your configuration.",
    "auth/invalid-id-token":
      "The provided ID token is invalid. Please sign in again.",
    "auth/invalid-last-sign-in-time":
      "The last sign-in time is invalid. Please try again.",
    "auth/invalid-page-token":
      "The provided next page token is invalid. Please try again.",
    "auth/invalid-password":
      "The provided password is invalid. It must be at least six characters long.",
    "auth/invalid-password-hash":
      "The provided password hash is invalid. Please check your configuration.",
    "auth/invalid-password-salt":
      "The provided password salt is invalid. Please check your configuration.",
    "auth/invalid-phone-number":
      "The provided phone number is invalid. Please enter a valid phone number.",
    "auth/invalid-photo-url":
      "The provided photo URL is invalid. Please enter a valid URL.",
    "auth/invalid-provider-id": "The provider ID is invalid. Please try again.",
    "auth/invalid-provider-data":
      "The provider data is invalid. Please try again.",
    "auth/invalid-recipient-email":
      "The provided recipient email is invalid. Please check the email address and try again.",
    "auth/invalid-sender":
      "The provided sender email or name is invalid. Please check the details and try again.",
    "auth/invalid-session-cookie-duration":
      "The session cookie duration is invalid. Please try again.",
    "auth/invalid-uid":
      "The provided UID is invalid. It must be a non-empty string with at most 128 characters.",
    "auth/invalid-user-import":
      "The user import data is invalid. Please check the data and try again.",
    "auth/maximum-user-count-exceeded":
      "The maximum number of users to import has been exceeded. Please try again with fewer users.",
    "auth/missing-android-pkg-name":
      "An Android Package Name is required. Please provide it and try again.",
    "auth/missing-continue-uri":
      "A valid continue URL is required. Please provide it and try again.",
    "auth/missing-hash-algorithm":
      "A hashing algorithm is required for user import with password hashes. Please provide it and try again.",
    "auth/missing-ios-bundle-id":
      "An iOS Bundle ID is required. Please provide it and try again.",
    "auth/missing-uid":
      "A UID is required for this operation. Please provide it and try again.",
    "auth/missing-oauth-client-secret":
      "The OAuth client secret is required to enable OIDC code flow. Please provide it and try again.",
    "auth/operation-not-allowed":
      "This operation is not allowed. Please contact support for assistance.",
    "auth/phone-number-already-exists":
      "The provided phone number is already in use. Please use a different phone number.",
    "auth/project-not-found":
      "No Firebase project was found for the provided credentials.",
    "auth/reserved-claims":
      "One or more of the provided custom claims are reserved. Please try again with different claims.",
    "auth/session-cookie-expired":
      "Your session cookie has expired. Please sign in again.",
    "auth/session-cookie-revoked":
      "Your session cookie has been revoked. Please sign in again.",
    "auth/uid-already-exists":
      "The provided UID is already in use. Please use a different UID.",
    "auth/unauthorized-continue-uri":
      "The domain of the continue URL is not authorized. Please whitelist the domain in the Firebase console.",
    "auth/user-not-found":
      "No user found with this email address. Please sign up for an account.",
    "auth/user-disabled":
      "Your account has been disabled. Please contact support for assistance.",
    "auth/user-token-expired":
      "Your session has expired. Please sign in again.",
    "auth/web-storage-unsupported":
      "This browser does not support web storage. Please try a different browser.",
    "auth/account-exists-with-different-credential":
      "An account with this email already exists. Please sign in using a different method.",
    "auth/wrong-password": "Incorrect password. Please try again.",
    "auth/invalid-verification-code":
      "The verification code entered is invalid. Please try again.",
    "auth/invalid-verification-id":
      "The verification ID is invalid. Please try again.",
    "auth/too-many-request":
      "You have tried too many times. Please try again later.",
  };
  type ErrorCodeType = keyof typeof errorMessages;
  log("exception", {
    description: e.code,
  });
  return errorMessages[e.code as ErrorCodeType];
}
