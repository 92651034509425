import { Col, Form, Row, Tabs } from "antd";
import { useForm } from "antd/es/form/Form";
import ProfileInput from "component/Form/ProfileInput";
import "dev.css";
import ProfilePage from "ProfilePage";
import { useContext, useState } from "react";
import { Profile, UserContext } from "./api/user";
import { Container } from "./component/Container";

export default function ProfileDev() {
  const items = Object.entries(ProfileInput).map(([key, component]) => ({
    key,
    label: key,
  }));
  const [form] = useForm();

  const [selected, onSelect] = useState<string>(items[0].key);
  const { profile } = useContext(UserContext);
  return Container({
    header: <Tabs items={items} onChange={onSelect} />,
    content: (
      <Form form={form} initialValues={profile}>
        <Row>
          <Col style={{ width: 414 }}>
            {ProfileInput[selected as keyof typeof ProfileInput]}
          </Col>
          <Col style={{ width: 414 }}>
            <Form.Item noStyle shouldUpdate>
              {() => (
                <UserContext.Provider
                  value={{
                    loading: false,
                    error: false,
                    profile: {
                      ...profile,
                      ...form.getFieldsValue(true),
                    } as Profile,
                  }}
                >
                  <div>
                    <ProfilePage />
                  </div>
                </UserContext.Provider>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ),
    footer: null,
  });
}
