import { Button, Card, Checkbox, Form, Space, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { createFeedback } from "api/feedback";
import { FriendsContext } from "api/friends";
import { PlanContext } from "api/plan";
import { QueueContext } from "api/queue";
import { UserContext } from "api/user";
import { Container, DefaultHeader } from "component/Container";
import { TextInput } from "component/Form/Input";
import { serverTimestamp } from "firebase/firestore";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

const FeedbackPage = ({
  children,
  auth = false,
}: React.PropsWithChildren<{ auth?: boolean }>) => {
  const navigate = useNavigate();
  const profile = useContext(UserContext);
  // const authData = useContext(AuthContext);
  const queue = useContext(QueueContext);
  const friends = useContext(FriendsContext);
  const plan = useContext(PlanContext);
  let metadata: any = { auth };
  if (auth) {
    metadata = {
      profile,
      queue,
      friends,
      plan,
    };
  }
  return Container({
    header: <DefaultHeader />,
    content: (
      <Card title="Give feedback">
        <Typography.Paragraph></Typography.Paragraph>
        <Form
          onFinish={(data) => {
            return createFeedback({
              ...data,
              metadata: JSON.stringify(metadata),
              timestamp: serverTimestamp(),
            }).then(() => navigate("/home"));
          }}
        >
          <Form.Item
            name="feedback"
            label={
              auth
                ? "What do you think about Jigsaur and what can we do to improve?"
                : "Hi, how can we help you?"
            }
          >
            <TextArea />
          </Form.Item>
          {auth ? null : <TextInput name="contact" label="Contact" />}
          <Form.Item name="contactCheck" valuePropName="checked">
            <Checkbox>
              I might be contacted about this feedback.{" "}
              <Link to="/privacy">Privacy policy</Link>
            </Checkbox>
          </Form.Item>

          <Space>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
            <Button onClick={() => navigate("/home")}>Cancel</Button>
          </Space>
        </Form>
      </Card>
    ),
    footer: null,
  });
};

export const SuggestEventPage = ({
  children,
  auth = false,
}: React.PropsWithChildren<{ auth?: boolean }>) => {
  const navigate = useNavigate();
  const profile = useContext(UserContext);
  // const authData = useContext(AuthContext);
  const queue = useContext(QueueContext);
  const friends = useContext(FriendsContext);
  const plan = useContext(PlanContext);
  let metadata: any = { auth };
  if (auth) {
    metadata = {
      profile,
      queue,
      friends,
      plan,
    };
  }
  return Container({
    header: <DefaultHeader />,
    content: (
      <Card title="Give feedback">
        <Typography.Paragraph></Typography.Paragraph>
        <Form
          onFinish={(data) => {
            return createFeedback({
              ...data,
              metadata: JSON.stringify(metadata),
              timestamp: serverTimestamp(),
            }).then(() => navigate("/home"));
          }}
        >
          <Form.Item
            name="feedback"
            label={
              "Suggest an activity that excites you! Tell us what you love about it, how many people should participate, and why it's a great way to bond with friends."
            }
          >
            <TextArea />
          </Form.Item>
          <Form.Item name="contactCheck" valuePropName="checked">
            <Checkbox>
              I might be contacted about this feedback.{" "}
              <Link to="/privacy">Privacy policy</Link>
            </Checkbox>
          </Form.Item>

          <Space>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
            <Button onClick={() => navigate("/home")}>Cancel</Button>
          </Space>
        </Form>
      </Card>
    ),
    footer: null,
  });
};

export default FeedbackPage;
