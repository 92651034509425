import { Typography } from "antd";
import { Container, DefaultHeader } from "./component/Container";

const PrivacyPage = () => {
  return Container({
    header: <DefaultHeader />,
    content: <JigsaurPrivacyPage />,
    footer: null,
  });
};
export const PrivacyText = () => (
  <div>
    <Typography.Title>PRIVACY POLICY</Typography.Title>
    <Typography.Paragraph>
      Your privacy is important to us and we are committed to responsibly
      handling your personal information and we only collect and use your
      personal information as outlined below.
    </Typography.Paragraph>
    <Typography.Title>SECTION 1 – GENERAL</Typography.Title>
    <Typography.Paragraph>
      This Privacy Policy sets out how we collect, store, use and disclose your
      personal information. Through your use of any Jigsaur facilities or stores
      or the website services, you are consenting to the collection and use (as
      outlined in this Privacy Policy) of this information.
    </Typography.Paragraph>
    <Typography.Paragraph>
      This Privacy Policy covers all the businesses in the Jigsaur together with
      any of its Associated Entities. Jigsaur Group and the Associated Entities,
      together defined throughout this Agreement as Jigsaur (‘Jigsaur’) and
      referred to throughout the Privacy Policy as “we” “us” or “our”. Where
      applicable, Jigsaur will also be the applicable data controller and will
      be responsible for your personal information.
    </Typography.Paragraph>
    <Typography.Paragraph>
      Jigsaur takes your privacy seriously and is committed to its compliance
      with the Australian Privacy Principles contained in the Privacy Act
      1988 (Cth) (‘Privacy Principles’).
    </Typography.Paragraph>
    <Typography.Title>
      SECTION 2 – WHAT DO WE DO WITH YOUR INFORMATION?
    </Typography.Title>
    <Typography.Paragraph>
      When you purchase something from our store, or online as part of the
      buying and selling process, we collect the personal information you give
      us, such as your name, address and email address.
    </Typography.Paragraph>
    <Typography.Paragraph>
      When you browse our website, we also automatically receive your computer’s
      internet protocol (IP) address in order to provide us with information
      that helps us learn about your browser and operating system.
    </Typography.Paragraph>
    <Typography.Paragraph>
      Email marketing (if applicable): With your permission, we may send you
      emails about our business, new venues, exclusive promotions and
      opportunities.
    </Typography.Paragraph>
    <Typography.Title>SECTION 3 – CONSENT</Typography.Title>
    <Typography.Paragraph>How do you get my consent?</Typography.Paragraph>
    <Typography.Paragraph>
      When you browse our website, provide us with personal information to
      complete a transaction, verify your credit card, place an order, arrange
      for a delivery or return a purchase, we imply that you consent to our
      collecting it and using it for that specific reason only. Your consent to
      this Privacy Policy is the legal basis upon which we can collect, use,
      store, process and disclose your personal information.
    </Typography.Paragraph>
    <Typography.Paragraph>
      If we ask for your personal information for a secondary reason, like
      marketing, we will either ask you directly for your expressed consent, or
      provide you with an opportunity to say no.
    </Typography.Paragraph>
    <Typography.Paragraph>
      If you do not wish to provide personal information to us, then you are not
      obliged to do so. However, please be aware that this will affect your use
      of any and all websites, platforms, applications, products or services
      offered to you.
    </Typography.Paragraph>
    <Typography.Paragraph>
      We reserve the right to review, amend, update or change this Privacy
      Policy from time to time to reflect our practices and obligations under
      the law. Any changes will take effect when they are made or posted on our
      website, platform or application and you are electronically notified of
      the same.
    </Typography.Paragraph>
    <Typography.Paragraph>How do I withdraw my consent?</Typography.Paragraph>
    <Typography.Paragraph>
      If after you opt-in, you change your mind, you may withdraw your consent
      for us to contact you, for the continued collection, use or disclosure of
      your information, at any time, by contacting us at
      goodvibesayhi@gmail.com.
    </Typography.Paragraph>
    <Typography.Paragraph>What information we collect</Typography.Paragraph>
    <Typography.Paragraph>
      Personal information has the meaning given to it under your local data
      protection laws and generally means information which relates to an
      individual who can be identified from that information.
    </Typography.Paragraph>
    <Typography.Paragraph>
      We collect the personal data necessary for us to provide you with the
      services you have requested from us. You do not have to provide us with
      your personal information, but if you do not do so, we may not be able to
      provide you with some or all of our services. By disclosing personal
      information to Jigsaur, you are consenting to its collection. 
    </Typography.Paragraph>
    <Typography.Paragraph>
      Where practicable, we will give you the option of interacting with us
      anonymously or using a pseudonym.
    </Typography.Paragraph>
    <Typography.Paragraph>
      Generally, the kinds of personal information Jigsaur may collect are: 
    </Typography.Paragraph>
    <Typography.Paragraph>
      contact information such as your name, addresses and email addresses; 
    </Typography.Paragraph>
    <Typography.Paragraph>
      other contact details including online addresses or online names;
    </Typography.Paragraph>
    <Typography.Paragraph>
      anonymous demographic information which is not unique to you, such as your
      postcode, age, gender;
    </Typography.Paragraph>
    <Typography.Paragraph>
      your location or activity including IP address, and whether you’ve
      accessed third party sites; and 
    </Typography.Paragraph>
    <Typography.Paragraph>
      other information relevant to the type of services being provided to you.
    </Typography.Paragraph>
    <Typography.Paragraph>
      In regard to any sensitive information, we will only collect sensitive
      information in compliance with the local data protection laws and/or with
      your consent and/or where it is reasonably necessary for or directly
      related to the services that you have requested from us.  To the extent
      permitted by the local data protection laws you consent to us collecting
      that sensitive information for the purpose for which it was collected and
      being stored, used, processed and disclosed as set out in this Privacy
      Policy.
    </Typography.Paragraph>
    <Typography.Title>Scope</Typography.Title>
    <Typography.Title>This Privacy Policy applies to:</Typography.Title>
    <Typography.Paragraph>
      all personal information that is collected, stored, used, processed and
      disclosed by Jigsaur when you purchase our services or goods;
    </Typography.Paragraph>
    <Typography.Paragraph>
      the use of personal information that is collected, stored, used, processed
      and disclosed when you participate in our events, promotions or contests
      or otherwise interact with us (e.g. through social media); and
    </Typography.Paragraph>
    <Typography.Paragraph>
      how we process your personal information, who we might share it with and
      what controls and rights you have in respect of it.
    </Typography.Paragraph>
    <Typography.Paragraph>
      If you do not agree to any of the provisions of this Privacy Policy, you
      must not use our website or any of our services or provide us with any
      personal information.
    </Typography.Paragraph>

    <Typography.Title>SECTION 4 – DISCLOSURE</Typography.Title>
    <Typography.Paragraph>
      We may disclose your personal information if we are required by law to do
      so or if you violate our Terms of Service.
    </Typography.Paragraph>
    <Typography.Title>SECTION 5 – FIREBASE</Typography.Title>
    <Typography.Paragraph>
      Our website is hosted on WordPress. They provide us with the online
      platform that allows us to provide information on our products and
      services to you.
    </Typography.Paragraph>
    <Typography.Paragraph>
      Your data is stored through Firebase's data storage, databases and the
      general Firebase application. They store your data on a secure server
      behind a firewall.
    </Typography.Paragraph>

    <Typography.Title>SECTION 7 – THIRD-PARTY SERVICES</Typography.Title>
    <Typography.Paragraph>
      In general, the third-party providers used by us will only collect, use
      and disclose your information to the extent necessary to allow them to
      perform the services they provide to us.
    </Typography.Paragraph>
    <Typography.Paragraph>
      However, certain third-party service providers, such as payment gateways
      and other payment transaction processors, have their own privacy policies
      in respect to the information we are required to provide to them for your
      purchase-related transactions.
    </Typography.Paragraph>
    <Typography.Paragraph>
      For these providers, we recommend that you read their privacy policies so
      you can understand the manner in which your personal information will be
      handled by these providers.
    </Typography.Paragraph>
    <Typography.Paragraph>
      Once you leave our website or are redirected to a third-party website or
      application, you are no longer governed by this Privacy Policy or our
      website’s Terms of Service.
    </Typography.Paragraph>
    <Typography.Paragraph>Links:</Typography.Paragraph>
    <Typography.Paragraph>
      When you click on links on our website, they may direct you away from our
      site. We are not responsible for the content or the privacy practices of
      other sites and encourage you to read their privacy statements.
    </Typography.Paragraph>
    <Typography.Title>SECTION 8 – SECURITY</Typography.Title>
    <Typography.Paragraph>
      To protect your personal information, we take reasonable precautions and
      follow industry best practices to make sure it is not inappropriately
      lost, misused, accessed, disclosed, altered or destroyed.
    </Typography.Paragraph>
    <Typography.Title>SECTION 9 – AGE OF CONSENT</Typography.Title>
    <Typography.Paragraph>
      By using this site, you represent that you are at least the age of
      majority in your state or province of residence, or that you are the age
      of majority in your state or province of residence, and you have given us
      your consent to allow any of your minor dependents to use this site.
    </Typography.Paragraph>
    <Typography.Title>
      SECTION 10 – CHANGES TO THIS PRIVACY POLICY
    </Typography.Title>
    <Typography.Paragraph>
      We reserve the right to modify this privacy policy at any time, so please
      review it frequently. Changes and clarifications will take effect
      immediately upon their posting on the website. If we make material changes
      to this policy, we will notify you here that it has been updated, so that
      you are aware of what information we collect, how we use it, and under
      what circumstances, if any, we use and/or disclose it.
    </Typography.Paragraph>
    <Typography.Paragraph>
      We encourage you to periodically review this Privacy Policy to be informed
      of how we are protecting your information.
    </Typography.Paragraph>
    <Typography.Title>QUESTIONS AND CONTACT INFORMATION</Typography.Title>
    <Typography.Paragraph>
      If you have any complaints about our privacy practices, please feel free
      to send in details of your complaints to contact@jigsaur.com. We take
      complaints very seriously and will respond shortly after receiving written
      notice of your complaint.
    </Typography.Paragraph>
  </div>
);
export const JigsaurPrivacyPage = () => (
  <div>
    <Typography.Title>Jigsaur Privacy Policy</Typography.Title>
    <Typography.Paragraph>
      Jigsaur respects your right to privacy and is committed to safeguarding
      the privacy of our customers and website visitors. We adhere to the
      Australian Privacy Principles contained in the Privacy Act 1988 (Cth).
      This policy sets out how we collect and treat your personal information.
    </Typography.Paragraph>
    <Typography.Paragraph>
      "Personal information" is information we hold which is identifiable as
      being about you.
    </Typography.Paragraph>

    <div>
      <Typography.Title level={4}>
        Collection of Personal Information
      </Typography.Title>
      <Typography.Paragraph>
        Jigsaur will, from time to time, receive and store personal information
        you enter onto our website, provide to us directly, or give to us in
        other forms. You may provide basic information such as your name, phone
        number, address, and email address to enable us to send information,
        provide updates, and process your product or service order. Jigsaur
        collects and holds information relevant for the provision of services to
        you as a customer.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Examples of personal information we hold include:
      </Typography.Paragraph>
      <ul>
        <li>Your first and last name (including title)</li>
        <li>
          Your contact details (including email address, mobile phone number,
          and address)
        </li>
        <li>
          Additional information such as feedback, personal or business affairs,
          content or email preferences, and interactions with our customer
          support.
        </li>
      </ul>
      <Typography.Paragraph>
        Additionally, we may also collect any other information you provide
        while interacting with us.
      </Typography.Paragraph>
    </div>

    <div>
      <Typography.Title level={4}>
        How We Collect Your Personal Information
      </Typography.Title>
      <Typography.Paragraph>
        Jigsaur collects personal information from you in a variety of ways,
        including when you interact with us electronically or in person, when
        you access our website, and when we provide our services to you. We may
        receive personal information from third parties. If we do, we will
        protect it as set out in this Privacy Policy.
      </Typography.Paragraph>
    </div>

    <div>
      <Typography.Title level={4}>
        How We Use Your Personal Information
      </Typography.Title>
      <Typography.Paragraph>
        We will use your information to:
      </Typography.Paragraph>
      <ul>
        <li>Verify your identity</li>
        <li>Provide services and products to you</li>
        <li>Tailor content to you</li>
        <li>Improve the services and products we provide to you</li>
        <li>
          Conduct research and statistical analysis on an anonymised basis
        </li>
        <li>Protect and enforce our legal rights and interests</li>
        <li>For any other purpose authorized by you or Privacy Law</li>
      </ul>
    </div>

    <div>
      <Typography.Title level={4}>
        Disclosing Your Personal Information
      </Typography.Title>
      <Typography.Paragraph>
        We may disclose your personal information to:
      </Typography.Paragraph>
      <ul>
        <li>
          Any of our employees, officers, insurers, professional advisers,
          agents, suppliers, or subcontractors insofar as reasonably necessary
          for the purposes set out in this Policy
        </li>
        <li>
          Any business that supports our services and products, including any
          person who hosts or maintains any underlying IT system or data centre
          that we use
        </li>
        <li>
          A person who can require us to supply your personal information (e.g.
          a regulatory authority)
        </li>
        <li>
          Any other person authorized by Privacy Law (e.g. a law enforcement
          agency)
        </li>
        <li>Any other person authorized by you</li>
      </ul>
      <Typography.Paragraph>
        Jigsaur will never sell personal information to third parties or allow
        personal information to be used for direct marketing via third parties.
      </Typography.Paragraph>
    </div>

    <div>
      <Typography.Title level={4}>
        Security of Your Personal Information
      </Typography.Title>
      <Typography.Paragraph>
        Jigsaur is committed to ensuring that the information you provide to us
        is secure. In order to prevent unauthorized access or disclosure, we
        have put in place suitable physical, electronic, and managerial
        procedures to safeguard and secure information and protect it from
        misuse, interference, loss, and unauthorized access, modification, and
        disclosure.
      </Typography.Paragraph>
      <Typography.Paragraph>
        The transmission and exchange of information is carried out at your own
        risk. We cannot guarantee the security of any information that you
        transmit to us, or receive from us. Although we take measures to
        safeguard against unauthorized disclosures of information, we cannot
        assure you that personal information we collect will not be disclosed in
        a manner inconsistent with this Privacy Policy.
      </Typography.Paragraph>
    </div>

    <div>
      <Typography.Title level={4}>
        Access to Your Personal Information
      </Typography.Title>
      <Typography.Paragraph>
        Subject to some exceptions set out in the Australian Privacy Principles
        (Principle 12), you can gain access to the personal information we hold
        about you by submitting a request to Jigsaur at contact@jigsaur.com. We
        will respond in writing to written requests for access within a
        reasonable time. We may charge you an access fee in some circumstances.
      </Typography.Paragraph>
    </div>

    <div>
      <Typography.Title level={4}>Changes to Privacy Policy</Typography.Title>
      <Typography.Paragraph>
        We may change this Privacy Policy in the future. We may modify this
        Policy at any time, in our sole discretion, and all modifications will
        be effective immediately upon our posting of the modifications on our
        website or notice board. Please check back from time to time to review
        our Privacy Policy.
      </Typography.Paragraph>
    </div>

    <div>
      <Typography.Title level={4}>Website Usage</Typography.Title>
      <Typography.Paragraph>
        When you come to our website (jigsaur.com), we may collect certain
        information such as browser type, operating system, and the website you
        visited immediately before coming to our site. This information is used
        in an aggregated manner to analyze how people use our site so we can
        improve our service.
      </Typography.Paragraph>
      <Typography.Paragraph>
        We may use cookies on our website to identify you when you return and to
        store details about your use of the site. Most web browsers
        automatically accept cookies, but you can choose to reject cookies by
        changing your browser settings. However, this may prevent you from
        taking full advantage of our website.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Our website may have links to other websites not owned or controlled by
        us. These links are meant for your convenience only. We are not
        responsible for the privacy practices of other websites and encourage
        you to read their privacy statements.
      </Typography.Paragraph>
    </div>

    <div>
      <Typography.Title level={4}>Complaints About Privacy</Typography.Title>
      <Typography.Paragraph>
        If you have any complaints about our privacy practices, please feel free
        to send in details of your complaints to contact@jigsaur.com. We take
        complaints very seriously and will respond shortly after receiving
        written notice of your complaint.
      </Typography.Paragraph>
    </div>
  </div>
);
export default PrivacyPage;
