import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { useState } from "react";

const ButtonGroup = ({ children }: React.PropsWithChildren) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Popover
      content={children}
      trigger="click"
      open={open}
      onOpenChange={(open) => setOpen(open)}
    >
      <Button shape="circle">
        <EllipsisOutlined key="ellipsis" />
      </Button>
    </Popover>
  );
};

export default ButtonGroup;
