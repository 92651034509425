import { Space, Tabs } from "antd";
import { AuthContext } from "api/auth";
import { Container, DefaultHeader } from "component/Container";
import { useContext } from "react";
import { Navigate, Outlet, useMatch, useNavigate } from "react-router-dom";

const items = [
  {
    key: "createPlan",
    label: "Create Plan",
  },
  {
    key: "notifications",
    label: "Send Notifications",
  },
];
const Admin = ({ children }: React.PropsWithChildren) => {
  const authUser = useContext(AuthContext);
  const navigate = useNavigate();
  const match = useMatch("/admin/:childPath/*");
  const childPath = match?.params.childPath ?? "/";

  if (authUser.user?.uid !== "i6yhLIKgG9QjlCRz91s9ulCfq1v2") {
    return <Navigate to="/home" />;
  }
  return (
    <Container
      header={<DefaultHeader />}
      content={
        <Space direction="vertical">
          <Tabs
            items={items}
            onChange={(key) => navigate(`./${key}`)}
            activeKey={childPath}
          ></Tabs>
          <Outlet />
        </Space>
      }
      footer={null}
    />
  );
};
export default Admin;
