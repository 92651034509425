import {
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
  LoginOutlined,
  RightOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Row,
  Space,
  Typography,
} from "antd";
import { FriendsContext } from "api/friends";
import {
  joinQueue,
  leaveOrDeleteQueueWithUIConfirmation,
  QueueType,
} from "api/queue";
import { Profile, UserContext } from "api/user";
import { dateArrayToString } from "api/utils";
import ButtonGroup from "component/ButtonGroup";
import { activities } from "component/Form/QueueInput";
import { getParticipantList } from "component/Participants";
import { useShare } from "component/Share";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CircularImageIcon from "./CircularImageIcon";

const QueueCard = ({
  children,
  queue,
}: React.PropsWithChildren<{ queue: QueueType }>) => {
  const friends = useContext(FriendsContext);
  const activity = activities.find(({ id }) => id === queue.activityType)!;
  const { profile } = useContext(UserContext);
  const participants = getParticipantList({ friends, queue });
  const share = useShare();
  return (
    <Space direction="vertical">
      <Card>
        <Flex vertical gap={15}>
          <Flex justify="space-between">
            <ActivityTitle activity={activity} />
            <QueueActionButtons profile={profile} queue={queue} />
          </Flex>
          <ParticipantList participants={participants} />
          <LoadingIndicator />
          <QueueDetails queue={queue} />
          <Divider />
          {queue.creator === profile.id ? (
            <>
              <Button
                onClick={() =>
                  share({
                    url: `https://jigsaur.com/q/${queue.id}`,
                    text: `Join me on a ${activities.find((id) => queue.activityType)!.text} at jigsaur.com/q/${queue.id}`,
                  })
                }
                icon={<UserAddOutlined />}
              >
                Invite friends
              </Button>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => leaveOrDeleteQueueWithUIConfirmation(queue)}
              >
                Stop waiting
              </Button>
            </>
          ) : !queue.participants.some((p) => p === profile.id) ? (
            <Button
              icon={<LoginOutlined />}
              onClick={() => joinQueue(queue, profile)}
            >
              Join
            </Button>
          ) : null}
        </Flex>
      </Card>
    </Space>
  );
};

const ActivityTitle = ({ activity }: any) => (
  <Flex vertical>
    <Typography.Title level={3} style={{ margin: 0 }}>
      {activity.text}
    </Typography.Title>
    <Typography.Paragraph style={{ margin: 0 }}>
      {activity.description}
    </Typography.Paragraph>
  </Flex>
);

const ParticipantList = ({ participants }: { participants: Profile[] }) => (
  <Row>
    {participants.map((p, index) => (
      <Col span={6} key={index}>
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <Avatar src={p?.profilePic} key={index} />
          <Typography.Text
            style={{
              display: "inline-block",
              textAlign: "center",
              fontSize: "x-small",
            }}
          >
            {p?.preferredName}
          </Typography.Text>
        </Space>
      </Col>
    ))}
  </Row>
);
const QueueActionButtons = ({ profile, queue }: any) => {
  const navigate = useNavigate();
  return (
    <ButtonGroup>
      <Space.Compact direction="vertical" style={{ width: "100%" }}>
        {profile.id === queue.creator ? (
          <Button
            icon={<EditOutlined />}
            onClick={() => navigate(`/q/${queue.id}/edit`)}
          >
            Edit activity
          </Button>
        ) : null}
        <Button
          type="primary"
          danger
          icon={<DeleteOutlined />}
          onClick={() => leaveOrDeleteQueueWithUIConfirmation(queue)}
        >
          Stop waiting
        </Button>
      </Space.Compact>
    </ButtonGroup>
  );
};
const QueueDetails = ({ queue }: any) => (
  <Flex vertical>
    <Card styles={{ body: { padding: "10px 15px" } }}>
      <Space align="center">
        <EnvironmentOutlined />
        Sydney
      </Space>
    </Card>
    <Card styles={{ body: { padding: "10px 15px" } }}>
      <Space align="center">
        <ClockCircleOutlined />
        {dateArrayToString(queue.preferredDate) || "Any time"}
      </Space>
    </Card>
  </Flex>
);
const LoadingIndicator = () => (
  <Flex vertical align="center" gap={6}>
    <Typography.Title level={5} style={{ margin: 0 }}>
      Waiting for others
    </Typography.Title>
    <div>
      <div className="dot-loader" />
    </div>
  </Flex>
);
export const SmallQueueCard = ({ queue }: { queue: QueueType }) => {
  const { text, icon } = activities.find(
    ({ id }) => id === queue.activityType,
  )!;
  const { profile } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <Card onClick={() => navigate(`/q/${queue.id}`)}>
      <Flex style={{ width: "100%" }} gap={5}>
        <CircularImageIcon src={icon} />

        <Space direction="vertical" style={{ flex: 1 }}>
          <Typography.Title
            level={5}
            style={{
              margin: 0,
            }}
          >
            {text}
          </Typography.Title>
          <Typography.Text
            style={{
              margin: 0,
            }}
            type="secondary"
          >
            <EnvironmentOutlined /> {profile.location}
          </Typography.Text>
          <Typography.Text
            style={{
              margin: 0,
            }}
            type="secondary"
          >
            <ClockCircleOutlined />{" "}
            {dateArrayToString(queue.preferredDate, true) || "Any time"}
          </Typography.Text>
        </Space>
        <RightOutlined />
      </Flex>
    </Card>
  );
};
export default QueueCard;
