import {
  Button,
  Card,
  Divider,
  Flex,
  Form,
  Space,
  Steps,
  Typography,
} from "antd";
import { log, useLogPage } from "api/analytics";
import { ActivityType, createQueue, QueueContext } from "api/queue";
import { UserContext } from "api/user";
import CircularImageIcon from "component/CircularImageIcon";
import { ParticipantCard } from "component/Participants";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, DefaultHeader, ProfileAction } from "./component/Container";
import {
  activities,
  AdditionalQuestionsInput,
  DateTimeInput,
  QueueTypeInput,
  VerifiedOnlyInput,
} from "./component/Form/QueueInput";

const { Title, Text } = Typography;

const GeneralStage = () => (
  <Space direction="vertical">
    <Title level={2}>What would you like to do?</Title>
    <Text type="secondary">Select what you are looking for</Text>
    <QueueTypeInput />
  </Space>
);

const QueueDetailsStage = ({ activity }: { activity: ActivityType }) => (
  <Card>
    <Flex vertical align="stretch">
      <Space direction="vertical">
        <Typography.Title level={2} style={{ margin: 0 }}>
          Find people to {activity.to}
        </Typography.Title>
        <Typography.Paragraph>{activity.description}</Typography.Paragraph>
      </Space>
      <Flex style={{ alignSelf: "center" }}>
        <CircularImageIcon src={activity.icon} />
      </Flex>
      <Divider />
      <DateTimeInput />
      <AdditionalQuestionsInput />
      <VerifiedOnlyInput />
      <SelfParticipantCard />
    </Flex>
  </Card>
);
const SelfParticipantCard = () => {
  const { profile } = useContext(UserContext);
  return <ParticipantCard profile={profile} />;
};

/*
const InviteButton = () => {
  const {profile} =useContext(UserContext);
  const form = Form.useFormInstance();
  const share = useShare();
  if (!form.getFieldValue(id)) {

  }
  return <Button
        onClick={() =>
          share({
            url: `https://jigsaur.com/`,
            text: `Join me on a ${activities.find((id) => form.getFieldValue("activityType"))!.text} at jigsaur.com/join/${self.id}`,
          })
        }
      >
        Invite friends
      </Button>
}
   */

const CreateQueue = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { queue } = useContext(QueueContext);
  const { queueId } = useParams() as { queueId: string };

  const [index, setIndex] = useState<number>(0);

  const stages = [<GeneralStage />];
  const activityType = form.getFieldValue("activityType");
  useLogPage("queueCreate");
  if (activityType) {
    const activity = activities.find(({ id }) => id === activityType);
    if (!activity) {
      throw Error();
    }
    stages.push(<QueueDetailsStage activity={activity} />);
  } else {
    stages.push(<div />);
  }

  return Container({
    header: <DefaultHeader action={<ProfileAction />} />,
    content: (
      <Flex vertical gap={2}>
        <Steps
          size="small"
          current={index}
          items={stages.map((_, i) => ({ disabled: i > index }))}
          onChange={setIndex}
          direction="horizontal"
          responsive={false}
          style={{ marginBottom: 10 }}
        />
        <Form
          onValuesChange={(d) => log("queueCreate_select", d)}
          initialValues={
            (queueId &&
              queue &&
              queue.find(({ id: qid }) => queueId === qid)) ||
            undefined
          }
          preserve
          onFinish={() => {
            const data = form.getFieldsValue(true);
            log(`queueCreate_stageFinish`, {
              index: index,
              data,
            });
            if (index + 1 < stages.length) {
              setIndex((p) => p + 1);
            } else {
              setSubmitting(true);
              createQueue(data)
                .then(() => log(`queueCreate_success`))
                .then(() => {
                  navigate("/home");
                })
                .finally(() => setSubmitting(false));
            }
          }}
          form={form}
        >
          {stages[index]}
        </Form>
      </Flex>
    ),
    footer: (
      <Flex vertical gap="large">
        <Button
          type="primary"
          block
          shape="round"
          size="large"
          onClick={() => {
            form.submit();
          }}
          loading={submitting}
        >
          {index === stages.length - 1 ? "Confirm" : "Next"}
        </Button>
      </Flex>
    ),
  });
};
export default CreateQueue;
