import { LeftOutlined } from "@ant-design/icons";
import { Button, Flex, Layout, Space, theme } from "antd";
import { log } from "api/analytics";
import { FriendsContext } from "api/friends";
import { QueueId, useQueueWithQueueId } from "api/queue";
import Loader from "component/Loader";
import QueueCard from "component/Queue";
import CreateQueue from "CreateQueue";
import NoMatchPage from "NoMatchPage";
import React, { useContext } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Container, Logo } from "./component/Container";

const QueuePage = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const { queueId } = useParams() as { queueId: QueueId };

  const { loading, queue, error } = useQueueWithQueueId(queueId!);
  const friends = useContext(FriendsContext);
  const {
    token: { colorBgLayout },
  } = theme.useToken();

  if (error) {
    log("exception", {
      description: "QueuePage queue missing",
    });
    return <Navigate to="/home" />;
  }
  if (!queue || loading || friends[queue.creator] === undefined) {
    return <Loader />;
  }
  const creator = friends[queue.creator];
  if (creator === null) {
    log("exception", {
      description: "QueuePage creator missing",
    });
    return null;
  }

  return (
    <Container
      header={
        <Layout.Header
          style={{
            backgroundColor: colorBgLayout,
            paddingLeft: 5,
          }}
        >
          <Space>
            <Button icon={<LeftOutlined />} onClick={() => navigate("/home")} />

            <Logo />
          </Space>
        </Layout.Header>
      }
      content={
        <Flex
          align="stretch"
          justify="space-between"
          style={{ height: "100%" }}
          vertical
        >
          <Flex vertical flex={1} justify="center" align="center">
            <QueueCard queue={queue} />
          </Flex>
        </Flex>
      }
    />
  );
};

const QueueContainer = () => (
  <Routes>
    <Route path="/" element={<QueuePage />} />
    <Route path="/edit" element={<CreateQueue />} />
    <Route element={<NoMatchPage />} path="*" />
  </Routes>
);

export default QueueContainer;
