import "animate.css";
import { ConfigProvider } from "antd";
import { ConfigProvider as AntdMobileConfigProvider } from "antd-mobile";
import enUS from "antd-mobile/es/locales/en-US";
import { AuthProvider } from "api/auth";
import { ShareProvider } from "component/Share";
import { RouterProvider } from "react-router-dom";
import { router } from "routerConfig";

/*
import { AuthProvider } from "api/auth";
import { router } from "routerConfig";
 */

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#A8815B",
        },
      }}
    >
      <AntdMobileConfigProvider locale={enUS}>
        <ShareProvider>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </ShareProvider>
      </AntdMobileConfigProvider>
    </ConfigProvider>
  );
}

export default App;
