import { Dialog } from "antd-mobile";
import Loader from "component/Loader";
import { Dayjs } from "dayjs";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { createContext, useContext, useMemo } from "react";
import { log } from "./analytics";
import { AuthContext } from "./auth";
import { auth, db } from "./firebaseConfig";
import {
  createFirestoreDateConverter,
  Status,
  useDocumentSnapshot,
  useQuerySnapshot,
} from "./firestore";
import { UserId } from "./user";

export type PlanType = {
  id?: string;
  description?: string;
  messageCount?: number;
  participants: UserId[];
  confirmedParticipants: UserId[];
  canceledParticipants: UserId[];
  lastSeen?: { [key: UserId]: number };
  // creator: string;
  time?: "afternoon";
  date?: Dayjs;
  location?: string;
  locationLink?: string;
  // options?: FilterOptions;
  activity: string;
};

export function confirmPlan(plan: PlanType) {
  return updateDoc(doc(db, "plan", plan.id!), {
    confirmedParticipants: arrayUnion(auth.currentUser!.uid),
  });
}

export function createPlan(plan: PlanType) {
  const user = auth.currentUser;
  if (!user) {
    throw Error();
  }

  return addDoc(
    collection(db, "plan").withConverter(
      createFirestoreDateConverter(["date"]),
    ),
    plan,
  );
}

export function updateMessageCount(planId: string, count: number) {
  return updateDoc(doc(db, "plan", planId), {
    messageCount: count,
  });
}

function leavePlan(plan: PlanType) {
  log("plan_leavePlan");
  const user = auth.currentUser;
  if (!user || !plan.id) {
    throw Error();
  }

  return updateDoc(doc(db, "plan", plan.id!), {
    canceledParticipants: arrayUnion(auth.currentUser?.uid),
    participants: arrayRemove(auth.currentUser?.uid),
    confirmParticipants: arrayRemove(auth.currentUser?.uid),
  });
}

export function leaveOrDeletePlanWithUIConfirmation(plan: PlanType) {
  return Dialog.show({
    content: "Oh no! Are you sure you want to stop queueing?",
    closeOnAction: true,
    actions: [
      { key: "cancel", text: "Stay in queue" },
      {
        key: "exit",
        text: "Yes, stop queueing",
        danger: true,
        onClick: () =>
          leavePlan(plan).then(() => {
            window.location.href = `/feedback/plan/${plan.id}`;
          }),
      },
    ],
  });
}

export const getPlanRef = (planId: string) => {
  return doc(db, "plan", planId).withConverter(
    createFirestoreDateConverter(["date"]),
  );
};

const getPlanRefForUser = (uid: string) => {
  return query(
    collection(db, "plan"),
    where("participants", "array-contains", uid),
    orderBy("date", "desc"),
  ).withConverter(createFirestoreDateConverter(["date"]));
};

export function usePlan(planId: string) {
  const q = useMemo(() => getPlanRef(planId), [planId]);
  const { loading, error, data } = useDocumentSnapshot(q);
  if (error) {
    return { loading: false, error: true, plan: null };
  }
  return { loading, error, plan: data as PlanType };
}

function useUserPlan(uid: string) {
  const q = useMemo(() => getPlanRefForUser(uid), [uid]);
  const { loading, error, data } = useQuerySnapshot(q);
  if (error && error === "missing") {
    return { loading: false, error: false, plan: [] };
  }
  return { loading, error, plan: data as PlanType[] };
}

export function useAllFuturePlanSnapshot() {
  const q = useMemo(
    () =>
      query(collection(db, "plan"), orderBy("date", "desc")).withConverter(
        createFirestoreDateConverter(["date"]),
      ),
    [],
  );
  const { loading, error, data } = useQuerySnapshot(q);
  if (error && error === "missing") {
    return { loading: false, error: false, plan: [] };
  }
  return { loading, error, plan: data as PlanType[] };
}

export const PlanContext = createContext<Status<{ plan?: PlanType[] }>>({
  loading: true,
  error: false,
});

export function PlanProvider({ children }: { children: React.ReactNode }) {
  const authUser = useContext(AuthContext);
  const uid = authUser.user!.uid;
  const plan = useUserPlan(uid);

  if (plan.loading) {
    return <Loader />;
  }

  return <PlanContext.Provider value={plan}>{children}</PlanContext.Provider>;
}
