import { Avatar, Card, Flex, Image, Skeleton, Space, Typography } from "antd";
import axios from "axios";
import { Container, DefaultHeader, PublicAction } from "component/Container";
import dayjs from "dayjs";
import sydney from "images/sydney.png";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { useParams } from "react-router-dom";
import { loadFront } from "yaml-front-matter";

const cache: { [k: string]: any } = {};
importAll(require.context("./content", false, /^.\/.*md/));
function importAll(r: any) {
  r.keys().forEach((key: string) => (cache[key] = r(key)));
}

const BlogHome = ({ children }: React.PropsWithChildren) => {
  const { id } = useParams();
  const [content, setContent] = useState<false | string>(false);
  const [metadata, setMetadata] = useState<any>({});
  console.log(content);
  useEffect(() => {
    axios
      .get(
        require("./content/how-to-make-meaningful-connections-in-your-spare-time.md"),
      )
      .then(({ data }) => data)
      .then(loadFront)
      .then(({ __content, date, ...m }) => {
        if (date) {
          date = dayjs(date);
        }
        setMetadata({ ...m, date });
        setContent(__content);
      });
  }, []);
  if (id) {
    return <div>hi</div>;
  }
  console.log(metadata);

  return (
    <Container
      header={<DefaultHeader action={<PublicAction />} />}
      content={
        <Flex vertical align="center">
          {Object.entries(cache).map(([id]) => (
            <Card style={{ maxWidth: "980px" }}>
              <Skeleton loading={!content}>
                <Image
                  height={200}
                  width="100%"
                  preview={false}
                  style={{ objectFit: "cover", borderRadius: 8 }}
                  src={require("images/activities/banner/coffee.png")}
                />
                <Typography.Title>{metadata.title}</Typography.Title>
                <Typography.Paragraph>
                  {metadata.description}
                </Typography.Paragraph>
                <Space style={{ marginBottom: 10 }}>
                  <Card type="inner">
                    <Card.Meta
                      avatar={<Avatar src={require("images/profiles/2.png")} />}
                      title={metadata.author}
                      description={metadata.date?.format("ll")}
                    />
                  </Card>
                </Space>
                <Markdown
                  components={{
                    a: ({ type, ...props }) => <Typography.Link {...props} />,
                    h1: ({ ...props }) => (
                      <Typography.Title {...props} level={1} />
                    ),
                    h2: ({ ...props }) => (
                      <Typography.Title {...props} level={2} />
                    ),
                    h3: ({ ...props }) => (
                      <Typography.Title {...props} level={3} />
                    ),
                    h4: ({ ...props }) => (
                      <Typography.Title {...props} level={4} />
                    ),
                    h5: ({ ...props }) => (
                      <Typography.Title {...props} level={5} />
                    ),
                    p: ({ ...props }) => <Typography.Paragraph {...props} />,
                  }}
                >
                  {content as string}
                </Markdown>
              </Skeleton>
            </Card>
          ))}
        </Flex>
      }
      footer={null}
    />
  );
};
export default BlogHome;

export const Blog = ({ children }: React.PropsWithChildren) => {
  return (
    <Container
      header={<DefaultHeader />}
      content={
        <Card>
          <Typography.Title level={2}>
            How to make new friends in a new city
          </Typography.Title>
          <Typography.Title level={5} type="secondary">
            Moving cities and saying goodbye to your old friends can feel
            impossible. Here are 10 tips for making new friends when moving to a
            new city or town.
          </Typography.Title>
          <Typography.Text strong>Written by JunYang Sim</Typography.Text>
          <Typography.Paragraph>
            Moving house is regularly cited as one of life’s most stressful
            events, right up there with divorce and the death of a loved one.
            Just moving down the road can be taxing. Change is change, and while
            human beings are incredible at adapting to it, that doesn’t mean we
            have to love it.
          </Typography.Paragraph>
          <Image
            width={"100%"}
            src={sydney}
            preview={false}
            style={{ borderRadius: 50 }}
          />
          <Typography.Paragraph>
            If you’re relocating to a whole new city or town, one of your
            worries might be about moving away from your friends. Arriving
            fresh-faced in a new place is exciting – all those bars and
            restaurants to try! – but the prospect of saying goodbye to old pals
            and trying to make new ones can feel a little daunting.
          </Typography.Paragraph>
          Popular culture is saturated with stories about how to meet the love
          of your life. There are entire television shows dedicated to it, whole
          industries built on helping people meet their soul mate. Meeting new
          friends is no less important, but because it doesn’t get the same air
          time, the process can feel less familiar. Where do you even begin?
          Here are 10 tips for making new friends when you move to a new city or
          town.
        </Card>
      }
      footer={null}
    />
  );
};
