import { Typography } from "antd";
import PageCard, { SimpleCard } from "component/PageCard";
import { Link } from "react-router-dom";

const NoMatchPage = ({ children }: React.PropsWithChildren) => {
  return (
    <PageCard>
      <SimpleCard
        title="Page Not Found"
        subtitle="Oops! It looks like you're lost."
        src={""}
      >
        <Typography.Paragraph>
          We can't find the page you're looking for. Don't worry, it happens to
          the best of us.
        </Typography.Paragraph>
        <Typography.Paragraph>Here's what you can do:</Typography.Paragraph>
        <ul>
          <li>
            Go back to the <Link to="/home">homepage</Link> and start fresh.
          </li>
          <li>
            <Link to="/feedback">Send us a feedback</Link> where we went wrong.
          </li>
        </ul>
      </SimpleCard>
    </PageCard>
  );
};
export default NoMatchPage;
