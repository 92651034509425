import { SwapRightOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  ConfigProvider,
  Flex,
  Image,
  Layout,
  Row,
  Space,
  theme,
  Typography,
} from "antd";
import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "./component/Container";
import Empty from "./empty.png";
import Friends from "./images/landing.png";

const strings = {
  landing: {
    title: [
      "Make Friends.",
      "Discover Activities.",
      "Experience Life Together.",
    ],
    subtitle:
      "Make real connections offline with Jigsaur. Join now for genuine friendships that go beyond the screen.",
  },
  steps: [
    {
      icon: <Avatar size={80} src={require("images/get_started.png")} />,
      title: "Create Your Profile",
      subtitle: "Tell us a bit about yourself and what you enjoy",
    },
    {
      icon: <Avatar size={80} src={require("images/queue.png")} />,
      title: "Queue Up ",
      subtitle: "Select a specific time and location for a meetup.",
    },
    {
      icon: <Avatar size={80} src={require("images/meet_up.png")} />,
      title: "Meet and Enjoy",
      subtitle:
        "Join a small group of people who share your interests and are ready to make new friends.",
    },
  ],

  factors: [
    {
      title: "Specific Time",
      subtitle: "Plan meetups that fit your schedule, even on short notice.",
    },
    {
      title: "Like-Minded Connections",
      subtitle: "Get matched with people who share your interests and hobbies.",
    },
    {
      title: "Group Activities",
      subtitle: "Enjoy a variety of activities in a safe, small group setting.",
    },
  ],
};

const { Header, Content } = Layout;

export default function OldLandingPage() {
  const {
    token: { colorBgLayout },
  } = theme.useToken();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgLayout: "white",
        },
      }}
    >
      <Layout style={{ height: "100%" }} id="container">
        <Header style={{ backgroundColor: "white" }}>
          <LandingPageHeader />
        </Header>
        <Content>
          <Flex vertical gap={20}>
            <LandingPageMainContent />
            <HowItWorks colorBgLayout={colorBgLayout} />
            <Factors />
            <LandingPageFooter />
          </Flex>
        </Content>
      </Layout>
    </ConfigProvider>
  );
}

export const HowItWorks = ({ colorBgLayout }: { colorBgLayout: any }) => {
  return (
    <Flex
      style={{ backgroundColor: colorBgLayout, padding: 50, borderRadius: 20 }}
      vertical
    >
      <Typography.Title style={{ textAlign: "center" }}>
        How it works
      </Typography.Title>
      <Row align="stretch" justify="center" gutter={[30, 30]}>
        {strings.steps.map(({ icon, subtitle, title }) => (
          <Col md={6} xs={24}>
            <Card style={{ height: "100%" }}>
              <Flex vertical align="center">
                {icon}

                <Typography.Title level={4} style={{ textAlign: "center" }}>
                  {title}
                </Typography.Title>
                <Typography.Paragraph style={{ textAlign: "center" }}>
                  {subtitle}
                </Typography.Paragraph>
              </Flex>
            </Card>
          </Col>
        ))}
      </Row>
    </Flex>
  );
};

const LandingPageMainContent = () => {
  const navigate = useNavigate();
  return (
    <Col style={{ maxWidth: "1280px", padding: "0 48px" }}>
      <DevAlert />
      <Row align="middle">
        <Col xs={23} lg={11}>
          <LandingInfos
            {...strings.landing}
            onClick={() => {
              navigate("login");
            }}
            buttonText="Get Started"
          />
        </Col>
        <Col xs={23} lg={11}>
          <Image width={"100%"} src={Friends} preview={false} />
        </Col>
      </Row>
    </Col>
  );
};

const LandingPageHeader = () => {
  const navigate = useNavigate();
  return (
    <Flex justify="space-between" align="center" style={{ height: "100%" }}>
      <Logo />
      <Button type="primary" onClick={() => navigate("login")}>
        Log in
      </Button>
    </Flex>
  );
};

const DevAlert = () => (
  <Alert
    message="Thanks for checking out this site. It is still under development but you can sign up first!"
    type="success"
    style={{ margin: 10 }}
  />
);
export const Factors = () => (
  <Col style={{ maxWidth: "1280px", padding: "0 48px" }}>
    <Row align="middle">
      <Typography.Title>Top Features</Typography.Title>
    </Row>
    <Row align="middle" gutter={48}>
      <Col xs={24} md={12}>
        <Image width={"100%"} src={Empty} preview={false} />
      </Col>
      <Col xs={24} md={12}>
        <Space size={30} direction="vertical">
          {strings.factors.map((text, index) => (
            <Factor {...text} key={index} icon={<SwapRightOutlined />} />
          ))}
        </Space>
      </Col>
    </Row>
  </Col>
);

const LandingInfos = ({
  title,
  subtitle,
  onClick,
  buttonText,
}: {
  title: string[];
  subtitle: string;
  onClick: () => void;
  buttonText: string;
}) => (
  <Space direction="vertical">
    <Space direction="vertical">
      {title.map((t) => (
        <Typography.Title style={{ margin: 0 }} level={2} key={t}>
          {t}
        </Typography.Title>
      ))}
      <Typography.Title level={4} type="secondary">
        {subtitle}
      </Typography.Title>
    </Space>
    <Space>
      <Button
        icon={<SwapRightOutlined />}
        type="primary"
        shape="round"
        size="large"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Space>
  </Space>
);

const Factor = ({
  title,
  subtitle,
  icon,
}: {
  title: string;
  subtitle: string;
  icon: ReactNode;
}) => (
  <Flex>
    <Space size="middle">
      <Button type="primary" icon={icon} size="large" />
      <Space direction="vertical">
        <Typography.Title level={4} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
        <Typography.Text type="secondary">{subtitle}</Typography.Text>
      </Space>
    </Space>
  </Flex>
);

export const LandingPageFooter = () => (
  <Col style={{ marginTop: "60px" }}>
    <Typography.Paragraph type="secondary">
      See our <Link to="/terms">Terms & Condition</Link> and{" "}
      <Link to="/privacy">Privacy Policy</Link>.
    </Typography.Paragraph>
    <Typography.Text>© Jigsaur Pty Ltd 2024</Typography.Text>
  </Col>
);
