import { Button, Card, Checkbox, Form, Space, Typography } from "antd";
import { Picker } from "antd-mobile/es/components/picker/picker";
import { createPlanFeedback } from "api/feedback";
import { FriendsContext } from "api/friends";
import { PlanContext } from "api/plan";
import { QueueContext } from "api/queue";
import { UserContext } from "api/user";
import { Container, DefaultHeader } from "component/Container";
import { TextInput } from "component/Form/Input";
import { required } from "component/Form/rules";
import { serverTimestamp } from "firebase/firestore";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const leaveReasons = [
  { reason: "My interests have changed.", code: "interest_change" },
  { reason: "My schedule is too full.", code: "schedule_conflict" },
  { reason: "It's not a good personality fit.", code: "personality_mismatch" },
  {
    reason: "Group size/structure isn't right for me.",
    code: "group_size_fit",
  },
  { reason: "Other commitments took priority.", code: "priorities_changed" },
  { reason: "Not enough communication.", code: "communication_issues" },
  { reason: "Expectations weren't met.", code: "expectations_unmet" },
  { reason: "Feeling overwhelmed.", code: "overwhelmed" },
  { reason: "Other", code: "other" },
].map(({ reason, code }) => ({ label: reason, value: code }));

const RemovePlanFeedback = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const profile = useContext(UserContext);
  const queue = useContext(QueueContext);
  const friends = useContext(FriendsContext);
  const plan = useContext(PlanContext);
  const { planId } = useParams();
  const metadata = {
    profile,
    queue,
    friends,
    plan,
  };
  return Container({
    header: <DefaultHeader />,
    content: (
      <Card title="Give feedback">
        <Typography.Title level={3}>
          We're sorry to see you leave the group!
        </Typography.Title>
        <Typography.Paragraph>
          Leave us some feedback so we can improve next time!
        </Typography.Paragraph>
        <Form
          onFinish={(data) => {
            return createPlanFeedback({
              ...data,
              planId,
              metadata: JSON.stringify(metadata),
              timestamp: serverTimestamp(),
            }).then(() => navigate("/home"));
          }}
        >
          <Space direction="vertical" size="large" style={{ maxWidth: "100%" }}>
            <Form.Item name="reason" rules={[required]}>
              <ReasonSelect value={[]} onChange={() => {}} />
            </Form.Item>
            <TextInput
              name="more"
              label="Is there anything else you'd like to add about your experience with this group?"
              rules={[]}
            />
            <Form.Item
              name="contactCheck"
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox>
                I'd love to receive follow-up communication about my feedback.
              </Checkbox>
            </Form.Item>
          </Space>

          <Button htmlType="submit" type="primary" block>
            Submit
          </Button>
          <Button onClick={() => navigate("/home")} block type="link">
            Cancel
          </Button>
        </Form>
      </Card>
    ),
    footer: null,
  });
};

function ReasonSelect({ value, onChange }: { value: any; onChange: any }) {
  const [visible, setVisible] = useState(false);
  const currentValueLabel = leaveReasons.find(
    ({ value: v }) => value === v,
  )?.label;
  return (
    <>
      <Typography.Paragraph strong>
        <Typography.Text type="danger">*</Typography.Text> What's the reason why
        you chose to leave the group?
      </Typography.Paragraph>
      <Button
        onClick={() => {
          setVisible(true);
        }}
        block
      >
        <Typography.Text ellipsis>
          {currentValueLabel ? currentValueLabel : "Select Reason..."}
        </Typography.Text>
      </Button>
      <Picker
        columns={[leaveReasons]}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        value={value}
        onConfirm={(v) => onChange(v[0])}
      />
    </>
  );
}
export default RemovePlanFeedback;
