import { AuthApiProvider } from "api";
import Loader from "component/Loader";
import { handleRedirect } from "component/LoginMethods/FacebookLogin";
import { Dayjs } from "dayjs";
import { User } from "firebase/auth";
import {
  doc,
  DocumentReference,
  runTransaction,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { createContext, useContext, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { goals } from "../component/Form/ProfileInput";
import { AuthContext } from "./auth";
import { auth, db } from "./firebaseConfig";
import {
  createFirestoreDateConverter,
  Status,
  useDocumentSnapshot,
} from "./firestore";
import { maybeCreateQueueFromQuickstart, QueueId } from "./queue";
import { quickstartDelete, quickstartLoad } from "./quickstart";
import { Branded } from "./utils";

export type UserId = Branded<string, "UserId">;

export type Profile = {
  id: UserId;
  verifiedNumber?: number;
  firstName?: string;
  birthday?: Timestamp | Dayjs;
  interestsQuestions?: { [key: string]: { [key: string]: string } };
  lastName?: string;
  name: string;
  interests?: { [key: string]: boolean };
  location?: string;
  preferredName?: string;
  origin?: string;
  tags?: string[];
  email?: string;
  priorHobbies?: string;
  profilePic?: string;
  quickstart?: boolean;
  aboutMe?: string;
  gender?: "male" | "female" | string;
  verified?: boolean;
  verifiedBy?: string[];
  password?: string;
  completedSetup?: boolean;
  bundleSelection?: { [key in string]: boolean };
  goal?: { [key in keyof typeof goals]: boolean };

  ref?: DocumentReference;
  quickJoinQueue: QueueId[];
};

export const UserContext = createContext<Status<{ profile: Profile }>>(
  {} as Status<{ profile: Profile }>,
);

export function getUserRef(uid: UserId) {
  return doc(db, "users", uid).withConverter(
    createFirestoreDateConverter(["birthday"], {
      fromFirestore: (data) => {
        if (!data.profilePicId) {
          return data;
        } else {
          return {
            ...data,
            profilePic: require(`images/profiles/${data.profilePicId}.png`),
          };
        }
      },
    }),
  );
}

export function useProfileSnapshot() {
  const authUser = useContext(AuthContext);
  const uid = authUser.user!.uid as UserId;
  const x = useMemo(() => getUserRef(uid), [uid]);
  const { loading, error, data } = useDocumentSnapshot(x);
  if (error && error === "missing") {
    maybeCreateNewProfile(authUser.user!);
    return { loading: true, error: false, profile: null };
  }
  return { loading, error, profile: data as Profile };
}

export function UserProvider({ children }: { children: React.ReactNode }) {
  const userProfile = useProfileSnapshot();
  const { pathname } = useLocation();

  if (userProfile.loading) {
    return <Loader />;
  }

  if (
    !userProfile.profile?.completedSetup &&
    !userProfile.profile?.quickstart
  ) {
    if (pathname !== "/createProfile") {
      return <Navigate to="/createProfile" />;
    }
  }
  if (!userProfile.profile) {
    return <Navigate to="/login" />;
  }

  return (
    <UserContext.Provider value={userProfile}>
      <AuthApiProvider>{children}</AuthApiProvider>
    </UserContext.Provider>
  );
}

export function updateSelfProfile(profile: Partial<Profile>) {
  const user = auth.currentUser;
  if (!user) {
    throw Error();
  }
  const profileDoc = getUserRef(user.uid as UserId);
  return setDoc(profileDoc, profile, { merge: true });
}

function maybeCreateNewProfile(user: User) {
  handleRedirect()
    .then((userCreatedThroughRedirect) => {
      if (userCreatedThroughRedirect) {
        return;
      }
      const { profile } = quickstartLoad();
      createProfileIfNew(user, profile || {});
    })
    .then(() => maybeCreateQueueFromQuickstart());
}

export function createProfileIfNew(user: User, profile: any) {
  if (!user) {
    throw Error();
  }
  const profileDoc = getUserRef(user.uid as UserId);
  return runTransaction(db, async (transaction) => {
    const sfDoc = await transaction.get(profileDoc);
    if (sfDoc.exists()) {
      return;
    }
    transaction.set(profileDoc, profile);
    quickstartDelete("profile");
  });
}
