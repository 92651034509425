import { CheckOutlined, EditOutlined, StarFilled } from "@ant-design/icons";
import {
  Button,
  Flex,
  Form,
  Input,
  InputProps,
  InputRef,
  Select,
  Space,
  Typography,
} from "antd";
import { DatePicker } from "antd-mobile";
import dayjs, { Dayjs } from "dayjs";
import { ReactNode, useCallback, useState } from "react";
import { required } from "./rules";

import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { Card, CardProps, ConfigProvider, theme } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import CircularImageIcon from "component/CircularImageIcon";
import { useEffect } from "react";
import { data as suburbsOptions } from "../../data/suburbs";
const { Title } = Typography;

const removeKeyboard = () => (document.activeElement as HTMLElement).blur();
export const removeKeyboardWhenScrollProps = {
  onFocus: () =>
    document
      .getElementById("root")!
      .addEventListener("touchmove", removeKeyboard),
  onBlur: () =>
    document
      .getElementById("root")!
      .removeEventListener("touchmove", removeKeyboard),
};

export type FormStage<FormField extends {}> = {
  id?: string;
  title: string;
  subtitle: string;
  names: (keyof FormField)[];
  fields: React.JSX.Element;
};

export type InputType =
  | "firstName"
  | "lastName"
  | "password"
  | "username"
  | "email";

export type SelectableProps = {
  selected?: boolean;
  onSelect?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const TextInput = ({
  name,
  label,
  placeholder,
  rules = [required],
  size = "large",
  initialValue = "",
  ...props
}: {
  name: string | string[];
  rules?: any[];
  placeholder?: string;
  label?: string;
  initialValue?: string;
  size?: SizeType;
} & Omit<InputProps, "name"> &
  React.RefAttributes<InputRef>) => (
  <Form.Item
    name={name}
    rules={rules}
    label={label ?? name}
    initialValue={initialValue}
    layout="vertical"
  >
    <Input
      size={size}
      placeholder={placeholder}
      {...removeKeyboardWhenScrollProps}
      {...props}
    />
  </Form.Item>
);

export const SaveableTextInput = ({ onChange, value }: any) => {
  const [editable, setEditable] = useState(!value);
  if (editable) {
    return (
      <Space.Compact style={{ width: "100%" }}>
        <Input
          size="large"
          onChange={onChange}
          value={value}
          onPressEnter={() => setEditable(false)}
        />
        <Button
          size="large"
          onClick={() => setEditable(false)}
          icon={<CheckOutlined />}
        />
      </Space.Compact>
    );
  }
  return (
    <Flex justify="space-between">
      <Typography.Text strong>{value}</Typography.Text>
      <Button icon={<EditOutlined />} onClick={() => setEditable(true)} />
    </Flex>
  );
};

export const textFilterOption = (
  input: string,
  option?: { label: string; value: string },
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const SuburbInput = ({
  label,
  placeholder,
}: {
  label?: string;
  placeholder?: string;
}) => {
  return (
    <Form.Item
      label={label ?? "Your current location"}
      style={{ width: "100%" }}
      name="location"
      help="Sydney suburbs only"
    >
      <Select
        size="large"
        showSearch
        style={{ width: "100%" }}
        {...removeKeyboardWhenScrollProps}
        placeholder={placeholder ?? "Your location"}
        optionFilterProp="children"
        filterOption={textFilterOption}
        optionRender={(option) => (
          <Flex justify="space-between">
            {option.data.label}
            <span>{option.data.index < 10 ? <StarFilled /> : null}</span>
          </Flex>
        )}
        options={suburbsOptions}
      />
    </Form.Item>
  );
};
export type CardChoiceProp = {
  icon?: string;
  id: string;
  text: ReactNode;
  description?: string;
  disabled?: boolean;
};

export const CardSelect = ({
  value,
  onChange,
  choices,
  multiple,
  size = "default",
}: {
  multiple?: boolean;
  size?: "default" | "small";
  value: string | { [key: string]: boolean };
  onChange: (arg0: string | { [key: string]: boolean }) => void;
  choices: CardChoiceProp[];
}) => {
  useEffect(() => {
    if (multiple && typeof value !== "object") {
      onChange(choices.reduce((a, c) => ({ ...a, [c.id]: false }), {}));
    }
  }, [choices, multiple, onChange, value]);
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {choices.map(({ id, ...cardProps }, index) => (
        <CardSelectChoice
          id={id}
          {...cardProps}
          key={id}
          selected={typeof value === "object" ? value[id] : value === id}
          onSelect={() =>
            typeof value === "object"
              ? onChange({ ...value, [id]: !value[id] })
              : onChange(id)
          }
        />
      ))}
    </Space>
  );
};

export const CardSelectChoice = ({
  icon,
  text,
  disabled = false,
  selected,
  onSelect,
  description,
  children,
  ...props
}: CardChoiceProp & {
  children?: React.ReactNode | false;
  selected?: boolean;
  onSelect?: (...a: any) => void;
  props?: CardProps;
}) => {
  const {
    token: {
      colorPrimary,
      colorBgContainerDisabled,
      colorTextDisabled,
      colorTextDescription,
    },
  } = theme.useToken();
  const select = disabled ? () => {} : onSelect;
  return (
    <Card
      hoverable={!!onSelect && !disabled}
      bordered
      style={{
        border: selected ? `1px solid ${colorPrimary}` : undefined,
        backgroundColor: disabled
          ? colorBgContainerDisabled
          : selected
            ? "rgb(253, 244, 211)"
            : undefined,
      }}
      {...props}
    >
      {selected ? (
        <CheckCircleTwoTone
          style={{ position: "absolute", top: 0, right: 0, padding: "inherit" }}
          twoToneColor={colorPrimary}
        />
      ) : null}
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space
          style={{ width: "100%", position: "relative" }}
          align="center"
          onClick={select}
        >
          {icon ? <CircularImageIcon src={icon} /> : null}
          <Flex vertical gap={5}>
            <Title
              level={5}
              style={{
                margin: 0,
                color: disabled
                  ? colorTextDisabled
                  : selected
                    ? colorPrimary
                    : "",
              }}
            >
              {text}
            </Title>
            <Typography.Text
              style={{
                margin: 0,
                color: disabled ? colorTextDescription : undefined,
              }}
            >
              {description}
            </Typography.Text>
          </Flex>
        </Space>
        {children}
      </Space>
    </Card>
  );
};

/*
 * TODO: Interests complete
type InterestsInputProps = {
  value?: Set<string>;
  onChange?: (value: Set<string>) => void;
};
 */

export const ActivityTitleInput = () =>
  TextInput({ name: "title", label: "Title" });

export const SelectableTag = ({
  selected,
  onSelect,
  text,
}: SelectableProps & { text: string }) => (
  <ConfigProvider
    theme={{
      token: {},
      components: {
        Button: {
          primaryShadow: "",
        },
      },
    }}
  >
    <Button
      icon={selected ? <CheckCircleFilled /> : <CheckCircleOutlined />}
      type={selected ? "primary" : "default"}
      onClick={onSelect}
    >
      {text}
    </Button>
  </ConfigProvider>
);

export const DateInput = ({
  label,
  value,
  min = dayjs().subtract(100, "year"),
  max = dayjs().add(100, "days"),
  onChange,
}: {
  min?: Dayjs;
  max?: Dayjs;
  label?: string;
  value?: undefined | Dayjs;
  onChange?: (a: Dayjs) => void;
}) => {
  const [visible, setVisible] = useState(false);
  const onSelect = (date: Date) => onChange!(dayjs(date));
  const labelRenderer = useCallback((type: string, data: number) => {
    switch (type) {
      case "month": {
        const months = {
          1: "Jan",
          2: "Feb",
          3: "Mar",
          4: "Apr",
          5: "May",
          6: "Jun",
          7: "Jul",
          8: "Aug",
          9: "Sept",
          10: "Oct",
          11: "Nov",
          12: "Dec",
        };
        return months[data as keyof typeof months] || "";
      }
      default:
        return data;
    }
  }, []);

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        {value ? value.format("ll") : "Select date"}
      </Button>
      <DatePicker
        value={value?.toDate()}
        title={label}
        visible={visible}
        onSelect={onSelect}
        min={min.toDate()}
        max={max.toDate()}
        onClose={() => {
          setVisible(false);
        }}
        cancelText="Cancel"
        confirmText="Confirm"
        renderLabel={labelRenderer}
      />
    </>
  );
};
