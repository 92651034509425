import { LeftOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Flex,
  Form,
  Input,
  Space,
  theme,
  Typography,
} from "antd";
import { useLogPage } from "api/analytics";
import { Message, readMessage, sendMessage, useMessage } from "api/message";
import { Profile, UserContext } from "api/user";
import { activities } from "component/Form/QueueInput";
import { required } from "component/Form/rules";
import { useContext, useEffect, useRef } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { auth } from "./api/firebaseConfig";
import { FriendsContext } from "./api/friends";
import { PlanContext, PlanType } from "./api/plan";
import { Container, DarkHeader } from "./component/Container";
import { EventInfoButton } from "./component/Plan";

const { Title, Paragraph, Text } = Typography;

export default function MessagePage() {
  const { id: roomId = "" } = useParams();
  const messages = useMessage(roomId);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { profile } = useContext(UserContext);
  const plans = useContext(PlanContext);

  const plan = plans.plan!.find((p: PlanType) => p.id === roomId);
  const activity = activities.find(({ id }) => id === plan?.activity)!;
  const friends = useContext(FriendsContext);
  const participants =
    (plan?.participants.map((p) => friends[p]).filter((x) => x) as Profile[]) ||
    [];
  const messageRef = useRef<HTMLDivElement>(null);
  useLogPage("messagePage", {
    id: roomId,
  });

  readMessage(plan, profile.id);
  useEffect(() => {
    messageRef.current!.scrollTo(0, messageRef.current!.scrollHeight);
  }, []);
  if (!plan) {
    return <Navigate to="/home" />;
  }
  if (!roomId) {
    return <Navigate to="/home" />;
  }

  return (
    <Container
      lessPadding
      header={
        <DarkHeader>
          <Flex align="center" gap={10} style={{ width: "100%" }}>
            <Button
              icon={<LeftOutlined style={{ color: "white" }} />}
              type="text"
              onClick={() => navigate("/home")}
            />
            <Avatar.Group
              max={{
                count: 2,
                style: { color: "#f56a00", backgroundColor: "#fde3cf" },
              }}
              size="small"
            >
              {participants.map((p, index) => (
                <Avatar src={p.profilePic} key={index} />
              ))}
            </Avatar.Group>

            <Title
              level={5}
              style={{ color: "white", margin: 0, flex: 1 }}
              ellipsis={{ rows: 1 }}
            >
              {activity.text}
            </Title>
            <EventInfoButton
              plan={plan as unknown as PlanType}
              buttonAlgorithm={theme.darkAlgorithm}
            />
          </Flex>
        </DarkHeader>
      }
      filledHeader
      content={
        <Flex
          gap={20}
          vertical
          style={{ overflow: "scroll", height: "100%", padding: "20px 0" }}
          ref={messageRef}
        >
          {messages.map((message) => (
            <MessageRow key={message.id} message={message} />
          ))}
        </Flex>
      }
      footer={
        <Form
          onFinish={({ message }) => {
            sendMessage(roomId, message).then(() => {
              form.setFieldValue("message", "");
              messageRef.current!.scrollTo(0, messageRef.current!.scrollHeight);
            });
          }}
          form={form}
        >
          <Space.Compact block size="large">
            <Form.Item noStyle name="message" rules={[required]}>
              <Input placeholder="Write a message..." />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space.Compact>
        </Form>
      }
    />
  );
}

export const MessageRow = ({ message }: { message: Message }) => {
  const friends = useContext(FriendsContext);

  if (message.user === "system") {
    return <SystemMessage message={message} />;
  }

  const fromMe = message.user === auth.currentUser?.uid;
  const sender = friends[message.user];
  return (
    <Flex vertical align={fromMe ? "end" : "start"}>
      <Flex
        style={{
          margin: "0 20",
          flexDirection: fromMe ? "row-reverse" : "row",
        }}
        align="end"
        gap={5}
      >
        <Avatar icon={<UserOutlined />} src={sender?.profilePic} />
        <Card style={{ flex: 1 }} styles={{ body: { padding: "10px 15px" } }}>
          <Text strong>{sender ? sender.preferredName : "Unknowned user"}</Text>
          <Paragraph>{message.message}</Paragraph>
          <Flex justify="end" gap={2}>
            <Text disabled>{message.time.fromNow()}</Text>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

const SystemMessage = ({ message }: { message: Message }) => {
  return (
    <Flex vertical align="center">
      <Card
        style={{ maxWidth: "80%", backgroundColor: "rgba(0, 0, 0, 0.08)" }}
        styles={{ body: { padding: 10 } }}
      >
        <Text style={{ textAlign: "center", display: "inline-block" }}>
          {message.message}
        </Text>
      </Card>
    </Flex>
  );
};
