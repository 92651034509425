import { addDoc, collection } from "firebase/firestore";
import { db } from "./firebaseConfig";

export function createFeedback(data: { [key: string]: string }) {
  return addDoc(collection(db, "feedback"), data);
}

export function createPlanFeedback(data: { [key: string]: string }) {
  return addDoc(collection(db, "feedback"), data);
}
