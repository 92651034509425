import { Card, Flex, Typography } from "antd";
import Loader from "component/Loader";
import dayjs from "dayjs";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { db } from "./api/firebaseConfig";
import { Container, DefaultHeader, Logo } from "./component/Container";

const { Title, Paragraph } = Typography;

const Deletion = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [deletionData, setData] = useState<any>(null);
  useEffect(() => {
    getDoc(doc(db, "deleteRequest", id as string)).then((q) =>
      setData(q.data()),
    );
  }, [id]);

  if (!deletionData) {
    return <Loader />;
  }

  return (
    <Container
      lessPadding
      header={<DefaultHeader />}
      content={
        <Flex vertical gap={10} justify="center" style={{ height: "100%" }}>
          <Card>
            <Flex vertical gap={10}>
              <Flex justify="space-between">
                <Title level={2}>Sorry to see you go</Title>
                <Logo size="small" />
              </Flex>
              <Paragraph>
                Request received:{" "}
                {dayjs(deletionData.timestamp.toDate()).toString()}
              </Paragraph>
              <Paragraph>Deletion status: {deletionData.status}</Paragraph>
            </Flex>
          </Card>
        </Flex>
      }
      footer={null}
    />
  );
};
export default Deletion;
