import { green } from "@ant-design/colors";
import { UserAddOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  ConfigProvider,
  Drawer,
  Flex,
  Form,
  Segmented,
} from "antd";
import { Profile, UserContext } from "api/user";
import { verifyUser } from "api/verify";
import { useContext, useState } from "react";
import { TextInput } from "./Form/Input";
import { required } from "./Form/rules";

export const VerifyButton = ({
  profile,
  children,
}: React.PropsWithChildren<{ profile: Profile }>) => {
  const { profile: selfProfile } = useContext(UserContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  if (profile.id === selfProfile.id) {
    throw Error("Verify unsuccessful");
  }
  const [verifySuccess] = useState(false);
  if (verifySuccess) {
    // !isSelfProfile &&
    // !profile.verifiedBy?.find((p) => selfProfile.id) ? (
    <Alert
      message={`Successfully verified ${profile.preferredName} by ${selfProfile.preferredName}.`}
      type="success"
    />;
  }
  const questions = [
    <Form.Item
      name={`Would you consider ${profile.preferredName} to be an introvert or an extrovert?`}
      label={`Would you consider ${profile.preferredName} to be an introvert or an extrovert?`}
      rules={[required]}
    >
      <Segmented<string>
        options={["Introvert", "Ambivert", "Extrovert", "Unclear"]}
      />
    </Form.Item>,
    <TextInput
      name={`What are some topics or interests that ${profile.preferredName} loves to talk about?`}
    />,
    <TextInput
      name={`How would you describe ${profile.preferredName}?`}
      rules={[]}
    />,
  ];
  const [form] = Form.useForm();
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: green.primary,
          },
        }}
      >
        <Button
          icon={<UserAddOutlined />}
          onClick={() => {
            setDrawerOpen(true);
          }}
        >
          Add friend and verify
        </Button>
      </ConfigProvider>
      <Drawer
        title="Verify User"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        size="large"
      >
        <Flex vertical gap={20}>
          <Form
            form={form}
            onFinish={(data) => {
              verifyUser(profile.id, selfProfile, data);
            }}
          >
            {questions}
            <Form.Item>
              <Form.Item name="verify" valuePropName="checked" noStyle>
                <Checkbox disabled={!selfProfile.verified}>
                  I verify that I have met{" "}
                  <strong>{profile.preferredName}</strong> and/or know them in
                  person
                </Checkbox>
              </Form.Item>
            </Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              icon={<UserAddOutlined />}
            >
              Add friend
            </Button>
          </Form>
        </Flex>
      </Drawer>
    </>
  );
};
