import { message } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { log } from "api/analytics";
import { createContext, useContext } from "react";

export const ShareContext = createContext<MessageInstance | null>(null);

export const ShareProvider = ({ children }: React.PropsWithChildren) => {
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <ShareContext.Provider value={messageApi}>
      {contextHolder}
      {children}
    </ShareContext.Provider>
  );
};

export const useShare = (): ((param: {
  url: string;
  text: string;
}) => void) => {
  const messageApi = useContext(ShareContext);
  if (!messageApi) {
    return () => {};
  }
  const share = ({ url, text }: { url: string; text: string }): void => {
    log("shareMessage", { url, text });
    if (navigator.share) {
      navigator.share({ url, text });
      return;
    }
    navigator.clipboard.writeText(text);
    messageApi.info("Link copied!");
  };
  return share;
};
