import {
  CalendarOutlined,
  ExportOutlined,
  InfoCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Dropdown,
  Flex,
  Form,
  Select,
  Skeleton,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { Popup } from "antd-mobile";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { UserContext } from "api/user";
import { dateArrayToString } from "api/utils";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration"; // ES 2015
import { ReactElement, useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ActivityType,
  QueueContext,
  QueueId,
  QueueType,
  useQueueCount,
} from "../../api/queue";
import {
  CardSelect,
  CardSelectChoice,
  SuburbInput,
  textFilterOption,
  TextInput,
} from "./Input";
import { required } from "./rules";
dayjs.extend(duration);

export const activities = [
  {
    text: "Casual Coffee Connections",
    to: "grab a coffee",
    description: "Meet someone new and grab a cup of coffee together!",
    banner: require("images/activities/banner/coffee.png"),
    icon: require("images/activities/coffee.png"),
    id: "coffeeCatchUp",
    type: "classic",
    questions: [
      "What questions would prompt you to talk about something interesting about you?",
    ],
  },
  {
    icon: require("images/activities/game.png"),
    banner: require("images/activities/banner/game.png"),
    text: "Game night",
    to: "play board games",
    description:
      "Get matched with 3-5 people nearby to play board games with in person!",
    id: "game",
    questions: [
      "What board games do you have? Do you have any board games you would want to play? (optional)",
    ],
    type: "classic",
  },
  {
    icon: require("images/activities/sydney.png"),
    text: "Explore Sydney",
    to: "explore sydney",
    description:
      "Join group exploring Sydney through the hidden gems with like-minded people with 3-5 people.",
    id: "explore",
    // tags: ["hello"],
    type: "classic",
    questions: ["Where do you suggest to explore? (optional)"],
  },
  {
    icon: require("images/activities/food.png"),
    text: "Foodie adventures",
    to: "try new restaurants",
    type: "classic",
    description:
      "Join group exploring new food spots, trying different cuisines and flavors. 3-5 people.",
    id: "foodExploration",
    questions: [
      "Are there any specific restaurants or eateries you've been wanting to try? (optional)",
    ],
  },
  {
    icon: require("images/activities/run.png"),
    text: "Run club",
    to: "have a run",
    description: "Go for a run with 3-5 people.",
    id: "run",
    type: "classic",
    questions: [
      "What are some running routes that you would recommend? (optional)",
    ],
  },
  {
    icon: require("images/activities/museum.png"),
    text: "Cultural Excursions",
    to: "visit some musuem or art gallery",
    description:
      "Visit a museum, art gallery or historical site in your area with 3-5 people.",
    id: "museum",
    // tags: ["hello"],
    type: "classic",
    questions: ["Which musuem or art gallery do you suggest to go? (optional)"],
  },
  {
    icon: require("images/activities/concert.png"),
    text: "Concert together (coming soon)",
    to: "go to concert together",
    description:
      "Find someone to go to a particular music concert/festival together!",
    id: "concert",
    disabled: true,
    type: "classic",
    questions: ["What concert are you interested to go to?"],
  },
];

export const activitiesMap: { [key: string]: (typeof activities)[0] } =
  activities.reduce((a, c) => ({ ...a, [c.id]: c }), {});

export type AvailableActivity = string;

export const QueueTypeInput = ({ admin = false }: { admin?: boolean }) => {
  // const limitedChoices = activities.filter(({ type }) => type === "limited");
  const navigate = useNavigate();
  const { queue } = useContext(QueueContext);
  const { queueId } = useParams() as { queueId: QueueId };
  const selectedActivity =
    queue
      ?.filter((q) => q.id !== queueId)
      .reduce((a, c) => ({ ...a, [c.activityType]: true }), {}) || ({} as any);
  const classicChoices = activities
    .filter(({ type }) => type === "classic")
    .map((a) => ({
      ...a,
      disabled: !admin && (a.disabled || selectedActivity[a.id]),
      children: <ActivityStats activity={a} />,
    }));

  return (
    <Flex vertical>
      <Space direction="vertical">
        <Form.Item<QueueType> name="activityType" noStyle rules={[required]}>
          <CardSelect
            choices={classicChoices}
            onChange={(_) => {}}
            value={""}
          />
        </Form.Item>
        <CardSelectChoice
          id=""
          text={
            <Space>
              <ExportOutlined />
              Suggest a new activity
            </Space>
          }
          onSelect={() => {
            navigate("/suggest");
          }}
        />
      </Space>
    </Flex>
  );
};

// TODO: deprecated for now but potential "match time" in the future
// eslint-disable-next-line
export function ActivityStats({ activity }: { activity: ActivityType }) {
  const count = useQueueCount(activity.id);

  /*
  const time = useTime();
  const tillNextMatch = dayjs
    .duration(activity.nextMatchTime.diff(time))
    .add(1, "day");
  const matchTimeVariant = [
    <Typography.Text>
      Next matching in{" "}
      <strong style={{ color: "brown" }}>
        {tillNextMatch.asHours() > 0
          ? tillNextMatch.format("HH:mm:ss")
          : `${tillNextMatch.minutes()} minutes`}
      </strong>
      ...
    </Typography.Text>,
    <Typography.Text>
      Next matching in{" "}
      <strong style={{ color: "brown" }}>
        {tillNextMatch.asHours() > 0
          ? tillNextMatch.format("HH:mm")
          : `${tillNextMatch.minutes()} minutes`}
      </strong>
      ...
    </Typography.Text>,
    <Typography.Text>
      Next matching at{" "}
      <strong style={{ color: "brown" }}>
        {activity.nextMatchTime.format("HH:mm")}
      </strong>{" "}
      today...
    </Typography.Text>,
  ];
   */
  if (count && count > 3) {
    return (
      <>
        <Divider style={{ margin: "12px 0" }} />
        <Space direction="vertical">
          <Skeleton
            loading={count === undefined}
            paragraph={false}
            active={true}
            title={true}
          >
            {count && count > 3 ? (
              <Space>
                <TeamOutlined />
                <Typography.Text>
                  <strong style={{ color: "green" }}>{count}</strong> people
                  queueing
                </Typography.Text>
              </Space>
            ) : null}
          </Skeleton>
          {/*
      <Space>
        <ClockCircleOutlined />
        {matchTimeVariant[1]}
      </Space>
       */}
        </Space>
      </>
    );
  }
  return null;
}

export const DateTimeInput = () => {
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { queue } = useContext(QueueContext);
  const form = useFormInstance();
  let presetDates: ReactElement;
  if (
    queue &&
    queue.length > 0 &&
    queue.some((q) => q.preferredDate && q.preferredDate.length > 0)
  ) {
    presetDates = (
      <Space direction="vertical">
        <Typography.Text>Quick select:</Typography.Text>
        {queue
          .filter((q) => q.preferredDate && q.preferredDate.length > 0)
          .map(({ activityType, preferredDate }) => (
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => form.setFieldValue("preferredDate", preferredDate)}
            >
              {activitiesMap[activityType].text}
              <Space>
                <Tag>{preferredDate[0].format("MMM D")}</Tag>
                <Tag>+ {preferredDate.length - 1} ...</Tag>
              </Space>
            </Button>
          ))}
      </Space>
    );
  }
  return (
    <Form.Item
      label="Preferred Date (optional)"
      style={{ alignSelf: "stretch" }}
    >
      <Button
        block
        onClick={() => {
          setVisible(true);
        }}
        icon={<CalendarOutlined />}
      >
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.preferredDate !== curValues.preferredDate
          }
          noStyle
        >
          {() =>
            dateArrayToString(form.getFieldValue("preferredDate")) ||
            "Select date"
          }
        </Form.Item>
      </Button>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{
          minHeight: "40vh",
        }}
      >
        <Flex style={{ padding: "24px" }} vertical gap={10}>
          <Typography.Title style={{ margin: 0 }}>Select date</Typography.Title>
          <Form.Item name="preferredDate">
            <DatePicker
              renderExtraFooter={() => presetDates}
              open={visible}
              minDate={dayjs()}
              maxDate={dayjs().add(14, "days")}
              getPopupContainer={(node) => ref.current!}
              placement="bottomRight"
              variant="borderless"
              popupStyle={{
                position: "relative",
                top: 0,
                left: 0,
                right: 0,
                minWidth: "initial",
              }}
              multiple
              needConfirm
              maxTagCount={1}
              onOk={() => setVisible(false)}
            />
          </Form.Item>
          <div ref={ref} style={{ alignSelf: "center" }} />
        </Flex>
      </Popup>
    </Form.Item>
  );
  /*
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        {value ? value.join(" ") : "Select date"}
      </Button>
      <CascadePicker
        value={value}
        options={options}
        title="New things"
        visible={visible}
        onSelect={onSelect}
        onClose={() => {
          setVisible(false);
        }}
        cancelText="Cancel"
        confirmText="Confirm"
      />
    </>
   */
};

// TODO: decide whether time is good
// eslint-disable-next-line
function TimeInput({ value = [], onChange }: any) {
  const [open, setOpen] = useState<boolean>(false);
  let label: string = "";

  const times = {
    morning: "Morning",
    afternoon: "Afternoon",
    night: "Night",
    lateNight: "Late night",
  };

  switch (value?.length) {
    case undefined:
    case 0:
      label = "Select time";
      break;
    case 1:
      label = times[value[0] as keyof typeof times];
      break;
    default:
      label = `${value.length}+ time`;
  }
  return (
    <Dropdown
      menu={{
        items: [
          { label: "Morning", key: "morning" },
          { label: "Afternoon", key: "afternoon" },
          { label: "Night", key: "night" },
          { label: "Late night", key: "lateNight" },
        ],
        multiple: true,
        selectedKeys: value,
        selectable: true,
        onSelect: (info) => {
          onChange(info.selectedKeys);
        },
        onDeselect: (info) => {
          onChange(info.selectedKeys);
        },
      }}
      onOpenChange={(nextOpen, info) => {
        if (info.source === "trigger" || nextOpen) {
          setOpen(nextOpen);
        }
      }}
      open={open}
    >
      <Button size="large">{label}</Button>
    </Dropdown>
  );
}

export function AdditionalQuestionsInput({ limit = 1 }: { limit?: number }) {
  const form = Form.useFormInstance();
  const selectedActivityId = form.getFieldValue("activityType");
  const selectedActivity = activities.find(
    ({ id }) => selectedActivityId === id,
  )!;
  const questions = selectedActivity.questions;
  return (
    <>
      {questions.map((question) => (
        <TextInput
          key={question}
          rules={[]}
          label={question}
          name={["additionalQuestions", question]}
        />
      ))}
    </>
  );
}

export function LevelInput() {
  return (
    <Form.Item<QueueType>
      rules={[required]}
      label="Verified Only?"
      valuePropName="checked"
      name={["options", "level"]}
    >
      <Switch />
    </Form.Item>
  );
}

export function SameGenderOnlyInput() {
  return (
    <Form.Item<QueueType>
      label="Same Gender Only"
      valuePropName="checked"
      name={["options", "sameGenderOnly"]}
      initialValue={false}
    >
      <Switch />
    </Form.Item>
  );
}

export function VerifiedOnlyInput() {
  const { profile } = useContext(UserContext);
  return (
    <Form.Item
      name="verifiedOnly"
      valuePropName="checked"
      help="You are not verified yet"
      rules={[
        {
          validator: async (self, v: boolean) => {
            if (!profile.verified && v) {
              throw Error("You are not verified yet");
            }
          },
        },
      ]}
    >
      <Checkbox disabled>
        <Space>
          <Tag color="green" style={{ borderRadius: 12 }}>
            <Typography.Text style={{ color: "inherit" }}>
              Safe mode
            </Typography.Text>
          </Tag>
          <Tooltip
            title={() => (
              <Typography.Text style={{ color: "white" }}>
                Meet with verified users only{" "}
                <Typography.Link>
                  <ExportOutlined />
                </Typography.Link>
              </Typography.Text>
            )}
          >
            <InfoCircleOutlined color="black" />
          </Tooltip>
        </Space>
      </Checkbox>
    </Form.Item>
  );
}

export function ActivityLevelInput({ label }: { label?: string }) {
  return (
    <Form.Item<QueueType>
      rules={[required]}
      label={label ?? "Are you looking for experience people?"}
      style={{ width: "100%" }}
      name={["options", "level"]}
    >
      <Select
        size="large"
        showSearch
        style={{ width: "100%" }}
        placeholder="Level"
        optionFilterProp="children"
        filterOption={textFilterOption}
        options={[
          { value: "none", label: "No preference" },
          { value: "beginner", label: "Beginner" },
          { value: "intermediate", label: "Intermediate" },
          { value: "expert", label: "Export" },
        ]}
      />
    </Form.Item>
  );
}

export { SuburbInput };
