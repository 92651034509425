import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Flex,
  Form,
  Space,
  theme,
  Typography,
} from "antd";
import ConfigProvider from "antd/es/config-provider";
import { AliasToken } from "antd/es/theme/interface";
import { FriendsContext } from "api/friends";
import { QueueType } from "api/queue";
import { Profile, UserContext } from "api/user";
import { ProfileName } from "ProfilePage";
import { useContext } from "react";
import { activities } from "./Form/QueueInput";
import { useShare } from "./Share";

export const getParticipantList = ({
  friends,
  queue,
  count,
}: {
  friends: { [key: string]: Profile | null };
  count?: number;
  queue: QueueType;
}) => {
  const empty = Array(count || queue.min || 4).fill({
    profilePic: undefined,
    preferredName: undefined,
  });
  const creator = friends[queue.creator];
  const participants = queue.participants.map((p) => friends[p]);

  return [creator, ...participants, ...empty].slice(0, count || queue.min || 4);
};

const Participants = ({
  profiles,
  min,
  allowAdding = false,
  waiting = false,
}: {
  profiles: (Profile | string)[];
  allowAdding?: boolean;
  waiting?: boolean;
  min: number;
}) => {
  const { profile: self } = useContext(UserContext);
  const friends = useContext(FriendsContext);
  const share = useShare();

  const participants: Profile[] = profiles
    .map((p) => (typeof p === "string" ? friends[p] : p))
    .filter((p) => p) as Profile[];

  if (participants.length === 0) {
    participants.push(self);
  }
  const placeholderCount = min - participants.length;
  const form = Form.useFormInstance();

  return (
    <Flex vertical align="stretch" style={{ width: "100%" }} gap={5}>
      <Typography.Title style={{ margin: 0 }} level={4}>
        Group
      </Typography.Title>

      {participants.map((profile, index) => (
        <ParticipantCard profile={profile} key={index} />
      ))}

      {placeholderCount > 0
        ? Array(placeholderCount)
            .fill({ profilePic: undefined, preferredName: "..." })
            .map((profile, index) => (
              <ParticipantCard profile={profile} key={index} disabled />
            ))
        : null}

      {allowAdding && form ? (
        <Button
          onClick={() =>
            share({
              url: `https://jigsaur.com/join/${self.id}`,
              text: `Join me on a ${activities.find((id) => form.getFieldValue("activityType"))!.text} at jigsaur.com/join/${self.id}`,
            })
          }
        >
          Invite friends
        </Button>
      ) : null}
      {waiting ? (
        <Space align="baseline">
          <Typography.Title level={5} style={{ margin: 0 }}>
            Waiting for others
          </Typography.Title>
          <div className="dot-loader" />
        </Space>
      ) : null}
    </Flex>
  );
};

export const ParticipantCard = ({
  profile,
  disabled,
  ...props
}: {
  profile: Profile;
  disabled?: boolean;
  props?: any;
}) => {
  const {
    token: { colorBgContainerDisabled, colorTextDisabled },
  } = theme.useToken();
  const disabledCardTheme: Partial<AliasToken> = {};
  if (disabled) {
    disabledCardTheme.colorBgContainer = colorBgContainerDisabled;
    disabledCardTheme.colorText = colorTextDisabled;
  }
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: disabledCardTheme,
        },
      }}
    >
      <Card styles={{ body: { padding: "10px 15px" } }} {...props}>
        <Space align="center">
          <Avatar icon={<UserOutlined />} size={30} src={profile.profilePic} />
          <ProfileName profile={profile} level={4} />
        </Space>
      </Card>
    </ConfigProvider>
  );
};
export default Participants;
