import { doc, runTransaction } from "firebase/firestore";
import { useCounter } from "./counter";
import { auth, db } from "./firebaseConfig";
import { getUserRef, Profile, UserId } from "./user";

export function verifyUser(
  uid: UserId,
  selfProfile: Profile,
  questions: { [question: string]: string },
) {
  return runTransaction(db, async (transaction) => {
    if (!auth.currentUser) {
      return;
    }
    const userDoc = await transaction.get(getUserRef(uid));
    if (!userDoc.data().verified && selfProfile.verified) {
      transaction.update(getUserRef(uid), { verified: true });
    }
    transaction.set(
      doc(db, "users", uid, "verifiedBy", selfProfile.id),
      questions,
    );
  });
}

export const useVerifiedCounter = () => {
  return useCounter("verifiedUsers");
};
