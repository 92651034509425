import { Button, Col, Flex, Image, Layout, Row, Typography } from "antd";
import { DefaultHeader, LoginAction } from "component/Container";
import sydney from "images/sydney.png";
import { Factors, HowItWorks, LandingPageFooter } from "LandingPage";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const LandingV2 = ({ children }: React.PropsWithChildren) => {
  /*
  const {
    token: { colorBgLayout },
  } = theme.useToken();
   */
  const navigate = useNavigate();
  return (
    <Layout id="container" style={{ height: "100%" }}>
      <DefaultHeader action={<LoginAction />} />
      <Layout.Content style={{}}>
        <Flex
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "stretch",
            padding: "0 32px",
            overflow: "scroll",
            scrollbarWidth: "none",
          }}
        >
          <Row
            style={{
              minHeight: "calc(100svh - 72px)",
            }}
            align={{ xs: "stretch", md: "middle" }}
          >
            <Col md={12} xs={24}>
              <Flex
                vertical
                justify="center"
                style={{ height: "100%" }}
                align="center"
              >
                <Typography.Title
                  style={{
                    textAlign: "center",
                    margin: 0,
                  }}
                >
                  Find people
                </Typography.Title>
                <Typography.Title
                  style={{
                    textAlign: "center",
                    margin: 0,
                  }}
                >
                  in real life to
                </Typography.Title>
                <Words />
                <Typography.Paragraph
                  type="secondary"
                  style={{
                    textAlign: "center",
                    margin: 0,
                  }}
                >
                  (beta)
                </Typography.Paragraph>
                <Flex vertical align="center">
                  <Button
                    type="primary"
                    onClick={() => navigate("/quickstart")}
                  >
                    Get started
                  </Button>
                </Flex>
              </Flex>
            </Col>
            <Col md={12} xs={24}>
              <Image
                width={"100%"}
                src={sydney}
                preview={false}
                style={{ borderRadius: 50 }}
              />
            </Col>
          </Row>
          <HowItWorks colorBgLayout="white" />
          <Factors />
          <LandingPageFooter />
        </Flex>
      </Layout.Content>
    </Layout>
  );
};

const Words = () => {
  const words = ["play board games", "run with", "explore sydney"];
  const colors = ["#8e44ad", "#2980b9", "#c0392b", "#16a085", "#2c3e50"];
  const [state, setState] = useState<number>(0);
  useEffect(() => {
    const x = setInterval(() => setState((i) => (i + 1) % words.length), 4000);
    return () => clearInterval(x);
  }, [words.length]);
  return (
    <Flex
      style={{
        height: 50,
      }}
      justify="center"
    >
      {words.map((w, index) => (
        <Word
          key={index}
          words={w}
          exit={index === state}
          enter={index === (state + 1) % words.length}
          color={colors[index % colors.length]}
        />
      ))}
    </Flex>
  );
};
const Word = ({
  words,
  enter,
  exit,
  color,
}: {
  words: string;
  enter?: boolean;
  exit?: boolean;
  color: string;
}) => {
  // const letters = &nbsp;
  const refs = useRef<Array<HTMLElement>>([]);
  const comp = words.split("").map((letter, index) => (
    <span
      key={index}
      ref={(e) => {
        if (e) {
          refs.current = [...refs.current, e];
        }
      }}
      className={enter ? "letter behind" : exit ? "letter in" : "letter out"}
    >
      {letter}
    </span>
  ));
  useEffect(() => {
    if (enter) {
      const timeouts = refs.current.map((p, i) =>
        setTimeout(
          () => {
            p.className = "letter in";
          },
          340 + i * 80,
        ),
      );
      return () => timeouts.forEach((i) => clearTimeout(i));
    } else if (exit) {
      const timeouts = refs.current.map((p, i) =>
        setTimeout(() => {
          p.className = "letter out";
        }, i * 80),
      );
      return () => timeouts.forEach((i) => clearTimeout(i));
    }
  }, [enter, exit]);

  return (
    <Typography.Title
      style={{
        textAlign: "center",
        margin: 0,
        display: "inline-block",
        position: "absolute",
        verticalAlign: "top",
        color,
      }}
    >
      {comp}
    </Typography.Title>
  );
};

export default LandingV2;
