import { Link } from "react-router-dom";
import { Container, DefaultHeader } from "./component/Container";

const TnCPage = () => {
  return Container({
    header: <DefaultHeader />,
    content: <TnCText />,
    footer: null,
  });
};

/* TODO: update domain website */
const TnCText = () => (
  <div>
    <h1> Terms of Use </h1>
    <h3> Introduction </h3>
    <ol>
      <li>
        Hello, we’re Jigsaur (Jigsaur, we, us). These Jigsaur Website Terms of
        Use (Terms) are here to ensure you understand your rights and
        obligations when you access, navigate and use our website located at{" "}
        {window.location.hostname} (Website) and use our Services (as defined
        below).
      </li>
      <li>
        Any reference in these Terms to “you” or “your” or “Customer” means any
        person accessing, viewing or using the Website and/or Services.
      </li>
      <li>
        Jigsaur is an event registration platform, designed as an online
        substitute for physical attendance forms. By scanning the QR code or by
        visiting the links shared by a society admin, you will be directed to
        our Website and will be able to mark your attendance to our services and
        be recorded in our system.
      </li>
      <li>
        By accessing, navigating and using the Services and/ or Website or
        otherwise dealing with Jigsaur, you agree to be bound by these Terms and
        the Jigsaur Privacy Policy located at{" "}
        <Link to="/privacy">https://{window.location.hostname}/privacy</Link>.
        If you do not agree to these Terms, then you must cease using the
        Services or the Website.
      </li>
      <li>
        Jigsaur may amend these Terms at any time at its sole discretion. By
        continuing to use the Website and/ or Services, you will be deemed to
        have accepted any revised terms from the date that they are published on
        the Website.
      </li>
      <li>
        Jigsaur reserves its rights to investigate and take appropriate legal
        action for any Illegal and/or unauthorised use of the Services, the
        Website or breach of these Terms.
      </li>
    </ol>
    <h3>Using our Services and Website</h3>
    <ol>
      <li>
        Jigsaur grants you a non-exclusive, non-transferable licence to access,
        use and navigate the Services and Website subject to you complying with
        these Terms.
      </li>
      <li>
        To use the Services, you acknowledge and agree that you may need to
        provide Jigsaur with information that could personally identify you and
        you acknowledge that Jigsaur may collect your location. You acknowledge
        and agree that all information collected will be dealt with in
        accordance with our Privacy Policy.
      </li>
      <li>
        You acknowledge and agree that Jigsaur may send electronic messages to
        your contact telephone number or email address. You agree that by using
        the Services you consent to Jigsaur sending you electronic messages. We
        will only send you messages we consider for the provision of services to
        you as a customer.
      </li>
      <li>
        You acknowledge that Jigsaur does not guarantee that any of the
        information displayed on its Website including all information, photos,
        time, explanations of an Organisation/Society are accurate and Jigsaur
        is not liable for any errors in representation.
      </li>
      <li>
        You acknowledge that Jigsaur acts only as an intermediary between the
        Customer and the University Student Body and is not liable if the
        University Student Body provides incorrect information.
      </li>
      <li>
        You agree to take responsibility for the security of access to your
        account by using a strong password to prevent unauthorised access to the
        account, and by not sharing login details with any other person.
      </li>
    </ol>
    <h3>Our content and access</h3>
    <ol>
      <li>
        Jigsaur makes all reasonable endeavours to ensure that the information
        about events and announcement available on the Website are accurate and
        correct, although we do not warrant that it is accurate, adequate or
        complete.
      </li>
      <li>
        You acknowledge and accept that the Website content may include
        incorrect information, technical inaccuracies and typographical errors.
        You acknowledge and accept that the Services and the Website will, from
        time to time, change without notice to you and that the content of the
        Website may not necessarily be accurate or up to date at the time you
        view it.
      </li>
      <li>
        You are responsible to contact us directly to ensure that any material
        or information on the Website that you seek to rely on is accurate and
        current. Jigsaur disclaims all liability for any direct or indirect loss
        or damage arising from your use or reliance on the Services or Website
        to the full extent permitted by law.
      </li>
      <li>
        You agree not to attempt to change, add to, remove, deface, hack or
        otherwise interfere with the Services or the Website or any material or
        content displayed on the Services or the Website unless expressly
        permitted by us or these Terms.
      </li>
      <li>
        You warrant that you will not hide, deface, alter or delete any
        copyright symbol, trademark or other proprietary rights notice.
      </li>
      <li>
        We do not guarantee that your access to the Services and/or the Website
        will be uninterrupted or that the Services and/or the Website is free
        from viruses or any other malware which may damage any device or data as
        a result of access to the Services or Website.
      </li>
      <li>
        You agree that Jigsaur may conduct maintenance of the Services or
        Website at any time and that this maintenance may interrupt your access
        to the Services or Website.
      </li>
      <li>
        We reserve our rights to suspend or terminate your access to the Service
        or Website at our sole discretion where we hold a reasonable
        apprehension that you have or may breach these Terms. If we suspect you
        are or may be in breach of these Terms, we will endeavour to notify you
        of that breach and ways in which you can remedy it.
      </li>
    </ol>
    <h3>Intellectual Property</h3>
    <ol>
      <li>
        All material displayed on the Service and the Website, including but not
        limited to all information, photographs, graphics, illustrations,
        artwork, names, logos, trademarks, copy writing and design features
        (Jigsaur Intellectual Property) we acknowledge we have the right to use
        or are our property and are protected by copyright, trademark and other
        intellectual property laws. You may not use the Jigsaur Intellectual
        Property for any commercial purpose without our express prior written
        consent.
      </li>
      <li>
        You agree not to copy, imitate, reproduce, reverse engineer, sell,
        reproduce, retransmit, distribute, disseminate, sell, publish, broadcast
        or circulate any Jigsaur Intellectual Property for any commercial
        purpose to any third party in whole or in part without our express prior
        written consent.
      </li>
      <li>
        You acknowledge that if you do copy, imitate, reproduce, reverse
        engineer, sell, reproduce, retransmit, distribute, disseminate, sell,
        publish, broadcast or circulate any Jigsaur Intellectual Property, we
        will suffer loss and damage and you agree to indemnify us for any such
        loss and damage.
      </li>
      <li>
        We do not grant any licence or right in or assign all or part of the
        rights of the Jigsaur Intellectual Property to you.
      </li>
      <li>
        The Website may contain links to other websites operated, controlled or
        produced by third parties including University Student Body. Jigsaur
        does not control, endorse, sponsor or approve of any third party website
        or its content and does not provide any warranty or take on any
        responsibility whatsoever in relation to your access and use of third
        party websites. We advise you to check their privacy policy and terms
        and conditions before using their services.
      </li>
      <li>
        You warrant that you will not infringe the Intellectual Property Rights
        of Jigsaur or any third party relating to your use of the Services or
        the Website.
      </li>
    </ol>
    <h3>Limitation of Liability</h3>
    <p>
      You agree that your use of the Services and the Website are at your own
      risk and you accept and agree that you will not seek to hold us
      accountable for any loss or damage that you or any third party may suffer
      as a result of your use of the Services and/or the Website, and that you
      will indemnify us for any such loss and damage we suffer as a result of
      claims brought against us by any party as a result of your use of the
      Services and/or the Website.
    </p>
    <h3>Indemnification</h3>
    <p>
      To the fullest extent of the law, you agree to indemnify us from and
      against any and all liabilities, costs, demands, causes of action, damages
      and expenses (including legal fees) arising out of or in any way related
      to your breach of any of the provisions of these Terms and/or your use of
      the Services and Website.
    </p>
    <h3>Severability</h3>
    <p>
      If any provision of these Terms is found to be unenforceable or invalid
      under any applicable law, such unenforceability or invalidity shall not
      render these Terms unenforceable or invalid as a whole, and such
      provisions shall be deleted without affecting the remaining provisions
      herein.
    </p>
    <h3>Assignment</h3>
    <p>
      We are permitted to assign, transfer, and subcontract our rights and/or
      obligations under these Terms without any notification or consent to you.
      You are not permitted to assign, transfer, or subcontract any of your
      rights and/or obligations under these Terms.
    </p>
  </div>
);
export default TnCPage;
