import { green, yellow } from "@ant-design/colors";
import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  EnvironmentOutlined,
  ExportOutlined,
  InfoCircleOutlined,
  LoginOutlined,
  QuestionCircleTwoTone,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Drawer,
  Flex,
  Form,
  Input,
  Popover,
  Row,
  Space,
  theme,
  Tooltip,
  Typography,
} from "antd";
import { log } from "api/analytics";
import { auth } from "api/firebaseConfig";
import { FriendsContext } from "api/friends";
import { sendMessage } from "api/message";
import {
  confirmPlan,
  leaveOrDeletePlanWithUIConfirmation,
  PlanType,
} from "api/plan";
import { UserContext } from "api/user";
import CircularImageIcon from "component/CircularImageIcon";
import { activities } from "component/Form/QueueInput";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
const { Paragraph } = Typography;

type PlanProps = { plan: PlanType };

export const PlanCard: React.FC<{ plan: PlanType; title?: string }> = ({
  plan,
  title: cardTitle,
}) => {
  const accepted = (plan.confirmedParticipants || []).some(
    (p: string) => auth.currentUser?.uid === p,
  );
  return (
    <Card
      style={{ width: 300 }}
      cover={<></>}
      actions={
        accepted
          ? [<MessageButton plan={plan} />]
          : [<RSVPButtons plan={plan} />]
      }
    >
      <Flex vertical align="stretch" gap={10}>
        <PlanHeader plan={plan} />
        <Divider style={{ margin: 4 }} />
        <Paragraph>{plan.description}</Paragraph>
        <Typography.Text strong>Participants:</Typography.Text>

        {plan.participants.map((p) => (
          <FriendsLine profileId={p} key={p} plan={plan} />
        ))}
      </Flex>
    </Card>
  );
};

// Sub-components
export const EventInfoButton = ({
  plan,
  buttonAlgorithm = theme.defaultAlgorithm,
}: {
  buttonAlgorithm?: any;
  plan: PlanType;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { activity } = plan;
  const activityDetails = activities.find(({ id }) => id === activity)!;
  const title = activityDetails.text;
  const details = activityDetails.description;
  return (
    <>
      <ConfigProvider
        theme={{
          algorithm: buttonAlgorithm,
        }}
      >
        <Button
          key="info"
          onClick={() => setDrawerOpen(true)}
          shape="circle"
          type="text"
          icon={<InfoCircleOutlined />}
        />
      </ConfigProvider>
      <Drawer
        title={title}
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        size="large"
      >
        <Flex vertical gap={20}>
          <Flex vertical>
            <Card styles={{ body: { padding: "10px 15px" } }}>
              <Space align="center">
                <EnvironmentOutlined />
                {plan.location || "Sydney"}
                <Button
                  type="link"
                  icon={<ExportOutlined />}
                  onClick={() => {
                    if (plan.locationLink) {
                      window.open(plan.locationLink, "_blank")?.focus();
                    }
                  }}
                />
              </Space>
            </Card>
            <Card styles={{ body: { padding: "10px 15px" } }}>
              <Space align="center">
                <ClockCircleOutlined />
                {plan.date?.format("ll") || "Any time"}
              </Space>
            </Card>
          </Flex>
          <Space direction="vertical">
            <Typography.Text strong>Who's joining?</Typography.Text>
            <p>{details}</p>
            {plan.participants.map((p) => (
              <FriendsLine profileId={p} key={p} plan={plan} />
            ))}
          </Space>
          <Divider />
          <Button
            block
            type="primary"
            danger
            key="message"
            onClick={() => leaveOrDeletePlanWithUIConfirmation(plan)}
            icon={<CloseCircleOutlined />}
          >
            Exit group
          </Button>
        </Flex>
      </Drawer>
    </>
  );
};
const PlanHeader = ({ plan }: PlanProps) => {
  const { activity } = plan;
  const activityDetails = activities.find(({ id }) => id === activity)!;
  return (
    <Flex vertical align="stretch">
      <Flex align="center" gap={10}>
        <CircularImageIcon src={activityDetails.icon} />
        <Flex vertical align="stretch">
          <Typography.Text strong>{activityDetails.text}</Typography.Text>
          {plan.location ? (
            <Typography.Text type="secondary" style={{ margin: 0 }}>
              <EnvironmentOutlined /> {plan.location}
            </Typography.Text>
          ) : null}
          <Typography.Text type="secondary" style={{ margin: 0 }}>
            <ClockCircleOutlined /> {plan.date?.format("ll")}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
const MessageButton = ({ plan }: PlanProps) => {
  const navigate = useNavigate();
  const {
    profile: { id },
  } = useContext(UserContext);
  const messageCount = plan.messageCount ?? 0;
  const lastSeenMessageCount = plan.lastSeen?.[id] ?? 0;
  const count = messageCount - lastSeenMessageCount || 0;

  return (
    <Badge count={count}>
      <Button
        type="primary"
        block
        key="message"
        onClick={() => navigate(`/hangout/${plan.id}`)}
        icon={<SendOutlined />}
        className="animate__animated animate__bounceIn"
      >
        Send message
      </Button>
    </Badge>
  );
};

const RSVPButtons = ({ plan }: PlanProps) => {
  const {
    token: { padding },
  } = theme.useToken();
  return (
    <Row style={{ padding, paddingTop: 0, paddingBottom: 0 }}>
      <Col span={4}>
        <DeclinePlanButton plan={plan} />
      </Col>
      <Col flex={1}>
        <JoinPlanButton plan={plan} />
      </Col>
    </Row>
  );
};
const DeclinePlanButton = ({ plan }: PlanProps) => (
  <Button
    type="primary"
    danger
    key="message"
    onClick={() => leaveOrDeletePlanWithUIConfirmation(plan)}
    icon={<CloseCircleOutlined />}
  ></Button>
);
export const JoinPlanButton = ({ plan }: PlanProps) => {
  const { profile } = useContext(UserContext);
  const [drawerOpen, _setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const setDrawerOpen = (open: boolean) => {
    if (open) {
      log("plan_joinPage_enter");
    } else {
      log("plan_joinPage_exit");
    }
    _setDrawerOpen(open);
  };
  const questions = [
    <Form.Item
      name="IntroText"
      label="Send a message to the group chat to get started!"
      initialValue={`Hi everyone, I'm ${profile.preferredName}!`}
      layout="vertical"
    >
      <Input.TextArea rows={2} />
    </Form.Item>,
  ];
  const [form] = Form.useForm();
  return (
    <>
      <Button
        type="primary"
        block
        key="message"
        onClick={() => setDrawerOpen(true)}
        icon={<LoginOutlined />}
      >
        Accept and join!
      </Button>
      <Drawer
        title="Verify User"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        size="large"
      >
        <Flex vertical gap={20}>
          <Form
            form={form}
            onFinish={(data) => {
              confirmPlan(plan)
                .then(() => log("plan_joinPage_success", data))
                .then(() => sendMessage(plan.id!, data.IntroText))
                .then(() => navigate(`/hangout/${plan.id}`));
            }}
          >
            {questions}
            <Divider />
            <Divider />
            <Form.Item
              name="verify"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, v) =>
                    v ? Promise.resolve() : Promise.reject(),
                  message: "Please accept the terms and condition",
                },
              ]}
            >
              <Checkbox>
                By checking this box, I agree not to spam, harass, or engage in
                any harmful behaiour. I understand that violating these terms
                may result in account termination.
              </Checkbox>
            </Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              icon={<LoginOutlined />}
            >
              Accept and join!
            </Button>
          </Form>
        </Flex>
      </Drawer>
    </>
  );
};

// eslint-disable-next-line
const ExitGroupButton = () => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Popover
      content={
        <Space.Compact direction="vertical" style={{ width: "100%" }}>
          <Button type="primary" danger>
            Leave Group
          </Button>
          <Button danger>Report group</Button>
        </Space.Compact>
      }
      trigger="click"
      open={open}
      onOpenChange={(open) => setOpen(open)}
    >
      <Button type="text">
        <EllipsisOutlined key="ellipsis" />
      </Button>
    </Popover>
  );
};

const FriendsLine = ({
  profileId,
  plan,
}: {
  profileId: string;
  plan: PlanType;
}) => {
  const profiles = useContext(FriendsContext);
  const profile = profiles[profileId];
  if (!profile) {
    return null;
  }
  const isConfirmed = plan.confirmedParticipants.find((id) => id === profileId);
  const isCanceled = plan.canceledParticipants.find((id) => id === profileId);
  if (isCanceled) {
    return null;
  }
  // {profile.verified ? <CheckCircleTwoTone /> : null}
  return (
    <Space key={profileId}>
      <Avatar icon={<UserOutlined />} src={profile.profilePic} />
      <Link to={`/profile/${profileId}`}>
        {profile.preferredName || "Unknown user"}
      </Link>
      <Tooltip trigger="click" title={isConfirmed ? "Accepted" : "Maybe"}>
        {isConfirmed ? (
          <CheckCircleTwoTone twoToneColor={green[5]} />
        ) : (
          <QuestionCircleTwoTone twoToneColor={yellow[6]} />
        )}
      </Tooltip>
    </Space>
  );
};
