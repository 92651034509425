import { DeleteOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Form,
  Input,
  Space,
  Table,
} from "antd";
import { Dialog } from "antd-mobile";
import { db } from "api/firebaseConfig";
import { FriendsContext } from "api/friends";
import { deleteMessage, sendSystemMessage, useMessage } from "api/message";
import { PlanType, useAllFuturePlanSnapshot } from "api/plan";
import { Profile } from "api/user";
import { TextInput } from "component/Form/Input";
import { activities } from "component/Form/QueueInput";
import { required } from "component/Form/rules";
import Loader from "component/Loader";
import { PlanCard } from "component/Plan";
import { addDoc, collection } from "firebase/firestore";
import { MessageRow } from "MessagePage";
import { useContext, useState } from "react";
import { EasyTag } from "./EasyTag";

const SendPlanNotifications = ({ children }: React.PropsWithChildren) => {
  const { plan, loading } = useAllFuturePlanSnapshot();
  if (loading) {
    return <Loader />;
  }
  return (
    <Flex align="start">
      {plan.map((plan) => (
        <PlanNotificationCard key={plan.id} plan={plan} />
      ))}
    </Flex>
  );
};
const AdminPlanCard = ({ plan }: { plan: PlanType }) => {
  const [showMessage, setShowMessage] = useState(false);

  return (
    <Space size="large">
      <Space direction="vertical">
        <PlanCard plan={plan} />
        <Button
          type="primary"
          block
          key="message"
          onClick={() => setShowMessage((v) => !v)}
          icon={<SendOutlined />}
          className="animate__animated animate__bounceIn"
        >
          Toggle message page
        </Button>
      </Space>
      {showMessage ? <AdminMessagePage id={plan.id!} /> : null}
    </Space>
  );
};
const AdminMessagePage = ({ id }: { id: string }) => {
  const messages = useMessage(id);
  console.log(messages);
  return (
    <Space direction="vertical">
      <Flex
        gap={20}
        vertical
        style={{
          overflow: "scroll",
          background: "rgba(0,0,0,0.02)",
          height: 700,
        }}
      >
        {messages.map((message) => (
          <Flex>
            <div
              style={{
                width: 400,
              }}
            >
              <MessageRow key={message.id} message={message} />
            </div>
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                Dialog.confirm({
                  title: "Delete message",
                  onConfirm: () => deleteMessage(message),
                });
              }}
            />
          </Flex>
        ))}
      </Flex>
      <Form
        onFinish={({ message }) => {
          sendSystemMessage(id, message);
        }}
      >
        <Space.Compact block size="large">
          <Form.Item noStyle name="message" rules={[required]}>
            <Input placeholder="Write a message..." />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Space.Compact>
      </Form>
    </Space>
  );
};
const PlanNotificationCard = ({ plan }: { plan: PlanType }) => {
  const profiles = useContext(FriendsContext);
  const [selected, select] = useState("");
  return (
    <Card>
      <AdminPlanCard plan={plan} />
      <Divider />
      <Space>
        <Table<Profile>
          rowKey="id"
          rowSelection={{
            type: "radio",
            onSelect(selectedRowKeys, selectedRows, info) {
              select(selectedRowKeys.id);
            },
          }}
          columns={[
            {
              title: "profilePic",
              key: "profilePic",
              dataIndex: "profilePic",
              render: (x, { profilePic = "" }) => (
                <Avatar icon={<UserOutlined />} src={profilePic} />
              ),
            },
            {
              title: "preferredName",
              key: "preferredName",
              dataIndex: "preferredName",
            },
            {
              title: "interests",
              key: "interests",
              dataIndex: "interests",
              render: (interests) => {
                return (
                  <>
                    {Object.keys(interests || {}).map((p) => (
                      <EasyTag tag={p} />
                    ))}
                  </>
                );
              },
            },
          ]}
          dataSource={
            plan.participants
              .map((p) => profiles[p])
              .filter((p) => p) as Profile[]
          }
        />
        {/*plan.participants.map((p) => (
          <Badge
            key={p}
            count={<CheckCircleFilled style={{ color: "blue" }} />}
            style={{
              display: p === selected ? "" : "none",
            }}
          >
            <Avatar
              onClick={() => select(p)}
              icon={<UserOutlined />}
              src={profiles[p]?.profilePic}
            />
          </Badge>
        ))*/}
      </Space>
      <Divider />
      <NotificationForm plan={plan} profileId={selected} key={selected} />
    </Card>
  );
};

const NotificationForm = ({
  profileId,
  plan,
}: {
  profileId: string;
  plan: PlanType;
}) => {
  const profiles = useContext(FriendsContext);
  if (profileId === "") {
    return null;
  }
  const profile = profiles[profileId];
  const activityDetails = activities.find(({ id }) => id === plan.activity)!;

  const values = {
    profileId,
    preferredName: profile?.preferredName,
    planName: activityDetails.text,
    planId: plan.id,
    date: plan.date,
    checked: true,
    message: "",
    test: true,
  };
  values.message = [
    `Hi ${values.preferredName}!`,
    `Your Jigsaur group for ${values.planName} is ready and tentatively scheduled at ${values.date?.format("MMM D")}! Go to Jigsaur to see your new group now at jigsaur.com/p/${values.planId}`,
  ].join("\n");
  return (
    <Form initialValues={values} onFinish={createSms}>
      <TextInput name="profileId" label="profileId" disabled />
      <TextInput name="preferredName" label="preferredName" disabled />
      <TextInput name="planName" label="planName" disabled />
      <TextInput name="planId" label="planId" disabled />
      <Form.Item
        rules={[required]}
        style={{ width: "100%" }}
        name="message"
        label="message"
      >
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item rules={[]} name="test" label="test" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Button htmlType="submit">Send</Button>
    </Form>
  );
};
export default SendPlanNotifications;
function createSms(data: any) {
  console.log(data);
  return addDoc(collection(db, "sms"), data);
}
