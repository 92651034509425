import { LeftOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Image, Space, theme, Typography } from "antd";
import { Header } from "antd/es/layout/layout";
import { Container, Logo } from "component/Container";
import verify from "images/security.png";
import React, { ReactElement, ReactNode } from "react";

const PageCard = ({
  children,
  back,
  footer,
}: React.PropsWithChildren<{
  back?: Function;
  footer?: ReactElement;
}>) => {
  const {
    token: { colorBgLayout },
  } = theme.useToken();

  return Container({
    header: (
      <Header
        style={{
          backgroundColor: colorBgLayout,
          padding: back ? 5 : undefined,
        }}
      >
        <Space>
          {back ? (
            <Button
              icon={<LeftOutlined />}
              type="text"
              onClick={() => back()}
            />
          ) : null}

          <Logo />
        </Space>
      </Header>
    ),
    content: (
      <Flex
        align="stretch"
        justify="space-between"
        style={{ height: "100%" }}
        vertical
      >
        {children}
      </Flex>
    ),
    footer,
  });
};

export const SimpleCard = ({
  children,
  title,
  subtitle,
  back,
  src = verify,
}: React.PropsWithChildren<{
  title: string;
  subtitle: ReactNode;
  back?: boolean;
  src?: string;
}>) => {
  return (
    <Card>
      <Flex vertical align="center">
        <Space direction="vertical">
          <Typography.Title level={2} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
          <Typography.Paragraph>{subtitle}</Typography.Paragraph>
        </Space>
        <Image
          width={200}
          src={src}
          preview={false}
          style={{
            borderRadius: 20,
          }}
        />
        {children}
      </Flex>
    </Card>
  );
};

export default PageCard;
