// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyDY0FdOEq9PpcZDAUioSFZ9X-AYLYB_wz0",
  authDomain: "jigsaur-v1.firebaseapp.com",
  projectId: "jigsaur-v1",
  storageBucket: "jigsaur-v1.appspot.com",
  messagingSenderId: "900672118152",
  appId: "1:900672118152:web:0d50c3beb440591b8d6e3d",
  measurementId: "G-QEZT1BFGEK",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
export const remoteConfig = getRemoteConfig(app);
if (window.location.hostname === "localhost") {
  //connectFirestoreEmulator(db, "127.0.0.1", 8080);
  //connectAuthEmulator(auth, "http://127.0.0.1:9099");
}
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  phoneCountryCode: "+61",
};
fetchAndActivate(remoteConfig);
