import dayjs, { Dayjs } from "dayjs";
import {
  DocumentReference,
  FirestoreDataConverter,
  onSnapshot,
  Query,
  Timestamp,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { log } from "./analytics";

export type Status<T extends {}> = {
  loading: boolean;
  error: any;
} & T;

export function useDocumentSnapshot(doc: DocumentReference) {
  const [state, setState] = useState<Status<{ data: any }>>({
    loading: true,
    error: false,
    data: undefined,
  });

  useEffect(
    () =>
      onSnapshot(
        doc,
        (querySnapshot) => {
          if (querySnapshot.exists()) {
            const data = querySnapshot.data();
            setState({
              loading: false,
              error: false,
              data,
            });
          } else {
            setState({
              loading: false,
              error: "missing",
              data: null,
            });
          }
        },
        (error) => {
          log("exception", {
            description: `Failed to fetch doc ${doc.path}. Error: ${error}`,
          });
        },
      ),
    [doc],
  );
  return state;
}

export function useQuerySnapshot(doc: Query) {
  const [state, setState] = useState<Status<{ data: any }>>({
    loading: true,
    error: false,
    data: null,
  });

  useEffect(
    () =>
      onSnapshot(
        doc,
        (querySnapshot) => {
          if (querySnapshot.empty) {
            setState({
              loading: false,
              error: "missing",
              data: null,
            });
            return;
          }
          const q = querySnapshot.docs;
          setState({
            loading: false,
            error: false,
            data: q.map((x) => x.data()),
          });
        },
        (error) => {
          log("exception", {
            description: `Failed to fetch doc ${(doc as any)._path}. Error: ${error}`,
          });
        },
      ),
    [doc],
  );
  return state;
}

export const createFirestoreDateConverter: (
  keys: string[],
  converterOptions?: {
    toFirestore?: (arg0: any) => {};
    fromFirestore?: (arg0: any) => {};
  },
) => FirestoreDataConverter<any> = (keys, converterOptions) => ({
  toFirestore: (item) => {
    let { ref, ...data } = item;
    keys.forEach((key) => {
      if (data[key]) {
        data[key] = dayjsToFirebaseDate(data[key]);
      }
    });
    if (converterOptions?.toFirestore) {
      data = converterOptions.toFirestore(data);
    }
    return {
      ...data,
    };
  },
  fromFirestore: (snapshot, options) => {
    let data = snapshot.data(options);
    keys.forEach((key) => {
      if (data[key]) {
        data[key] = firebaseDateToDayjs(data[key]);
      }
    });
    if (converterOptions?.fromFirestore) {
      data = converterOptions.fromFirestore(data);
    }
    return {
      ...data,
      ref: snapshot.ref,
      id: snapshot.id,
    };
  },
});

export function dayjsToFirebaseDate(date: Dayjs) {
  return Timestamp.fromDate(date.toDate());
}

export function firebaseDateToDayjs(date: Timestamp) {
  return dayjs(date.toDate());
}
