import { FormRule } from "antd";
import { Rule } from "antd/es/form";

export const required = { required: true, message: "Required" };

export const email: FormRule = { type: "email" };

export const phoneNumberRule: Rule = {
  pattern: /^[41]\d{8}$/,
  message: "Please enter a valid phone number",
};
